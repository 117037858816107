import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table } from 'antd'
import { connect } from 'dva';
import _ from 'lodash';

const namespace = 'claimSetting';
@connect(({ claimSetting }) => ({ claimSetting }))
export default class ClaimSetting extends Component {
  state = {
    currentData: {}
  }
  componentDidMount() {
    this.props.dispatch({
      type: `${namespace}/fetchInsureUnitList`
    })
    this.getList()
  }
  getList = () => {
    this.props.dispatch({
      type: `${namespace}/fetchList`
    })
  }

  searchSubmit = data => {
    const { searchCond } = this.props[namespace];
    Object.entries(data).forEach(([k, v]) => {
      searchCond[k] = v;
    });
    searchCond.page = 1;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: searchCond,
    });
    this.getList()
  }
  handleTableChange = pagination => {
    const { searchCond } = this.props[namespace];
    searchCond.size = pagination.pageSize;
    searchCond.page = pagination.current;
    this.props.dispatch({
      type: `${namespace}/set`
    })
    this.getList()
  };

  render() {
    const { searchCond, totalNum, dataList, insureUnitList } = this.props[namespace]
    const searchList = [
      { key: "policy_no", type: "INPUT", placeholder: "保单号", width: '220px' },
      {
        key: "unit_id",
        type: "SEARCHSELECT",
        placeholder: "投保单位",
        width: '240px',
        options: insureUnitList,
      },
    ]
    const columns = [
      { title: '保单号', dataIndex: 'policy_no', align: 'center' },
      { title: '保单名称', dataIndex: 'cr_name', align: 'center' },
      { title: '保险公司', dataIndex: 'agt_name', align: 'center' },
      { title: '投保单位', dataIndex: 'unit_name', align: 'center' },
      { title: '保额（每人）', dataIndex: 'user_consumable_amount', align: 'center' },
      { title: '理赔上限（每人）', dataIndex: 'user_claim_amount', align: 'center' },
      { title: '保障人数', dataIndex: 'person', align: 'center' },
      { title: '起止日期', dataIndex: 'start_and_end_date', align: 'center', render: (_,record)=>(
        <span>{`${record.insure_date.join('-')}至${record.validity_date.join('-')}`}</span>
      ) },
      {
        title: '操作', dataIndex: 'x', align: 'center', render: (text, record) => (
          <span>
            <Button type='link'>
              <span className='underline'>理赔资料配置</span>
            </Button>
          </span>
        )
      },
    ]
    const pagination = {
      pageSize: searchCond.size,
      total: totalNum,
      showQuickJumper: true,
      current: searchCond.page,
      showTotal: (total, range) => {
        return (
          <span>
            目前显示{range[0]}-{range[1]} 条,共 {total} 条
          </span>
        );
      },
    }
    return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
      <div className='flexst mb15'>
        <CheckBox
          searchList={searchList}
          searchSubmit={this.searchSubmit}
          fields={searchCond}
        />
      </div>
      <Table
        rowKey="policy_no"
        columns={columns}
        pagination={pagination}
        dataSource={dataList}
        onChange={this.handleTableChange}
      />
    </div>
  }
}