import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { connect } from 'dva';
import router from "umi/router";

const namespace = 'policyholder';
@connect(({ policyholder }) => ({ policyholder }))
export default class ApplicantList extends Component {
    state = {
        crName: ''
    }
    componentDidMount() {
        const { cr_id, cr_name } = this.props.location.state
        const { searchCond } = this.props[namespace]
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: {
                searchCond: {
                    ...searchCond,
                    cr_id: cr_id
                }
            }
        })
        this.setState({
            crName: cr_name
        })
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            searchCond[k] = v;
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.props.dispatch({
            type: `${namespace}/fetchList`
        });
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: {
                searchCond: searchCond,
            },
        });
        this.props.dispatch({
            type: `${namespace}/fetchList`,
        });
    };

    render() {
        const mcStateColor = {
            true: '#52c41a',
            false: '#ff0000',
        }
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const searchList = [
            { key: "name", type: "INPUT", placeholder: "姓名", width: '180px' },
            { key: "mobile", type: "INPUT", placeholder: "手机号", width: '180px' },
            { key: "id_no", type: "INPUT", placeholder: "证件号", width: '220px' }
        ]
        const columns = [
            { title: '保险公司', dataIndex: 'agt_name', align: 'center' },
            { title: '投保单位', dataIndex: 'unit_name', align: 'center' },
            { title: '姓名', dataIndex: 'name', align: 'center' },
            { title: '证件号', dataIndex: 'id_no', align: 'center' },
            { title: '手机号', dataIndex: 'mobile', align: 'center' },
            { title: '可用金额', dataIndex: 'public_amount', align: 'center' },
            { title: '个人可理赔金额', dataIndex: 'balance', align: 'center' },
            { title: '开始日期', dataIndex: 'insure_date', align: 'center' },
            { title: '截止日期', dataIndex: 'validity_date', align: 'center' },
            { title: '权益状态', dataIndex: 'mc_state', align: 'center' },
            // {
            //     title: '理赔授权激活状', dataIndex: 'signature', align: 'center', render: text =>
            //     (
            //         <span style={{ color: mcStateColor[text] || '' }}>{text ? '已签名' : '未签名'}</span>
            //     )
            // },
            // {
            //     title: '保障激活状态', dataIndex: 'mc_state', align: 'center', render: text =>
            //     (
            //         <span style={{ color: mcStateColor[text] || '' }}>{text ? '已激活' : '未激活'}</span>
            //     )
            // },
        ]
        const pagination = {
            pageSize: searchCond.size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <div className='flexst mb15'>
                <Button
                    type='primary'
                    ghost
                    onClick={() => {
                        router.goBack();
                    }}
                >
                    <LeftOutlined />返回
                </Button>
                <div className='ml20 ft16 color333 fwbold'>{this.state.crName}</div>
            </div>
            <div className='mb15'>
                <CheckBox
                    searchList={searchList}
                    searchSubmit={this.searchSubmit}
                    fields={searchCond}
                />
            </div>
            <Table
                rowKey='id'
                pagination={pagination}
                columns={columns}
                dataSource={dataList}
                onChange={this.handleTableChange}
            />
        </div>
    }
}