import { message } from "antd";
import { getRecruitList, getRecruitDetail } from "@/service/join";

export default {
  namespace: "join",
  state: {
    searchCond: {
      page_size: 10,
      page: 1,
    },
    dataList: [],
    totalNum: 0,
  },
  effects: {
    *fetchList({ payload }, { call, put, select }) {
      const searchCond = yield select((state) => state.join.searchCond);
      const { items, count } = yield call(getRecruitList, searchCond);
      yield put({
        type: "set",
        payload: {
          dataList: items,
          totalNum: count,
        },
      });
    },

    *fetchRecruitDetail({ payload }, { call, put, select }) {
      const res = yield call(getRecruitDetail, payload);
      return res;
    },
  },

  reducers: {
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
