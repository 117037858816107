import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'global', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/models/global.js').default) });
app.model({ namespace: 'service', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/service/models/service.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/drug/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/news/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/join/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/dataMoudle/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/insuranceManage/insuranceStaff/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/insuredUnitManagement/unitManagement/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/insuredUnitManagement/unitStaff/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/proposalFormManagement/proposalFormInfo/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/proposalFormManagement/policyholder/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/serviceProvidorManagement/organization/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/drugOrderManage/expenseInfo/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/detailManage/claimDetail/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/detailManage/quotaDetail/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/detailManage/TPADetail/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/serviceManage/groupManageer/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/basicModule/enterpriseManage/configuration/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/businessCenter/claimOperateManage/claimSetting/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/report/quotaReport/models/index.js').default) });
app.model({ namespace: 'index', ...(require('/builds/middle-platform/laobai-platform/laobai-open-platform-web/src/pages/platform/report/claimReport/models/index.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
