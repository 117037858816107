import React, { Component } from "react";
import style from "./index.less";
import { Table, Carousel } from "antd";
import { connect } from "dva";
import FlyInAnimation from "@/component/flyInAnimation";
import {
  bannerData,
  peopleArr,
  abilityArr,
  ourData,
  ourData1,
  ourData2,
} from "./joinData";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import MyTitle from "@/component/title/title";

const namespace = "join";
@connect(({ join }) => ({
  join,
}))
export default class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: 1,
      previewShow: false,
      tab: 1,
      positionY: 0,
      highlightRow: null,
      tabList: [{ id: 1, name: "社会招聘" }, { id: 0, name: "校园招聘" }],
      serviceActive: [[false, false], [false, false], [false, false]],
      solutionTimerDisable: false,
      detail: {},
      expandedRowKeys: [],
    };
    this.carouselRef1 = React.createRef();
    this.carouselRef2 = React.createRef();
    this.carouselRef3 = React.createRef();
    this.carouselRef4 = React.createRef();
    this.carouselRef5 = React.createRef();
    this.carouselRef6 = React.createRef();
    const { searchCond } = this.props[namespace];
    searchCond.recruit_type = 1;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.getList();
  }

  getList = () => {
    this.props.dispatch({
      type: `${namespace}/fetchList`,
    });
  };

  changeTab = (value) => {
    const { searchCond } = this.props[namespace];
    this.setState({ tab: value });
    searchCond.page = 1;
    searchCond.recruit_type = value;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.getList();
  };

  handleTableChange = (current) => {
    const { searchCond } = this.props[namespace];
    searchCond.page = current;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: { searchCond },
    });
    this.getList();
  };

  rowClassName = (record, index) => {
    const { highlightRow } = this.state;
    if (index === highlightRow) {
      return style.heighLight;
    } else {
      return "";
    }
  };

  onRowMouseEnter = (rowIndex) => {
    this.setState({ highlightRow: rowIndex });
  };

  handleSelect = (selectedKey) => {
    this.setState({ selectedKey });
    const idList = {
      1: "id1",
      2: "id2",
      3: "id3",
      4: "id4",
    };
    this.scrollToAnchor(idList[selectedKey]);
  };

  goDetail = (item) => {
    const routerLink = {
      1: "https://www.laobai.com/news/detail?id=1784782679105277954",
      2: "https://www.laobai.com/news/detail?id=1784781670337417218",
      3: "",
      4: "https://www.laobai.com/news/detail?id=1784784792812195841",
      5: "https://www.laobai.com/news/detail?id=1784777340792279042",
    };
    if (item.key != 3) {
      window.location.href = routerLink[item.key];
    }
  };

  componentDidMount() {
    const { serviceActive } = this.state;
    serviceActive[0][0] = true;
    this.setState({ serviceActive });
    setTimeout(() => {
      serviceActive[0][1] = true;
      this.setState({ serviceActive });
    }, 1000);
    window.addEventListener("scroll", this.changeScrollTopShow, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeScrollTopShow, true);
  }

  changeScrollTopShow = () => {
    const mainEle = document.getElementsByClassName("point");
    const pos = mainEle[0].getBoundingClientRect();
    this.setState({ positionY: pos.top });
    this.activeAnimation(pos.top);
  };

  activeAnimation = (positionY) => {
    const { serviceActive } = this.state;

    // 提升自己动画
    if (positionY < -476) {
      serviceActive[1][0] = true;
      this.setState({ serviceActive });
      setTimeout(() => {
        serviceActive[1][1] = true;
        this.setState({ serviceActive });
      }, 1000);
    }
    if (positionY < -1050) {
      serviceActive[2][0] = true;
      this.setState({ serviceActive });
      setTimeout(() => {
        serviceActive[2][1] = true;
        this.setState({ serviceActive });
      }, 1000);
    }
  };

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  render() {
    const { searchCond, totalNum, dataList = [] } = this.props[namespace];
    const {
      tab,
      tabList,
      selectedKey,
      serviceActive,
      detail,
      expandedRowKeys,
      previewShow,
    } = this.state;

    const pagination = {
      pageSize: searchCond.size,
      total: totalNum,
      showQuickJumper: true,
      current: searchCond.page,
      showTotal: (total) => {
        return <span>共 {total} 条</span>;
      },
    };
    const columns = [
      // { title: '岗位名称', dataIndex: 'position_name', align: 'center', width: '150px' },
      { title: "岗位名称", dataIndex: "position_name", align: "center" },
      { title: "岗位类别", dataIndex: "position_type", align: "center" },
      {
        title: "工作地点",
        dataIndex: "region",
        align: "center",
        render: (_text, record) =>
          `${record.province_name ||
            record.province_code ||
            "-"}·${record.city_name || "-"}`,
      },
      { title: "发布时间", dataIndex: "release_time", align: "center" },
      {
        title: "",
        dataIndex: "caozuo",
        align: "center",
        render: (_text, record, _index) => {
          return (
            <div
              style={{ color: "#0661C9", fontSize: "16px" }}
              onClick={() => {
                // router.push(`/joinUs/detail?id=${record.recruit_id}`);
                if (expandedRowKeys.length == 0) {
                  this.props
                    .dispatch({
                      type: `${namespace}/fetchRecruitDetail`,
                      payload: { recruitId: record.recruit_id },
                    })
                    .then((res) => {
                      this.setState({
                        detail: res,
                        expandedRowKeys: [record.recruit_id],
                      });
                    });
                } else {
                  this.setState({
                    detail: {},
                    expandedRowKeys: [],
                  });
                }
              }}
            >
              {expandedRowKeys.includes(record.recruit_id) ? "收起" : "展开"}
            </div>
          );
        },
      },
    ];

    const expandedRowRender = () => {
      return (
        <div>
          <div className={style.mainTitle}>岗位详情</div>
          <div style={{ whiteSpace: "pre-line" }}>
            {detail.position_responsibility}
          </div>
          <div className={style.subTitle}>任职要求</div>
          <div style={{ whiteSpace: "pre-line" }}>
            {detail.position_requirement}
          </div>
          <div className="flexcenter">
            <div
              className={style.deliver}
              onClick={() => {
                this.setState({
                  previewShow: true,
                });
              }}
            >
              投递简历
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div
          className={style.preview}
          style={{ display: previewShow ? "" : "none" }}
        >
          <div className={style.previewContent}>
            <img src={require("@/assets/joinUs/preview.png")} />
            <div className={style.resumeInfo}>
              <div className={style.resume}>
                <img src={require("@/assets/joinUs/resume.png")} />
                <div>简历投递邮箱：zhaopin@laobai.com</div>
              </div>
              <div className={style.bottomButton}>
                <div
                  className={style.sureButton}
                  onClick={() => {
                    this.setState({
                      previewShow: false,
                    });
                  }}
                >
                  我知道了
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.wrapper}>
          <div
            className={style.banner}
            style={{ height: "auto", width: "100%" }}
          >
            <img src={require("@/assets/joinUs/joinUs.png")} />
          </div>
          <div className={style.selectedModule}>
            {bannerData.map((item) => {
              return (
                <div
                  key={item.key}
                  className={style.moduleItem}
                  style={{
                    borderLeft: item.key == 1 ? "1px solid #ddd" : "",
                  }}
                  onClick={() => {
                    this.handleSelect(item.key);
                  }}
                >
                  {item.key == selectedKey && (
                    <img src={require("@/assets/joinUs/selected.png")} />
                  )}
                  <span
                    style={{
                      color: item.key == selectedKey ? "#fff" : "",
                    }}
                  >
                    {item.text}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={style.content}>
            <div
              className={style.content1}
              id="id1"
              style={{ paddingTop: 78, marginTop: -78 }}
            >
              <div className="fs24 fwbold">
                在老白，你可以有这样的机会提升自己
              </div>
              <div className={style.line} />
              <div style={{ width: "100%", backgroundColor: "#fff" }}>
                <div className={style.box} style={{ padding: "60px 0" }}>
                  <div className={style.carouselIcon}>
                    <LeftCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef1.current.prev();
                        this.carouselRef2.current.prev();
                      }}
                    />
                    <RightCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef1.current.next();
                        this.carouselRef2.current.next();
                      }}
                    />
                  </div>
                  <div className={style.caseItem}>
                    <FlyInAnimation show={serviceActive[0][0]}>
                      <div style={{ width: 660, opacity: 0 }}>
                        <Carousel ref={this.carouselRef1} dots={false} autoplay>
                          {ourData.map((item, index) => (
                            <img
                              style={{ verticalAlign: "bottom" }}
                              key={index}
                              src={item.img}
                              alt=""
                            />
                          ))}
                        </Carousel>
                      </div>
                    </FlyInAnimation>
                    <FlyInAnimation show={serviceActive[0][1]}>
                      <div className={style.descriptionHidden}>
                        <div className={style.descriptionWrapper}>
                          <Carousel ref={this.carouselRef2} autoplay>
                            {ourData.map((item, index) => (
                              <div key={index} className={style.description}>
                                <div className="fs24 mb10">{item.title}</div>
                                <div className="fs16">{item.content}</div>
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </FlyInAnimation>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", backgroundColor: "#fff" }}>
                <div className={style.box} style={{ padding: "60px 0" }}>
                  <div className={style.carouselIcon}>
                    <LeftCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef3.current.prev();
                        this.carouselRef4.current.prev();
                      }}
                    />
                    <RightCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef3.current.next();
                        this.carouselRef4.current.next();
                      }}
                    />
                  </div>
                  <div className={style.caseItem}>
                    <FlyInAnimation show={serviceActive[1][0]}>
                      <div style={{ width: 660, opacity: 0 }}>
                        <Carousel ref={this.carouselRef3} dots={false} autoplay>
                          {ourData1.map((item, index) => (
                            <img
                              style={{ verticalAlign: "bottom" }}
                              key={index}
                              src={item.img}
                              alt=""
                            />
                          ))}
                        </Carousel>
                      </div>
                    </FlyInAnimation>
                    <FlyInAnimation show={serviceActive[1][1]}>
                      <div className={style.descriptionHidden}>
                        <div className={style.descriptionWrapper}>
                          <Carousel ref={this.carouselRef4} autoplay>
                            {ourData1.map((item, index) => (
                              <div key={index} className={style.description}>
                                <div className="fs24 mb10">{item.title}</div>
                                <div className="fs16">{item.content}</div>
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </FlyInAnimation>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", backgroundColor: "#fff" }}>
                <div className={style.box} style={{ padding: "60px 0" }}>
                  <div className={style.carouselIcon}>
                    <LeftCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef5.current.prev();
                        this.carouselRef6.current.prev();
                      }}
                    />
                    <RightCircleOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.carouselRef5.current.next();
                        this.carouselRef6.current.next();
                      }}
                    />
                  </div>
                  <div className={style.caseItem}>
                    <FlyInAnimation show={serviceActive[2][0]}>
                      <div style={{ width: 660, opacity: 0 }}>
                        <Carousel ref={this.carouselRef5} dots={false} autoplay>
                          {ourData2.map((item, index) => (
                            <img
                              style={{ verticalAlign: "bottom" }}
                              key={index}
                              src={item.img}
                            />
                          ))}
                        </Carousel>
                      </div>
                    </FlyInAnimation>
                    <FlyInAnimation show={serviceActive[2][1]}>
                      <div className={style.descriptionHidden}>
                        <div className={style.descriptionWrapper}>
                          <Carousel ref={this.carouselRef6} autoplay>
                            {ourData2.map((item, index) => (
                              <div key={index} className={style.description}>
                                <div className="fs24 mb10">{item.title}</div>
                                <div className="fs16">{item.content}</div>
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </FlyInAnimation>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={style.content2}
              id="id2"
              style={{ paddingTop: 78, marginTop: -78 }}
            >
              <div className="fs24 fwbold">人人机会平等，为奋斗者提供舞台</div>
              <div className={style.line} />
              <img src={require("@/assets/joinUs/fighter.png")} />
            </div>
            <div
              className={style.content3}
              id="id3"
              style={{ paddingTop: 78, marginTop: -78 }}
            >
              <div className="fs24 fwbold">前行有星光，未来共奋进</div>
              <div className={style.line} />
              <div className={style.head}>
                {peopleArr.map((item) => {
                  return (
                    <div
                      className={style.headItem}
                      key={`${item.key}`}
                      onClick={() => this.goDetail(item)}
                    >
                      <img src={item.head} />
                      <div className={style.name}>{item.name}</div>
                      <div className={style.university}>{item.university}</div>
                      <div className={style.intro}>{item.intro}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={style.contentVideo}>
              <video
                controls
                src={require("@/assets/joinUs/joinUs.mp4")}
                width={900}
              />
            </div>
            <div
              className={style.content4}
              id="id4"
              style={{ paddingTop: 78, marginTop: -78 }}
            >
              <div className="fs24 fwbold">
                如果你具备以下九项胜任力，欢迎加入我们
              </div>
              <div className={style.line} />
              <div className={style.content}>
                <div className={style.contentTop}>
                  {abilityArr.map((item, index) => {
                    return (
                      <div
                        key={`${item.key}+${index}`}
                        className={style.contentItem}
                      >
                        <img src={item.icon} />
                        <div>{item.text}</div>
                      </div>
                    );
                  })}
                </div>
                <div className={style.contentButton}>加入我们</div>
              </div>
            </div>
            <div style={{ marginTop: 100 }} className={style.content5}>
              <MyTitle title="老白健康，寻找优秀的你"></MyTitle>
              <div className={style.resume}>
                <div className={style.resumeTootip}></div>
                <img src={require("@/assets/joinUs/resume.png")} />
                <div>简历投递邮箱：zhaopin@laobai.com</div>
              </div>
            </div>
          </div>
          <div className={style.mainContent}>
            <div className={style.btnWrapper}>
              {tabList.map((item, index) => (
                <div
                  key={`${item.id}+${index}`}
                  onClick={() => {
                    this.changeTab(item.id);
                  }}
                  className={
                    tab == item.id ? `${style.btn} ${style.active}` : style.btn
                  }
                >
                  {item.name}
                </div>
              ))}
            </div>
            <Table
              onRow={(record, index) => {
                return {
                  // onClick: () => {
                  //   router.push(`/joinUs/detail?id=${record.recruit_id}`);
                  // },
                  // onMouseEnter: () => {
                  //   this.onRowMouseEnter(index);
                  // },
                };
              }}
              expandable={{
                expandIcon: null,
                expandedRowKeys: expandedRowKeys,
                expandedRowRender,
                expandIconAsCell: false,
                expandIconColumnIndex: -1,
              }}
              rowKey="recruit_id"
              columns={columns}
              pagination={pagination}
              dataSource={dataList}
              rowClassName={this.rowClassName}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </>
    );
  }
}
