import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import _ from 'lodash';

const namespace = 'unitManagement';
@connect(({ unitManagement }) => ({ unitManagement }))
export default class UnitManagement extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            if (v) {
                searchCond[k] = v
            } else {
                delete searchCond[k]
            }
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.page_size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const searchList = [
            { key: "unit_no", type: "INPUT", placeholder: "投保单位编号", width: '220px' },
            { key: "unit_name", type: "INPUT", placeholder: "投保单位名称", width: '220px' },
        ]
        const columns = [
            { title: '投保单位编号', dataIndex: 'unit_no', align: 'center', width: '150px' },
            { title: '投保单位名称', dataIndex: 'unit_name', align: 'center', width: '150px' },
            { title: '上级投保单位', dataIndex: 'p_unit_name', align: 'center', width: '150px' },
            { title: '联系人', dataIndex: 'unit_contact', align: 'center', width: '150px' },
            { title: '联系电话', dataIndex: 'unit_phone', align: 'center', width: '150px' },
            { title: '邮箱', dataIndex: 'unit_email', align: 'center', width: '150px' },
            { title: '行业分类', dataIndex: 'trade_class', align: 'center', width: '150px' },
            { title: '企业性质', dataIndex: 'business_nature', align: 'center', width: '150px' },
            { title: '集团公司', dataIndex: 'group_company', align: 'center', width: '150px' },
            { title: '备注', dataIndex: 'unit_remark', align: 'center', width: '150px' },
            // {
            //     title: '操作', dataIndex: '', align: 'center', fixed: 'right', width: '200px',
            //     render: (text, record) => (
            //         <>
            //             <Button style={{ marginRight: '10px' }} type='primary' onClick={() => {
            //                 openModal(record)
            //             }}>编辑</Button>
            //         </>
            //     )
            // },
        ]
        const pagination = {
            pageSize: searchCond.page_size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
            />
            <Table
                rowKey="unit_id"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
            />
        </div>
    }
}