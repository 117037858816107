import Plugins from './plugins';
import StylePlugin from './plugins/StylePlugin';
import ArrayPlugin from './plugins/ArrayPlugin';
import GradientsPlugin from './plugins/GradientsPlugin';
import PathMotionPlugin from './plugins/PathMotionPlugin';
import SvgDrawPlugin from './plugins/SvgDrawPlugin';
import SvgMorphPlugin from './plugins/SvgMorphPlugin';
import Ticker from './animate/ticker';
import Easing from './animate/ease';
import TweenOne, { Tween, globalTimeline } from './TweenOne';
export { Tween, Ticker, Plugins, Easing, globalTimeline, StylePlugin, ArrayPlugin, GradientsPlugin, PathMotionPlugin, SvgDrawPlugin, SvgMorphPlugin };
export * from './typings';
export * from './typings/IAnimObject';
export default TweenOne;