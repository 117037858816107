import React, { Component } from 'react';
import { Button, Table } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { connect } from 'dva';
import router from "umi/router";

const namespace = 'expenseInfo';
@connect(({ expenseInfo }) => ({ expenseInfo }))
export default class ConsumeDetail extends Component {
    componentDidMount() {
        const { co_id } = this.props.location.state
        this.props.dispatch({
            type: `${namespace}/fetchConsumeDetail`,
            payload: co_id
        })
    }

    render() {
        const { consumeDetailList } = this.props[namespace]
        const columns = [
            // { title: '订单ID', dataIndex: 'co_id', align: 'center' },
            { title: '药品名', dataIndex: 'gd_name', align: 'center' },
            // { title: '药品代码', dataIndex: 'gd_code', align: 'center' },
            // { title: '药品准字', dataIndex: 'gd_approval_num', align: 'center' },
            // { title: '药品类目', dataIndex: 'gd_category', align: 'center' },
            { title: '药品数量', dataIndex: 'gd_num', align: 'center' },
            { title: '药品单价', dataIndex: 'gd_price', align: 'center',render: (text, record) => (text?Number(text).toFixed(2) :0)  },
            // { title: '药品简介', dataIndex: 'gd_info', align: 'center' },
            // { title: '生产日期', dataIndex: 'manufacture_date', align: 'center' },

        ]
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <div className='flexst mb15'>
                <Button
                    type='primary'
                    ghost
                    onClick={() => {
                        router.goBack();
                    }}
                >
                    <LeftOutlined />返回
                </Button>
            </div>
            <Table
                rowKey='detail_id'
                columns={columns}
                dataSource={consumeDetailList}
            />
        </div>
    }
}