'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCssLowerCase = toCssLowerCase;
exports.toStyleUpperCase = toStyleUpperCase;
exports.toFixed = toFixed;
exports.createMatrix = createMatrix;
exports.checkStyleName = checkStyleName;
exports.getGsapType = getGsapType;
exports.parseColor = parseColor;
exports.parseShadow = parseShadow;
exports.getColor = getColor;
exports.isTransform = isTransform;
exports.isConvert = isConvert;
exports.splitFilterToObject = splitFilterToObject;
exports.getMatrix = getMatrix;
exports.getTransform = getTransform;
exports.stylesToCss = stylesToCss;
exports.getUnit = getUnit;
exports.getValues = getValues;
exports.findStyleByName = findStyleByName;
exports.mergeStyle = mergeStyle;
var isUnitlessNumber = {
  animationIterationCount: true,
  borderImageOutset: true,
  borderImageSlice: true,
  borderImageWidth: true,
  boxFlex: true,
  boxFlexGroup: true,
  boxOrdinalGroup: true,
  columnCount: true,
  flex: true,
  flexGrow: true,
  flexPositive: true,
  flexShrink: true,
  flexNegative: true,
  flexOrder: true,
  gridRow: true,
  gridColumn: true,
  fontWeight: true,
  lineClamp: true,
  lineHeight: true,
  opacity: true,
  order: true,
  orphans: true,
  tabSize: true,
  widows: true,
  zIndex: true,
  zoom: true,

  // SVG-related properties
  fillOpacity: true,
  floodOpacity: true,
  stopOpacity: true,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: true,
  strokeWidth: true
};
var prefixes = ['Webkit', 'ms', 'Moz', 'O'];

function prefixKey(prefix, key) {
  return prefix + key.charAt(0).toUpperCase() + key.substring(1);
}

Object.keys(isUnitlessNumber).forEach(function (prop) {
  prefixes.forEach(function (prefix) {
    isUnitlessNumber[prefixKey(prefix, prop)] = isUnitlessNumber[prop];
  });
});

var unquotedContentValueRegex = /^(normal|none|(\b(url\([^)]*\)|chapter_counter|attr\([^)]*\)|(no-)?(open|close)-quote|inherit)((\b\s*)|$|\s+))+)$/;

var IE = function () {
  if (typeof document === 'undefined') {
    return false;
  }
  if (navigator && (navigator.userAgent.indexOf("MSIE 8.0") > 0 || navigator.userAgent.indexOf("MSIE 9.0") > 0)) {
    return true;
  }
  return false;
}();

var rnd = 100000;

var colorLookup = {
  aqua: [0, 255, 255, 1],
  lime: [0, 255, 0, 1],
  silver: [192, 192, 192, 1],
  black: [0, 0, 0, 1],
  maroon: [128, 0, 0, 1],
  teal: [0, 128, 128, 1],
  blue: [0, 0, 255, 1],
  navy: [0, 0, 128, 1],
  white: [255, 255, 255, 1],
  fuchsia: [255, 0, 255, 1],
  olive: [128, 128, 0, 1],
  yellow: [255, 255, 0, 1],
  orange: [255, 165, 0, 1],
  gray: [128, 128, 128, 1],
  purple: [128, 0, 128, 1],
  green: [0, 128, 0, 1],
  red: [255, 0, 0, 1],
  pink: [255, 192, 203, 1],
  cyan: [0, 255, 255, 1],
  transparent: [255, 255, 255, 0]
};

var DEG2RAD = Math.PI / 180;
var RAD2DEG = 180 / Math.PI;

var $cssList = {
  _lists: {
    transformsBase: ['translate', 'translateX', 'translateY', 'scale', 'scaleX', 'scaleY', 'skewX', 'skewY', 'rotateZ', 'rotate'],
    transforms3D: ['translate3d', 'translateZ', 'scaleZ', 'rotateX', 'rotateY', 'perspective']
  },
  transformGroup: { translate: 1, translate3d: 1, scale: 1, scale3d: 1, rotate: 1, rotate3d: 1, skew: 1 },
  filter: ['grayScale', 'sepia', 'hueRotate', 'invert', 'brightness', 'contrast', 'blur'],
  filterConvert: { grayScale: 'grayscale', hueRotate: 'hue-rotate' }
};
$cssList._lists.transformsBase = !IE ? $cssList._lists.transformsBase.concat($cssList._lists.transforms3D) : $cssList._lists.transformsBase;

var hexExp = exports.hexExp = /#([0-9a-f]{8}|[0-9a-f]{6}|[0-9a-f]{3})/;

var rgbAndHslExp = exports.rgbAndHslExp = /((rgb|hsl)[a]?)+\((?:\d|\.\d)+(?:(deg|\%|)),[\s+]?(?:\d|\.\d)+(?:(deg|\%|)),[\s+]?(?:\d|\.\d)+(?:(deg|%|))(,[\s+]?(?:\d|\.\d)+(?:(deg|\%|)))?\)/;

var colorRegExp = exports.colorRegExp = /#([0-9a-f]{8}|[0-9a-f]{6}|[0-9a-f]{3})|((rgb|hsl)[a]?)+\((?:\d|\.\d)+(?:(deg|\%)?),[\s+]?(?:\d|\.\d)+(?:(deg|\%)?),[\s+]?(?:\d|\.\d)+(?:(deg|%)?)(,[\s+]?(?:\d|\.\d)+(?:(deg|\%)?))?\)/;

var colorNumExp = exports.colorNumExp = /(?:\d|\.\d)+(%?)/g;

var cssList = exports.cssList = $cssList;

function toCssLowerCase(d) {
  return d.replace(/[A-Z]/, function ($1) {
    return '-' + $1.toLocaleLowerCase();
  });
}

function toStyleUpperCase(d) {
  return d.replace(/-(.?)/g, function ($1) {
    return $1.replace('-', '').toLocaleUpperCase();
  });
}

function toFixed(num, length) {
  var _rnd = length ? Math.pow(10, length) : rnd;
  var n = num | 0;
  var dec = num - n;
  var fixed = num;
  if (dec) {
    var r = (dec * _rnd + (num < 0 ? -0.5 : 0.5) | 0) / _rnd;
    var t = r | 0;
    var str = r.toString();
    var decStr = str.split('.')[1] || '';
    fixed = '' + (num < 0 && !(n + t) ? '-' : '') + (n + t) + '.' + decStr;
  }
  return parseFloat(fixed);
}

function createMatrix(style) {
  if (typeof document === 'undefined') {
    return null;
  }
  var matrixs = ['WebKitCSS', 'MozCSS', 'DOM', 'MsCSS', 'MSCSS', 'OCSS', 'CSS'].filter(function (key) {
    return key + 'Matrix' in window;
  });
  if (matrixs.length) {
    return new window[matrixs[0] + 'Matrix'](style);
  }
  console.warn('Browsers do not support matrix.');
  return '';
}

function checkStyleName(p) {
  if (typeof document === 'undefined') {
    return null;
  }
  var a = ['O', 'Moz', 'ms', 'Ms', 'Webkit'];
  if (p !== 'filter' && p in document.body.style) {
    return p;
  }
  var _p = p.charAt(0).toUpperCase() + p.substr(1);
  var prefixCss = a.filter(function (key) {
    return '' + key + _p in document.body.style;
  });
  return prefixCss[0] ? '' + prefixCss[0] + _p : null;
}

function getGsapType(_p) {
  var p = _p;
  p = p === 'x' ? 'translateX' : p;
  p = p === 'y' ? 'translateY' : p;
  p = p === 'z' ? 'translateZ' : p;
  // p = p === 'r' ? 'rotate' : p;
  return p;
}

// href: https://www.w3schools.com/lib/w3color.js
var hueToRgb = function hueToRgb(t1, t2, hue) {
  if (hue < 0) hue += 6;
  if (hue >= 6) hue -= 6;
  if (hue < 1) return (t2 - t1) * hue + t1;else if (hue < 3) return t2;else if (hue < 4) return (t2 - t1) * (4 - hue) + t1;else return t1;
};

function parseColor(value) {
  var colorArray = colorLookup.transparent;
  var color = value;
  var r = void 0;
  var g = void 0;
  var b = void 0;
  if (!color) {
    colorArray = colorLookup.transparent;
  } else if (colorLookup[color]) {
    colorArray = colorLookup[color];
  } else if (typeof color === 'number') {
    colorArray = [color >> 16, color >> 8 & 255, color & 255];
  } else {
    if (color.charAt(color.length - 1) === ',') {
      color = color.substr(0, color.length - 1);
    }
    if (color.match(hexExp)) {
      color = color.substr(1);
      // is #FFF
      if (color.length === 3) {
        r = color.charAt(0);
        g = color.charAt(1);
        b = color.charAt(2);
        color = '' + r + r + g + g + b + b + 'ff';
      }
      if (color.length === 6) {
        color += 'ff';
      }
      color = parseInt(color, 16);
      colorArray = [color >> 24 & 255, color >> 16 & 255, color >> 8 & 255, parseFloat(((color & 255) / 255).toFixed(2))];
    } else if (color.match(/^hsl/)) {
      colorArray = color.match(colorNumExp);
      var alpha = colorArray[3];
      alpha = typeof alpha === 'string' && alpha.match('%') ? parseFloat(alpha) / 100 : parseFloat('' + (alpha || '1'));
      var hue = parseFloat(colorArray[0]) / 60;
      var sat = parseFloat(colorArray[1]) / 100;
      var light = parseFloat(colorArray[2]) / 100;
      var t2 = light <= 0.5 ? light * (sat + 1) : light + sat - light * sat;
      var t1 = light * 2 - t2;
      r = Math.round(hueToRgb(t1, t2, hue + 2) * 255);
      g = Math.round(hueToRgb(t1, t2, hue) * 255);
      b = Math.round(hueToRgb(t1, t2, hue - 2) * 255);
      colorArray = [r, g, b, alpha];
    } else {
      // rgb(a?) 拆分
      colorArray = color.match(colorNumExp) || colorLookup.transparent;
      colorArray = colorArray.map(function (c) {
        return parseFloat(c);
      });
      if (colorArray.length === 3) {
        colorArray.push(1);
      }
    }
  }
  return colorArray;
}

function parseShadow(v, key) {
  /**
   * text-shadow: x y blur color;
   * box-shadow: x y blur spread color inset;
   */
  var textKey = key && toStyleUpperCase(key);
  if (!v) {
    if (textKey === 'boxShadow') {
      return [0, 0, 0, 0, 0, 0, 0, 0]; //0.85
    }
    return [0, 0, 0, 0, 0, 0, 0];
  }
  var vArr = v.replace(/,\s+/gi, ',').split(/\s+/).filter(function (c) {
    return c;
  });
  var inset = vArr.indexOf('inset');
  if (inset >= 0) {
    vArr.splice(inset, 1);
  }
  var colorStr = vArr.find(function (c) {
    return colorLookup[c] || c.match(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})|(rgb|hsl)+(?:a)?\((.*)\)/i);
  }) || 'black';
  var colorIndex = vArr.indexOf(colorStr);
  vArr.splice(colorIndex, 1);
  var color = parseColor(colorStr);
  color[3] = typeof color[3] === 'number' ? color[3] : 1;
  if (textKey === 'boxShadow' && vArr.length < 4) {
    vArr.push(0);
  }
  return vArr.concat(color, inset >= 0 ? ['inset'] : []);
}

function getColor(v) {
  var rgba = v.length === 4 ? 'rgba' : 'rgb';
  var _vars = v.map(function (d, i) {
    return i < 3 ? Math.round(d) : d;
  });
  return rgba + '(' + _vars.join(',') + ')';
}

function isTransform(p) {
  return cssList._lists.transformsBase.indexOf(p) >= 0 ? 'transform' : p;
}

function isConvert(p) {
  var cssName = isTransform(p);
  return cssList.filter.indexOf(cssName) >= 0 ? 'filter' : cssName;
}

function splitFilterToObject(data) {
  if (data === 'none' || !data || data === '') {
    return null;
  }
  var filter = data.replace(/\s+/g, '').split(')').filter(function (item) {
    return item;
  });
  var startData = {};
  filter.forEach(function (item) {
    var dataArr = item.split('(');
    startData[dataArr[0]] = dataArr[1];
  });
  return startData;
}

function getMatrix(t) {
  var arr = t.match(/(?:\-|\b)[\d\-\.e]+\b/gi);
  var m = {};
  if (arr.length === 6) {
    m.m11 = parseFloat(arr[0]);
    m.m12 = parseFloat(arr[1]);
    m.m13 = 0;
    m.m14 = 0;
    m.m21 = parseFloat(arr[2]);
    m.m22 = parseFloat(arr[3]);
    m.m23 = 0;
    m.m24 = 0;
    m.m31 = 0;
    m.m32 = 0;
    m.m33 = 1;
    m.m34 = 0;
    m.m41 = parseFloat(arr[4]);
    m.m42 = parseFloat(arr[5]);
    m.m43 = 0;
    m.m44 = 0;
  } else {
    arr.forEach(function (item, i) {
      var ii = i % 4 + 1;
      var j = Math.floor(i / 4) + 1;
      m['m' + j + ii] = parseFloat(item);
    });
  }
  return m;
}

function transformNoMatrix(transform) {
  var tm = {};
  tm.translateX = 0;
  tm.translateY = 0;
  tm.translateZ = 0;
  tm.rotate = 0;
  tm.rotateX = 0;
  tm.rotateY = 0;
  tm.scaleX = 1;
  tm.scaleY = 1;
  tm.scaleZ = 1;
  tm.skewX = 0;
  tm.skewY = 0;
  tm.perspective = 0;
  (transform.trim().match(/(\w+)\([^\)]+\)/ig) || []).forEach(function (str) {
    var strArray = str.split('(');
    var key = strArray[0].trim();
    var value = strArray[1].replace(')', '').trim();
    if (value.match(/%|em|rem/ig)) {
      console.warn('value(' + value + ') must be absolute, not relative, has been converted to absolute.');
    }
    value = value.replace(/px|deg|\)/ig, '');
    if (cssList.transformGroup[key] && key !== 'rotate') {
      value = value.split(',').map(function (num) {
        return parseFloat(num);
      });
      if (key === 'scale3d' || key === 'translate3d') {
        ['X', 'Y', 'Z'].forEach(function (s, i) {
          var $key = key.substring(0, key.length - 2);
          tm['' + $key + s] = value[i] || tm['' + $key + s];
        });
      } else if (key === 'rotate3d') {
        tm.rotateX = value[0] && value[3] || tm.rotateX;
        tm.rotateY = value[1] && value[3] || tm.rotateY;
        tm.rotate = value[2] && value[3] || tm.rotate;
      } else {
        ['X', 'Y'].forEach(function (s, i) {
          tm['' + key + s] = value[i] || tm['' + key + s];
        });
      }
    } else {
      if (key === 'rotateZ') {
        tm.rotate = parseFloat(value) || tm.rotate;
      } else {
        tm[key] = parseFloat(value) || tm[key];
      }
    }
  });
  return tm;
}

function getTransform(transform) {
  var _transform = !transform || transform === 'none' || transform === '' ? 'matrix(1, 0, 0, 1, 0, 0)' : transform;
  if (!_transform.match('matrix')) {
    return transformNoMatrix(transform);
  }
  var m = getMatrix(_transform);
  var m11 = m.m11;
  var m12 = m.m12;
  var m13 = m.m13;
  var m14 = m.m14;
  var m21 = m.m21;
  var m22 = m.m22;
  var m23 = m.m23;
  var m24 = m.m24;
  var m31 = m.m31;
  var m32 = m.m32;
  var m33 = m.m33;
  var m34 = m.m34;
  var m43 = m.m43;
  var t1 = void 0;
  var t2 = void 0;
  var t3 = void 0;
  var tm = {};
  var angle = Math.atan2(m23, m33);
  var skewX = Math.tan(m21);
  var skewY = Math.tan(m12);
  var cos = void 0;
  var sin = void 0;
  // rotateX
  tm.rotateX = toFixed(angle * RAD2DEG) || 0;
  if (angle) {
    cos = Math.cos(-angle);
    sin = Math.sin(-angle);
    t1 = m21 * cos + m31 * sin;
    t2 = m22 * cos + m32 * sin;
    t3 = m23 * cos + m33 * sin;
    m31 = m21 * -sin + m31 * cos;
    m32 = m22 * -sin + m32 * cos;
    m33 = m23 * -sin + m33 * cos;
    m34 = m24 * -sin + m34 * cos;
    m21 = t1;
    m22 = t2;
    m23 = t3;
  }
  // rotateY
  angle = Math.atan2(-m13, m33);
  tm.rotateY = toFixed(angle * RAD2DEG) || 0;
  if (angle) {
    cos = Math.cos(-angle);
    sin = Math.sin(-angle);
    t1 = m11 * cos - m31 * sin;
    t2 = m12 * cos - m32 * sin;
    t3 = m13 * cos - m33 * sin;
    m32 = m12 * sin + m32 * cos;
    m33 = m13 * sin + m33 * cos;
    m34 = m14 * sin + m34 * cos;
    m11 = t1;
    m12 = t2;
    m13 = t3;
  }
  // rotateZ
  angle = Math.atan2(m12, m11);
  tm.rotate = toFixed(angle * RAD2DEG) || 0;
  if (angle) {
    cos = Math.cos(angle);
    sin = Math.sin(angle);
    t1 = m11 * cos + m12 * sin;
    t2 = m21 * cos + m22 * sin;
    t3 = m31 * cos + m32 * sin;
    m12 = m12 * cos - m11 * sin;
    m22 = m22 * cos - m21 * sin;
    m32 = m32 * cos - m31 * sin;
    m11 = t1;
    m21 = t2;
    m31 = t3;
  }

  if (tm.rotateX && Math.abs(tm.rotateX) + Math.abs(tm.rotate) > 359.9) {
    tm.rotateX = tm.rotate = 0;
    tm.rotateY = 180 - tm.rotateY || 0;
  }

  tm.scaleX = toFixed(Math.sqrt(m11 * m11 + m12 * m12 + m13 * m13));
  tm.scaleY = toFixed(Math.sqrt(m22 * m22 + m23 * m23));
  tm.scaleZ = toFixed(Math.sqrt(m31 * m31 + m32 * m32 + m33 * m33));
  // 不管 skewX skewY了；
  tm.skewX = skewX === -skewY ? 0 : skewX;
  tm.skewY = skewY === -skewX ? 0 : skewY;
  tm.perspective = m34 ? 1 / (m34 < 0 ? -m34 : m34) : 0;
  tm.translateX = m.m41;
  tm.translateY = m.m42;
  tm.translateZ = m43;
  return tm;
}

function stylesToCss(key, value) {
  var _value = void 0;
  if (!isUnitlessNumber[key] && typeof value === 'number') {
    _value = ' ' + value + 'px';
  } else if (key === 'content' && !unquotedContentValueRegex.test(value)) {
    _value = '\'' + value.replace(/'/g, "\\'") + '\'';
  }
  return _value || value;
}

function getUnit(p, v) {
  var currentUnit = v && v.toString().replace(/[^a-z|%]/ig, '');
  var unit = '';
  if (p.indexOf('translate') >= 0 || p.indexOf('perspective') >= 0 || p.indexOf('blur') >= 0) {
    unit = 'px';
  } else if (p.indexOf('skew') >= 0 || p.indexOf('rotate') >= 0) {
    unit = 'deg';
  }
  return currentUnit || unit;
}

function getValues(p, d, u) {
  return p + '(' + d + (u || '') + ')';
}

function findStyleByName(cssArray, name) {
  var ret = null;
  if (cssArray) {
    cssArray.forEach(function (_cname) {
      if (ret) {
        return;
      }
      var cName = _cname.split('(')[0];
      var a = cName in cssList.transformGroup && name.substring(0, name.length - 1).indexOf(cName) >= 0;
      var b = name in cssList.transformGroup && cName.substring(0, cName.length - 1).indexOf(name) >= 0;
      var c = cName in cssList.transformGroup && name in cssList.transformGroup && (cName.substring(0, cName.length - 2) === name || name.substring(0, name.length - 2) === cName);
      if (cName === name || a || b || c) {
        ret = _cname;
      }
    });
  }
  return ret;
}

function mergeStyle(current, change) {
  if (!current || current === '') {
    return change;
  }
  if (!change || change === '') {
    return current;
  }
  var _current = current.replace(/\s/g, '').split(')').filter(function (item) {
    return item !== '' && item;
  }).map(function (item) {
    return item + ')';
  });
  var _change = change.replace(/\s/g, '').split(')').filter(function (item) {
    return item !== '' && item;
  });
  _change.forEach(function (changeOnly) {
    var changeArr = changeOnly.split('(');
    var changeName = changeArr[0];
    var currentSame = findStyleByName(_current, changeName);
    if (!currentSame) {
      _current.push(changeOnly + ')');
    } else {
      var index = _current.indexOf(currentSame);
      _current[index] = changeOnly + ')';
    }
  });
  _current.forEach(function (item, i) {
    if (item.indexOf('perspective') >= 0 && i) {
      _current.splice(i, 1);
      _current.unshift(item);
    }
  });
  return _current.join(' ').trim();
}
