import React, { Component } from "react";
import style from './index.less'

export default class Notice extends Component {

	render () {
		return (
			<div className={style.wrapper}>
                <img className={style.topBanner} src={require('@/assets/home/notice_top_banner.png')} alt="" />
                <div className={style.content}>
                    <div className="color333 fs34 fwbold">严正声明</div>
                    <div className={style.divider} />
                    <div className="color999 fs18 mb40">时间：2024/1/22</div>
                    <img src={require('@/assets/notice.png')} alt="" />
                </div>
            </div>
		);
	}
}
