import React, { Component } from 'react'
import { connect } from 'dva';
import { Button } from 'antd'
import { ROLE_TYEP } from '@/common/constant'
import router from 'umi/router'
import { InboxOutlined } from '@ant-design/icons'
import styles from './index.less'
import { CopyrightOutlined } from '@ant-design/icons'
import Login from '../Login/Login.jsx'
import Register from '../Register/index.jsx'
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// import "swiper/swiper.min.css"; // core Swiper
// import "swiper/modules/effect-creative/effect-creative.min.css";
// import { Autoplay } from 'swiper';
import { loginOut } from '@/service/login';
import Link from 'umi/link';
import NoticeModal from '../../component/NoticeModal/index.js'

const namespace = 'global'
@connect(({ global }) => ({
  global
}))
export default class Entry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNoticeModal:false
    }
  }

  // componentDidMount() {
  //   const loginFlag = localStorage.getItem('isLogin')
  //   if (loginFlag) {
  //     router.push(`/home`)
  //   }
  // }
  // btnClick = (roleType) => {
  //   localStorage.setItem('roleType', roleType)
  //   router.push(`/home`)
  // }

  linkTo = (value) => {
    // localStorage.setItem('roleType','SOCIAL_ORGANIZATION')
    this.props.dispatch({type: `${namespace}/set`,payload: { showQrcode:false }})
    router.push(value)
  }

  loginOut = async() => {
    const token = localStorage.getItem('token')||''
    const res = await loginOut(token)
    if (res) {
      localStorage.clear()
      router.push('/')
    }
  }

  showNotice = () => {
    this.setState({showNoticeModal:true})
  }

  render() {
    const { login } = this.props[namespace]
    const { location: { pathname } } = this.props
    const { showNoticeModal } = this.state
    const host = location.host

    return (
      <div className={styles.entryConatiner}>
        <div className={styles.swiper}>
          <div className={`${styles.swiperSlide} ${styles.bg1}`}>
            {pathname === '/' ? null : pathname === '/login' ?<div className={styles.desc}>
              <div className={styles.title}>数字化医药福利管理</div>
              <div className={styles.text}>·商保好药-底层赋能</div>
              <div className={styles.text}>·智慧员福-健康保障</div>
            </div>: null }
          </div>
        </div>
        {pathname === '/' ?<img className={styles.notice} onClick={this.showNotice} src={require('../../assets/notice.png')} alt="" />:null}
        <div className={styles.contentBg} ref={(node) => this.bgRef = node}>
          <div className={styles.header}>
            <div onClick={() => { this.linkTo('/') }} style={{ cursor: 'pointer' }}><img src={require('../../assets/home/logo.png')} alt="" /></div>
            {!localStorage.getItem('token') ?
              <div className={styles.loginBtn} onClick={() => { this.linkTo('/login') }} >登录</div> :
              <div>您好，{localStorage.getItem('userName')}<Button className='ml15' style={{ backgroundColor: 'transparent', color: '#fff' }} onClick={() => { this.loginOut() }}>退出登录</Button></div>}
          </div>

          {/* <div className={styles.btn} onClick={() => this.btnClick(ROLE_TYEP.INSURANCE_INDIVIDUAL)}>我是保险公司</div> */}
          {/* {pathname === '/' ?
            <div className={styles.btnContainer}>
              <div className={styles.btn} onClick={() => this.btnClick(ROLE_TYEP.SOCIAL_ORGANIZATION)}>我是社会组织</div>
              <div className={styles.btn}>
                <div className={styles.btnDefault}>我是保险公司</div>
                <div className={styles.insureWrapper}>
                  <div className={styles.insureBtn} onClick={() => this.btnClick(ROLE_TYEP.INSURANCE_INDIVIDUAL)}>我是保险公司-个险</div>
                  <div className={styles.insureBtn} onClick={() => this.btnClick(ROLE_TYEP.INSURANCE_GROUP)}>我是保险公司-团险</div>
                </div>
              </div>
              <div className={styles.btn} onClick={() => this.btnClick(ROLE_TYEP.COMPANY_HR)}>我是企业人资</div>
              <div className={styles.btn} onClick={() => this.btnClick(ROLE_TYEP.PHARMACY)}>我是药企</div>
            </div>
            :
            pathname === '/login' ?<Login></Login>: <Register></Register>
          } */}
          { pathname === '/login' && <Login></Login>}
          { pathname === '/register' && <Register></Register>}
        </div>
        <div className={styles.entryFooter}>
          <div className={styles.footerTab}>
            <span onClick={() => { this.linkTo('/aboutus') }}><a>关于老白</a></span>
            <span className='ml15 mr15'>|</span>
            <span onClick={() => { this.linkTo('/service/contact') }}><a>联系我们</a></span>
          </div>
          {host.indexOf('laobaiyy') > -1 ? <div className={styles.copyright}>
              <span>海南老白健康科技有限公司 <CopyrightOutlined />2021-2024 laobaiyy.com版权所有 <a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                >ICP证：琼ICP备2021005742号</a>
              </span>
            </div> : <div>
            <div className={styles.copyright}>
              <span><a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                >浙ICP备11001156号-7 ICP证:浙B2-20200881</a>
              </span>
              <span className='ml10 mr10'>|</span>
              <span>专属客服热线：4008089697</span>
              <span className='ml10 mr10'>|</span>
              <span><Link target = "_blank" to={`/qualification?type=1`}>营业执照</Link></span>
              <span className='ml10 mr10'>|</span>
              <span><Link target = "_blank" to={`/qualification?type=2`}>增值电信业务经营许可证</Link></span>
              <span className='ml10 mr10'>|</span>
              <span><Link target = "_blank" to={`/qualification?type=3`}>互联网药品信息服务资格证书号(浙)-经营性-2019-0106</Link></span>
            </div>
            <div className={styles.copyright}>
            <span><Link target = "_blank" to={`/qualification?type=4`}>药品网络交易第三方平台备案编号(浙)网药平台备字〔2023〕第000001-000号</Link></span>
              <span className='ml10 mr10'>|</span>
              <span><Link target = "_blank" to={`/qualification?type=5`}>医疗器械网络交易服务第三方平台备案号(浙)网械平台备字 [2023] 第00004号</Link></span>
            </div>
            <div className={styles.copyright}>
              <span>Copyright<CopyrightOutlined /> 2021-2024laobai.com 版权所有</span>
            </div>
          </div>}
        </div>
        { showNoticeModal && <NoticeModal onCancel={()=>{this.setState({showNoticeModal:false})}} /> }
      </div >
    )
  }
}