import React, { Component } from 'react'
import style from './index.less'
import HomeBanner from '@/component/banner/index.jsx'
import MyTitle from '@/component/title/title'
import Provide from './insurance/provide.jsx'
import Certification from './insurance/certification.jsx'
import CompanyPromise from './insurance/companyPromise.jsx'
import FlyInAnimation from '../../component/flyInAnimation'
import { socialData, insuranceData, hrData, pharmacyData } from '@/common/homePage'
import router from 'umi/router'
const leftBtnList = [
  { id: 1, name: '我是社会组织' },
  { id: 2, name: '我是保险公司' },
  { id: 3, name: '我是企业人资' },
  { id: 4, name: '我是制药企业' },
]
export default class Home extends Component {
  state = {
    data: {},
    tab: 'insure',
    leftBtnId: 1,
    zhyfActive: [false,false],
    sbhyActive: [false,false],
    solutionActive:[false,false,false,false,false],
    solutionTimerDisable:false,
    setOpacity:false,
    serviceActive:[[false,false],[false,false]],
    showCertification:false,
  }
  componentDidMount() {
    window.addEventListener('scroll', this.changeScrollTopShow, true)
    this.getPageData()
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeScrollTopShow, true)
  }
  changeScrollTopShow = () => {
      const mainEle = document.getElementsByClassName('point');
      const pos = mainEle[0].getBoundingClientRect()
      this.activeAnimation(pos.top)
  }
  activeAnimation = (positionY) => {
    const { zhyfActive,sbhyActive,solutionActive,solutionTimerDisable,serviceActive } = this.state
    // 触发智慧员福动画
    if (positionY < -1000) {
      zhyfActive[0] = true
      this.setState({ zhyfActive })
      setTimeout(() => {
        zhyfActive[1] = true
        this.setState({ zhyfActive })
      }, 1000);
    }
    // 触发智慧员福动画
    if (positionY < -1400) {
      sbhyActive[0] = true
      this.setState({ sbhyActive })
      setTimeout(() => {
        sbhyActive[1] = true
        this.setState({ sbhyActive })
      }, 1000);
    }
    // 解决方案动画
    if (positionY < -2100 && !solutionTimerDisable) {
      let index = 0
      this.setState({solutionTimerDisable:true})
      const timer = setInterval(() => {
        solutionActive[index] = true
        index++
        this.setState({ solutionActive, })
      }, 600);
      setTimeout(() => {
        clearInterval(timer)
        this.setState({setOpacity:true})
      }, 3000);
    }
    // 服务案例动画
    if (positionY < -3700) {
      serviceActive[0][0] = true
      this.setState({serviceActive})
      setTimeout(()=>{
        serviceActive[0][1] = true
        this.setState({serviceActive})
      },1000)
    }
    if (positionY < -4100) {
      serviceActive[1][0] = true
      this.setState({serviceActive})
      setTimeout(()=>{
        serviceActive[1][1] = true
        this.setState({serviceActive})
      },1000)
    }
    if (positionY < -5800) {
      this.setState({showCertification:true})
    }
  }
  getPageData = () => {
    const { leftBtnId } = this.state
    const dataMap = {
      1: socialData,
      2: insuranceData,
      3: hrData,
      4: pharmacyData,
    }
    this.setState({
      data: dataMap[leftBtnId]
    })
  }
  changeTab = (value) => {
    this.setState({ tab: value })
  }
  changeLiftBtn = (value) => {
    this.setState({ leftBtnId: value }, () => {
      this.getPageData()
    })
  }
  handleRoute = (value) => {
    router.push(value)
  }
  handleArrow = () => {
    let anchorElement = document.getElementById('swiper');
    if (anchorElement) { anchorElement.scrollIntoView({ behavior: 'smooth' }) }
  }
  render() {
    const { data, tab, leftBtnId, showCertification } = this.state
    const { provideData = [] } = data
    return <div className={style.homePage}>
    <div className={style.videoWrapper}>
      <img src={require('@/assets/home/arrow_down.png')} alt="" onClick={this.handleArrow} />
      <video autoPlay playsInline loop preload='auto' muted poster={require("@/assets/home/videoPoster.png")} src={require("@/assets/home/homeVideo.mp4")}/>
    </div>
    <div className={style.pageContent} id='swiper'>
      <HomeBanner />
      <div style={{backgroundColor:'#fff',paddingTop:100}}>
        <div className={style.sbhyProdWrapper}>
          <FlyInAnimation show={this.state.zhyfActive[0]}>
            <img className={style.desc} onClick={()=>{this.handleRoute('/business?tab=1')}} src={require('@/assets/home/zhyfDesc.png')} alt="" />
          </FlyInAnimation>
          <FlyInAnimation show={this.state.zhyfActive[1]}>
            <img className={style.other} src={require('@/assets/home/zhyfRight.png')} alt="" />
          </FlyInAnimation>
        </div>
      </div>
      <div style={{backgroundColor:'#FAFCFE',paddingTop:100}}>
        <div className={style.sbhyProdWrapper}>
          <FlyInAnimation show={this.state.sbhyActive[0]}>
            <img className={style.other} src={require('@/assets/home/sbhyLeft.png')} alt="" />
          </FlyInAnimation>
          <FlyInAnimation show={this.state.sbhyActive[1]}>
            <img className={style.desc} onClick={()=>{this.handleRoute('/business?tab=2')}} src={require('@/assets/home/sbhyDesc.png')} alt="" />
          </FlyInAnimation>
        </div>
      </div>
      <div className={style.moreWrapper}>
        <img className={style.btn} onClick={()=>{this.handleRoute('/business?tab=3')}} src={require('@/assets/home/more_btn.png')} alt="" />
      </div>
      <div className={style.box} style={{backgroundColor:'#F4F8FC'}}>
        <MyTitle title='老白健康解决方案'></MyTitle>
        <div className={style.selectWrapper}>
          <div className={style.selectContent}>
            <div className={style.maincontent}>
              <div className={style.leftBtnWrapper}>
                {leftBtnList.map(item => <div key={item.id} onClick={() => { this.changeLiftBtn(item.id) }} className={leftBtnId == item.id ? `${style.leftBtn} ${style.activeBtn}` : `${style.leftBtn}`}>{item.name}</div>)}
              </div>
              <div className={style.problemWrapper} style={{ justifyContent: 'space-between' }}>
                {
                  provideData.map((item, index) => {
                    return <FlyInAnimation key={index} show={this.state.solutionActive[index]}><Provide active={this.state.setOpacity} data={item}/></FlyInAnimation>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.box}>
        <MyTitle title='合作承诺'></MyTitle>
        <div className={style.promiseWrapper}>
          <CompanyPromise />
        </div>
      </div>

      <div className={style.box} style={{backgroundColor:'#F4F8FC'}}>
        <MyTitle title='服务案例'></MyTitle>
        <div className={style.promiseWrapper}>
          <div className={style.caseItem}>
            <FlyInAnimation show={this.state.serviceActive[0][0]}>
              <img src={require('@/assets/home/case1.png')} alt="" />
            </FlyInAnimation>
            <FlyInAnimation show={this.state.serviceActive[0][1]}>
              <div className={style.descriptionWrapper}>
                <div className={style.description}>
                  <div className='fs24 mb10'>健康服务获好评</div>
                  <div className='fs16' style={{textAlign:'justify'}}>郭先生在老白健康商保好药&lt;健康服务群&gt;里发布了自己的检查报告，自述有尿频、尿不尽、起夜等问题，且正处于备孕阶段，非常担心会不会有影响。老白健康7*12小时在线为用户解答健康问题的医生当即为郭先生详细解读了报告，并根据专业判断给与了备孕建议和日常疗治方案，帮助郭先生顺利解决了问题。事后，郭先生发来长评表达了感谢，并对老白健康的平台服务表示了高度认可。</div>
                </div>
              </div>
            </FlyInAnimation>
          </div>
          <div className={style.caseItem}>
            <FlyInAnimation show={this.state.serviceActive[1][0]}>
              <img src={require('@/assets/home/case2.png')} alt="" />
            </FlyInAnimation>
            <FlyInAnimation show={this.state.serviceActive[1][1]}>
              <div className={style.descriptionWrapper}>
                <div className={style.description}>
                  <div className='fs24 mb10'>就医绿通解急忧</div>
                  <div className='fs16' style={{textAlign:'justify'}}>李女士是老白健康智慧员福某合作渠道的用户，患有腿部疾病，在一次手术治疗中，医生使用了钢板对腿部进行固定。因迟迟没有排到医院钢板拆除手术非常焦虑，申请了就医绿通服务。老白健康安排专人联系客户进行一对一专人跟进，快速启动服务流程，帮助李女士在10个工作日内完成了手术治疗，快速解决了其就医急忧。术后，李女士恢复良好，向老白健康发来感谢，高度赞扬了工作人员的暖心服务。</div>
                </div>
              </div>
            </FlyInAnimation>
          </div>
        </div>
      </div>
      
      <div className={style.box}>
        <MyTitle title='合作伙伴'></MyTitle>
        <div className={style.imgWrapper}>
          <div className={style.btnWrapper}>
            <div onClick={() => { this.changeTab('insure') }} className={tab === 'insure' ? `${style.checked}` : ''}>保险公司</div>
            <div onClick={() => { this.changeTab('drug') }} className={tab === 'drug' ? `${style.checked}` : ''}>药企</div>
          </div>
          <div className={style.desc}>以下排名不分先后</div>
          <img style={{ marginBottom: 80 }} src={tab === 'insure' ? require('../../assets/home/insureCo.png') : require('../../assets/home/drugCo.png')} alt="" />
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        {/* {!isLogin && <Consult></Consult>} */}
      </div>
      <div className={style.box}>
        <MyTitle title='服务资质'></MyTitle>
        { showCertification && <Certification /> }
      </div>
    </div >
    </div>
  }
}