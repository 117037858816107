import { DownOutlined } from "@ant-design/icons";
export const menu = [
  {
    menu_name: "首页",
    level: 1,
    menu_identify: "home",
    children_menus: null,
  },
  {
    menu_name: "主营业务",
    level: 1,
    menu_identify: "business",
    children_menus: null,
  },
  {
    menu_name: "关于老白",
    level: 1,
    menu_identify: "aboutus",
    children_menus: null,
  },
  {
    menu_name: "新闻动态",
    level: 1,
    menu_identify: "news",
    children_menus: null,
  },
  {
    menu_name: "加入老白",
    level: 1,
    menu_identify: "joinUs",
    children_menus: null,
  },
  {
    menu_name: "药品服务",
    level: 1,
    menu_identify: "medicinalService",
    children_menus: null,
  },
  {
    menu_name: "支持与服务",
    level: 1,
    menu_identify: "service",
    icon: <DownOutlined />,
    children_menus: [
      {
        menu_name: "联系我们",
        menu_identify: "service/contact",
      },
      {
        menu_name: "意见建议",
        menu_identify: "service/suggestions",
      },
    ],
  },
  // {
  //     menu_name: "文档中心",
  //     level: 1,
  //     menu_identify: "/fileCenter",
  //     children_menus: null,
  // },
];

function getMenu(arr, addArr) {
  let selectedArr = localStorage.getItem("userMenu")
    ? JSON.parse(localStorage.getItem("userMenu")).map((item) => {
        return item.menu_identify;
      })
    : [];
  arr.forEach((item) => {
    if (selectedArr.includes(item.menu_identify)) {
      let newObj = { ...item, children_menus: [] };
      addArr.push(newObj);
      if (item.children_menus) {
        getMenu(item.children_menus, newObj.children_menus);
      }
    }
  });
}

export function getMenuFn() {
  let selectedArr = localStorage.getItem("userMenu")
    ? JSON.parse(localStorage.getItem("userMenu")).map((item) => {
        return item.menu_identify;
      })
    : [];
  let returnData = [];
  getMenu(menu, returnData);
  localStorage.setItem("permisson", JSON.stringify(selectedArr));
  selectedArr.map((str) => {
    if (str && str.includes("_role")) {
      localStorage.setItem("loginRole", str.replace("_role", ""));
    }
  });
  console.log("当前登录角色", localStorage.getItem("loginRole"));
  return returnData;
}
