import Config from "@/utils/config";
import Gateway from "@/utils/Gateway";

// 理赔明细列表查询
export async function getClaimList(params) {
  return Gateway.post(`${Config.GatewayPlatform}/policy/page`, params);
}

// 额度明细列表查询
export async function getQuotaList(params) {
  return Gateway.post(`${Config.GatewayPlatform}/policy/recharge`, params);
}

// 理赔资料管理
export async function getPoliceManager(data) {
  return Gateway.post(`${Config.GatewayPlatform}/policy/manager`, data);
}

// 理赔单的单一类型材料下载
export async function downMaterialByType(data) {
  return Gateway.post(
    `${Config.GatewayPlatform}/policy/material/downMaterialByType`,
    data
  );
}
