import React, { useEffect, useState } from "react";
import { useDispatch } from "dva";
import CheckBox from "@/component/CheckBox";
import { Table } from "antd";

const namespace = "serviceManage";
const groupCategoryList = [
  { id: 1, name: "第三方TPA机构" },
  { id: 2, name: "第三方直付机构" },
  { id: 3, name: "定点医疗服务商" },
];

const groupCategoryType = {
  1: "第三方TPA机构",
  2: "第三方直付机构",
  3: "定点医疗服务商",
};

function groupManagement() {
  const dispatch = useDispatch();
  const [searchCond, useSearchCond] = useState({ size: 10, page: 1 });

  const [groupList, useGroupList] = useState([]);
  const [totalNum, useTotalNum] = useState(0);

  useEffect(() => {
    getServiceInsureList(searchCond);
  }, []);

  const getServiceInsureList = (searchParams) => {
    dispatch({
      type: `${namespace}/fetchList`,
      payload: {
        ...searchParams,
      },
    }).then((response) => {
      const { records, total } = response;
      useGroupList(records);
      useTotalNum(total);
    });
  };

  const searchSubmit = (data) => {
    const queryParams = { ...searchCond };
    Object.entries(data).forEach(([k, v]) => {
      queryParams[k] = v;
      searchCond[k] = v;
    });
    searchCond.page = 1;
    useSearchCond({
      ...searchCond,
    });
    getServiceInsureList(queryParams);
  };

  const searchReset = () => {
    const queryParams = { size: 10, page: 1 };
    useSearchCond({
      ...queryParams,
    });
    getServiceInsureList(queryParams);
  };

  const columns = [
    {
      title: "集团编号",
      dataIndex: "id",
      align: "center",
      width: "150px",
    },
    {
      title: "集团名称",
      dataIndex: "group_name",
      align: "center",
      width: "150px",
    },
    {
      title: "类别",
      dataIndex: "group_category",
      align: "center",
      width: "150px",
      render: (text) => <span>{groupCategoryType[text]}</span>,
    },
    {
      title: "联系人",
      dataIndex: "contact",
      align: "center",
      width: "150px",
    },
    {
      title: "联系电话",
      dataIndex: "mobile",
      align: "center",
      width: "150px",
    },
    { title: "邮箱", dataIndex: "email", align: "center", width: "150px" },
  ];

  const pagination = {
    pageSize: searchCond.size,
    total: totalNum,
    showQuickJumper: true,
    current: searchCond.page,
    showTotal: (total, range) => {
      return (
        <span>
          目前显示{range[0]}-{range[1]} 条,共 {total} 条
        </span>
      );
    },
  };

  const searchList = [
    { key: "group_id", type: "INPUT", placeholder: "集团id", width: "180px" },
    {
      key: "group_name",
      type: "INPUT",
      placeholder: "集团名称",
      width: "180px",
    },
    {
      key: "group_category",
      type: "SELECT",
      placeholder: "类别",
      width: "220px",
      options: groupCategoryList,
    },
    // { key: "status", type: "INPUT", placeholder: "状态", width: "220px" },
  ];

  const handleTableChange = (current) => {
    const queryParams = { size: 10, page: current };
    useSearchCond({
      ...queryParams,
    });
    getServiceInsureList(queryParams);
  };

  return (
    <div
      style={{ margin: 24, padding: 24, background: "#fff", minHeight: 360 }}
    >
      <CheckBox
        searchList={searchList}
        searchSubmit={searchSubmit}
        searchReset={searchReset}
        fields={searchCond}
      />
      <Table
        rowKey={(record, index) => record.group_id + index}
        columns={columns}
        pagination={pagination}
        dataSource={groupList}
        onChange={handleTableChange}
        className="mt15"
      />
    </div>
  );
}

export default React.memo(groupManagement);
