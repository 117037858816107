import React, { Component } from 'react'
import { Modal } from 'antd';

export default class NoticeModal extends Component {
    render() {
        const { onCancel } = this.props
        return <Modal
            width={676}
            open={true}
            footer={null}
            onCancel={onCancel}
            style={{
                top: "10px" 
            }}
        >
            <img src={require('../../assets/notice.png')} alt="" />
        </Modal>
    }
}