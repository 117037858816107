import React, { Component } from 'react'
import style from './index.less'
import { Pagination } from 'antd';
import { formatVideoValue } from '@/utils/utils';
import { connect } from 'dva';
import router from 'umi/router'
const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchVideoList`,
        });
    }
    handleTableChange = (current) => {
        const { videoSearchCond } = this.props[namespace];
        videoSearchCond.page = current;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: { videoSearchCond },
        });
        this.getList()
    };
    linkTo = (id) => {
        router.push(`/news/videoDetail?id=${id}`)
    }
    render() {
        const { videoSearchCond, videoTotalNum, videoDataList = [] } = this.props[namespace]
        return <div >
            <div className={style.videoContent}>
                {videoDataList.map(item=><div key={item.id} className={style.videoItem}>
                    <img className={style.play} src={require('@/assets/news/play_btn.png')} onClick={() => { this.linkTo(item.id) }} alt="" />
                    <div className={style.video_param}>
                        <div>{item.duration}</div>
                        <div>
                            <img style={{width:18,height:12}} src={require('@/assets/news/sign.png')} alt="" />
                            <span className='ml5 mr20'>{formatVideoValue(item.views_count)}</span>
                            <img style={{width:14,height:12}} src={require('@/assets/news/like.png')} alt="" />
                            <span className='ml5'>{formatVideoValue(item.likes_count)}</span>
                        </div>
                    </div>
                    <img src={item.cover_url} alt="" onClick={() => { this.linkTo(item.id) }} />
                    <div className={`${style.title} twoline`}>{item.video_title}</div>
                </div>)}
            </div>
            {videoTotalNum === 0 ? <div className={style.emptyWrapper}><img src={require("../../assets/empty.png")} alt="" /><div className={style.desc}>暂无数据</div></div> : <div className={style.paginationWrapper}>
                <Pagination
                    total={videoTotalNum}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条`}
                    onChange={this.handleTableChange}
                    pageSize={videoSearchCond.page_size}
                    current={videoSearchCond.page}
                />
            </div>}
        </div >
    }
}