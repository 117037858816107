import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取药品列表
export async function getDrugList(params) {
      return Gateway.post(`${Config.GatewayAdmin}/website/product/catalog/pageByCondition`, params)
}
// 商品详情
export async function getDrugDetail(product_id ) {
      return Gateway.get(`${Config.GatewayAdmin}/website/product/catalog/detail?product_id=${product_id}`)
}
// 新增建议
export async function addSuggestion(params) {
      return Gateway.post(`${Config.GatewayAdmin}/website/suggestion/add`, params)
}

// 新增咨询
export async function addConsultRecord(params) {
      return Gateway.post(`${Config.GatewayAdmin}/website/consultRecord/add`, params)
}
