import {
  getClaimList,
  getPoliceManager,
  downMaterialByType,
} from "@/service/platform/basicModule/detailManage";

export default {
  namespace: "claimDetail",
  state: {
    searchCond: {
      page_size: 10,
      page: 1,
    },
    dataList: [],
    totalNum: 0,
    policeManagerList: [],
  },
  effects: {
    *fetchList({}, { call, put, select }) {
      const searchCond = yield select((state) => state.claimDetail.searchCond);
      const { records, total } = yield call(getClaimList, searchCond);
      yield put({
        type: "set",
        payload: {
          dataList: records,
          totalNum: Number(total),
        },
      });
    },
    *getPoliceManager({ payload }, { call, put }) {
      const data = yield call(getPoliceManager, payload);
      yield put({
        type: "set",
        payload: {
          policeManagerList: data,
        },
      });
    },
    *downMaterialByType({ payload }, { call }) {
      const data = yield call(downMaterialByType, payload);
      return data;
    },
  },

  reducers: {
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
