import React, { Component } from "react";
import CheckBox from "@/component/CheckBox";
import { Button, Table, Modal } from "antd";
import { connect } from "dva";
import moment from "moment";
import _ from "lodash";
import { materialTypeJson } from "@/common/constant";

const namespace = "claimDetail";
@connect(({ claimDetail }) => ({ claimDetail }))
export default class ClaimDetail extends Component {
  state = {
    claimVisible: false,
  };
  componentDidMount() {
    this.getList();
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  getList = () => {
    this.props.dispatch({
      type: `${namespace}/fetchList`,
    });
  };

  searchSubmit = (data) => {
    const { searchCond } = this.props[namespace];
    Object.entries(data).forEach(([k, v]) => {
      if (k === "time") {
        if (v && v.length > 0) {
          searchCond.start_time = `${moment(v[0]).format(
            "YYYY-MM-DD"
          )} 00:00:00`;
          searchCond.end_time = `${moment(v[1]).format("YYYY-MM-DD")} 23:59:59`;
        } else {
          delete searchCond.start_time;
          delete searchCond.end_time;
        }
      } else if (k == "pl_month") {
        searchCond[k] = v ? moment(v).format("YYYY-MM") : undefined;
      } else {
        searchCond[k] = v;
      }
    });
    searchCond.page = 1;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: searchCond,
    });
    this.getList();
  };

  searchReset = () => {
    const searchCond = { page_size: 10, page: 1 };
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.getList();
  };

  handleTableChange = (pagination) => {
    const { searchCond } = this.props[namespace];
    searchCond.page_size = pagination.pageSize;
    searchCond.page = pagination.current;
    this.props.dispatch({
      type: `${namespace}/set`,
    });
    this.getList();
  };

  handleCancle = () => {
    this.setState({
      claimVisible: false,
    });
  };

  handleClaim = (record) => {
    this.props.dispatch({
      type: `${namespace}/getPoliceManager`,
      payload: { policy_id: record.pl_id },
    });

    this.setState({
      claimVisible: true,
    });
  };

  handleUpload = (record) => {
    this.props
      .dispatch({
        type: `${namespace}/downMaterialByType`,
        payload: {
          policy_ids: [record.policy_id],
          material_type: record.material_type,
        },
      })
      .then((response) => {
        let link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("href", response);
        link.setAttribute("download", "订单列表");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  render() {
    const { searchCond, totalNum, dataList, policeManagerList } = this.props[
      namespace
    ];
    const { claimVisible } = this.state;
    const claimState = {
      0: "0/4 未处理",
      3: "1/4 已开票",
      4: "2/4 资料已发送",
      5: "3/4 理赔中",
      6: "4/4 已全部到款",
    };

    const searchList = [
      { key: "pl_id", type: "INPUT", placeholder: "理赔单号", width: "220px" },
      {
        key: "policy_no",
        type: "INPUT",
        placeholder: "关联保单号",
        width: "220px",
      },
      { key: "pl_month", type: "MONTH", placeholder: "理赔月", width: "160px" },
      // {
      //   key: "time",
      //   type: "RANGEDATE",
      //   width: "280px",
      //   placeholder: ["开始时间", "结束时间"],
      //   rangeDateLabel: { firstTime: "start_time", secondTime: "end_time" },
      // },
    ];

    const columns = [
      { title: "理赔单号", dataIndex: "pl_id", align: "center" },
      { title: "关联保单号", dataIndex: "policy_no", align: "center" },
      { title: "保单名称", dataIndex: "cr_name", align: "center" },
      { title: "保险公司", dataIndex: "agt_name", align: "center" },
      { title: "商家名称", dataIndex: "tc_name", align: "center" },
      { title: "理赔月份", dataIndex: "pl_month", align: "center", width: 100 },
      { title: "理赔消费笔数", dataIndex: "pl_count", align: "center" },
      { title: "应理赔金额", dataIndex: "pl_sum", align: "center" },
      {
        title: "实际理赔金额",
        dataIndex: "real_claim_amount",
        align: "center",
      },
      {
        title: "理赔进度",
        dataIndex: "pl_status",
        width: 130,
        align: "center",
        render: (text) => <span>{claimState[text]}</span>,
      },
      {
        title: "操作",
        dataIndex: "",
        align: "center",
        fixed: "right",
        width: "100px",
        render: (_text, record) => (
          <>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => this.handleClaim(record)}
            >
              理赔资料管理
            </Button>
          </>
        ),
      },
    ];

    const claimColumns = [
      {
        title: "资料名称",
        dataIndex: "material_type",
        align: "center",
        render: (text) => <span>{materialTypeJson[text]}</span>,
      },
      {
        title: "生成状态",
        dataIndex: "generate_state",
        align: "center",
        render: () => <span>已生成</span>,
      },
      {
        title: "备注",
        dataIndex: "remark",
        align: "center",
        render: (text) => <span>{text || "-"}</span>,
      },
      {
        title: "操作",
        align: "center",
        render: (_text, record) => (
          <>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => this.handleUpload(record)}
            >
              下载
            </Button>
          </>
        ),
      },
    ];

    const pagination = {
      pageSize: searchCond.page_size,
      total: totalNum,
      showQuickJumper: true,
      current: searchCond.page,
      showTotal: (total, range) => {
        return (
          <span>
            目前显示{range[0]}-{range[1]} 条,共 {total} 条
          </span>
        );
      },
    };

    return (
      <div
        style={{ margin: 24, padding: 24, background: "#fff", minHeight: 360 }}
      >
        <Modal
          visible={claimVisible}
          maskClosable={false}
          width="60%"
          title="理赔资料管理"
          onCancel={this.handleCancle}
          footer={null}
        >
          <Table
            rowKey={(_record, index) => index}
            columns={claimColumns}
            dataSource={policeManagerList}
            pagination={false}
            locale={{
              emptyText: "暂无理赔资料",
            }}
          />
        </Modal>
        <CheckBox
          searchList={searchList}
          searchSubmit={this.searchSubmit}
          fields={searchCond}
          searchReset={this.searchReset}
        />
        <Table
          rowKey="pl_id"
          columns={columns}
          pagination={pagination}
          dataSource={dataList}
          onChange={this.handleTableChange}
          className="mt15"
          scroll={{ x: true }}
        />
      </div>
    );
  }
}
