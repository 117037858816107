import React, { Component } from 'react'
import style from './index.less'
import { getUrlParam } from '@/utils/utils';

export default class Qualification extends Component {

    domRender = () => {
        const type = getUrlParam('type')
        let el = null
        switch (type) {
            case '1':
                el = (<img src={require("../../assets/qualification/1.jpg")} alt="" />)
                break;
            case '2':
                el = (<div>
                    <img src={require("../../assets/qualification/2_1.png")} alt="" />
                    <img src={require("../../assets/qualification/2_2.png")} alt="" />
                </div>)
                break;
            case '3':
                el = (<img src={require("../../assets/qualification/3.png")} alt="" />)
                break;
            case '4':
                el = (<img src={require("../../assets/qualification/4.png")} alt="" />)
                break;
            case '5':
                el = (<img src={require("../../assets/qualification/5.png")} alt="" />)
                break;
            default:
                break;
        }
        return el
    }
    render() {

        return <div className={style.wrapper}>
            <div className={style.container}>
                {this.domRender()}
            </div>
        </div >
    }
}