import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Select, message, Popover } from 'antd'
import { connect } from 'dva';
import style from './Login.less';
import Config from '@/utils/config';
import md5 from 'md5';
import _, { times } from 'lodash'
import { getUrlParam, setCookie, verifyPassWord } from '@/utils/utils';
import { LeftOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { checkImgCaptcha, getImageCaptcha, sendCode, signIn, getUserinfo,getUnitList } from '@/service/login';
import router from 'umi/router';
import qs from 'query-string'
const namespace = 'global';

const roleList = {
	'1': 'SOCIAL_ORGANIZATION',
	'2': 'INSURANCE_INDIVIDUAL',
	'3': 'INSURANCE_GROUP',
	'4': 'COMPANY_HR',
	'5': 'PHARMACY',
}
const clientTypeList = {
	0: 'agent',//保司
	1: 'unit', //投保单位
	2: 'point',
  3: 'direct',
  4: 'tpa',
}
@connect(({ global, loading }) => ({
	global,
}))

export default class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openImgCaptcha: false,
			captchaImg: '',
			captchaToken: '',
			countDown: 0,
		}
		this.formRef = React.createRef()
	}
	onFinish = async (values) => {
		if (!values['username']) {
			message.warning('请输入账号!')
			return
		}
		if (!values['password']) {
			message.warning('请输入密码!')
			return
		}
		const params = {
			username: values['username'],
			password: md5(values['password']),
			verifyCode: values['verifyCode']
		}
		const res = await signIn(params)
		if (res) {
			message.success('登录成功!')
			localStorage.setItem('userName', values['username']);
			localStorage.setItem('token', res.access_token);
			localStorage.setItem('refreshToken', res.refresh_token);
			localStorage.setItem('isLogin', true);
			const returnUrl = qs.parse(location.search).returnUrl
			const response = await getUserinfo()
			if (response) {
				const { roles } = response
				localStorage.setItem('roleType', roleList[roles] || 'SOCIAL_ORGANIZATION')
				localStorage.setItem('realName', response.realName)
				localStorage.setItem('organName', response.organName)
				localStorage.setItem('roleName',response.roleList[0]?.roleName || '')
				localStorage.setItem('roleId', response.roleList[0]?.roleId || '')
				const bindClientInfo = response.enterpriseList[0]
				if (bindClientInfo) {
					localStorage.setItem('bindClientType', clientTypeList[bindClientInfo.enterpriseType])
					localStorage.setItem('bindClientId', bindClientInfo.enterpriseId)
					if (bindClientInfo.enterpriseType == 0) {
						const paramData = {
							agent_id:bindClientInfo.enterpriseId,
							page:1,
							page_size:100
						}
						getUnitList(paramData).then(resp=>{
							const relatedUnitList = resp.map(item=>({unit_id:item.id,unit_name:item.name}))
							localStorage.setItem('relatedUnitList',JSON.stringify(relatedUnitList) || [])
						})
					}
				}
				if (returnUrl) {
					window.location.replace(returnUrl)
				} else {
					router.push('/home')
				}
				const children_menus = response.menuList.map(item=>{
					return {
						id:item.menuId,
						menu_name:item.menuName,
						menu_identify:item.menuUrl,
						menu_icon:null,
						parent_id:'0',
						children_menus:null,
					}
				})
				localStorage.setItem('userMenu', JSON.stringify(children_menus));
			}
			
		}
	}
	imgCaptcha = () => {
		const { captchaImg } = this.state;
		return <div>
			<div>
				<img
					style={{ width: '100%', marginBottom: '7px' }}
					src={captchaImg}
					alt="点击刷新"
					onClick={() => this.changeImgCaptchaPopOver(true)}
				/>
			</div>
			<Form.Item
				label=''
				name='imageCaptcha'
			>
				<Row>
					<Col span={18}><Input placeholder='请输入图片上的验证码' onPressEnter={() => this.checkImgCaptcha()} /></Col>
					<Col span={4}><Button type='primary' onClick={() => this.checkImgCaptcha()}>确定</Button></Col>
				</Row>
			</Form.Item>
			<div style={{ textAlign: 'center', fontSize: '20px', margin: '-17px 0 5px 0' }} onClick={() => { this.setState({ openImgCaptcha: false, captchaImg: '', captchaToken: '' }) }}><CloseCircleOutlined /></div>
		</div>
	}
	getCaptchaFormItem = () => {
		const { openImgCaptcha, countDown } = this.state
		return <Form.Item
			name="verifyCode"
			rules={[{ required: true, message: '请输入短信验证码!' }]}
		>
			<Row gutter={8}>

				<Col span={16}>
					<Input placeholder='请输入短信验证码' />
				</Col>
				<Col span={8}>

					<Popover
						content={this.imgCaptcha()}
						title={null}
						trigger="click"
						open={openImgCaptcha}
					>
						<Button type='primary' disabled={!!countDown} ghost onClick={() => { this.changeImgCaptchaPopOver(true) }}>{countDown ? countDown : '获取验证码'}</Button>
					</Popover>
				</Col>
			</Row>
		</Form.Item>
	}
	changeImgCaptchaPopOver = async (show) => {
		if (!show) {
			this.setState({ openImgCaptcha: false, captchaImg: '', captchaToken: '' })
			return
		}
		const res = await getImageCaptcha()
		if (res) {
			this.setState({ openImgCaptcha: true, captchaImg: res.captchaImg, captchaToken: res.captchaToken })
		}
	}
	checkImgCaptcha = async () => {
		const { captchaToken } = this.state;
		const values = this.formRef.current.getFieldsValue(['imageCaptcha', 'username'])
		if (!values['imageCaptcha']) {
			message.warning('请输入图形验证码!')
			return
		}

		if (!values['username']) {
			message.warning('请输入用户名称!')
			return
		}
		const params = { captchaToken, captcha_code: values['imageCaptcha'] }
		const res = await checkImgCaptcha(params)
		if (res.rc == 0) {
			let params = {
				captchaToken: res.data,
				username: values['username'] || ''
			}
			//发送短信验证码
			const emsRes = await sendCode(params)
			if (emsRes.rc == 0) {
				message.success('发送成功！')
				this.changeImgCaptchaPopOver(false)
				this.runGetCaptchaCountDown()
			} else {
				message.error(emsRes.msg)
			}
		} else {
			message.error(res.msg)
			this.formRef.current.setFields([{
				name: 'imageCaptcha',
				errors: ['验证码输入错误!']
			}])
		}
	}
	runGetCaptchaCountDown = () => {
		let { countDown } = this.state;
		countDown = 60
		this.interval = setInterval(() => {
			--countDown;
			this.setState({ countDown });
			if (countDown === 0) {
				clearInterval(this.interval);
			}
		}, 1000);
	};
	goToPdfReview = (e) => {
		e.preventDefault();
		// this.props.showPolicy('https://yyb-img.laobaiyy.com/76683468f84e430c9454649ea60a669e-1667441969.pdf')
	}
	render() {
		return <div className={style.loginFormContainer} >
			<div className={style.loginTitle}>
				账号登录
			</div>
			<Form
				labelCol={{ span: 2 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				onFinish={this.onFinish}
				colon={false}
				ref={this.formRef}
			>
				<div className={style.signInContainer}>
					<Form.Item
						name="username"
						rules={[{ required: true, message: '请输入用户名!' }]}
					>
						<Input placeholder='请输入用户名称' />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: '请输入密码!' }]}
					>
						<Input.Password placeholder='请输入密码' />
					</Form.Item>
				</div>
				{this.getCaptchaFormItem()}
				{/* <Form.Item name="protocol" valuePropName="checked" style={{ fontSize: 12 }}>
					登录视为您已阅读并同意<span className={style.underLineBlue} onClick={(e) => { this.goToPdfReview(e) }}>{` 海南老白 `}</span>服务条款和<span className={style.underLineBlue}>{` 隐私协议 `}</span>
				</Form.Item> */}
				<Form.Item>
					<Button type="primary" htmlType="submit" style={{ width: '100%' }}>
						登录
					</Button>
					<div style={{color:'#0099FF'}} className='mt10 ta-c'> <span className='pointer' onClick={() => { router.push('/register') }}>免费注册</span> </div>
				</Form.Item>
			</Form>
		</div >

	}
}
