import React, { Component } from 'react'
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import MyTitle from '@/component/title/title'
import style from './sbhy.less'
import FlyInAnimation from '../../../component/flyInAnimation'
TweenOne.plugins.push(Children);
const data1 = [
    { name: '上线渠道', value: 800 },
    { name: '惠民保渠道', value: 259 },
    { name: '特殊群体', value: 16500 },
    { name: '激活用户', value: 70 },
    { name: '转化率', value: 22 },
]
const data2 = [
    require('@/assets/business/sbhy/7_1.png'),
    require('@/assets/business/sbhy/7_2.png'),
    require('@/assets/business/sbhy/7_3.png'),
    require('@/assets/business/sbhy/7_4.png'),
    require('@/assets/business/sbhy/7_5.png'),
    require('@/assets/business/sbhy/7_6.png'),
    require('@/assets/business/sbhy/7_7.png'),
    require('@/assets/business/sbhy/7_8.png'),
]
export default class ZhyfTab extends Component {
    state = {
        active1: [false, false],
        active2: false,
        active3: false,
        active4: false,
        animation: [],
        active5: [false, false, false, false, false, false, false, false],
        active5TimerDisable: false,
        active6:[false,false]
    }
    componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
        const { active1 } = this.state
        // 服务案例动画
        active1[0] = true
        this.setState({ active1 })
        setTimeout(() => {
            active1[1] = true
            this.setState({ active1 })
        }, 1000);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        this.setState({ positionY: pos.top })
        this.activeAnimation(pos.top)
    }
    activeAnimation = (positionY) => {
        const { active5TimerDisable, active5,active6 } = this.state
        if (positionY < -700) {
            this.setState({ active2: true })
        }
        if (positionY < -1400) {
            const animationList = data1.map(item => {
                return {
                    Children: {
                        value: item.value,
                        floatLength: 0,
                    },
                    duration: 2000,
                }
            })
            this.setState({
                active3: true,
                animation: animationList
            })
        }
        if (positionY < -2100) {
            this.setState({ active4: true })
        }
        if (positionY < -2100) {
            this.setState({ active4: true })
        }
        if (positionY < -2400 && !active5TimerDisable) {
            let index = 0
            this.setState({ active5TimerDisable: true })
            const timer = setInterval(() => {
                active5[index] = true
                index++
                this.setState({ active5 })
            }, 600);
            setTimeout(() => {
                clearInterval(timer)
            }, 4800);
        }
        if (positionY < -2800) {
            active6[0] = true
            this.setState({ active6 })
            setTimeout(() => {
                active6[1] = true
                this.setState({ active6 })
            }, 600);
        }
    }
    render() {
        const { active1, active2, active3, active4, animation, active5,active6 } = this.state
        return (
            <div className={style.pageWrapper}>
                <div className={style.topBanner}>
                    <img src={require('@/assets/business/sbhy/top.png')} alt="" />
                </div>
                <div className={style.boxWrapper}>
                    <div className={style.boxContent}>
                        <div className='fs24 fwbold'>0投入、0风险，更好的客户体验</div>
                        <div className={style.line} />
                        <div className='flexbet mb40'>
                            <div className={style.fontStyle} style={{ width: 520 }}>商保好药是老白健康面向城乡居民、城镇职工推出的的数字化医药福利管理解决方案，包括“家康保”等针对不同场景设计的创新保险解决方案，为保险公司的普通C端客户提供折扣购药增值服务。其特点是通过优化医药流通中间环节利润结构，实现没有中间商赚差价，能够直接为C端用户降低药品成本、提高医疗保障水平，并帮助保险公司实现底层赋能，创造交易机会、提高二次开发可能性。</div>
                            <FlyInAnimation show={active1[0]}>
                                <img className={style.rightImg} src={require('@/assets/business/sbhy/img1.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                    <FlyInAnimation show={active1[1]}>
                        <div className={style.banner}>
                            <img src={require('@/assets/business/sbhy/img3.png')} alt="" />
                        </div>
                    </FlyInAnimation>
                    <img className={style.bgImg} src={require('@/assets/business/zhyf/img2.png')} alt="" />
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务内容'></MyTitle>
                    <div className={style.content}>
                        <FlyInAnimation show={active2}>
                            <img src={require('@/assets/business/sbhy/img4.png')} alt="" />
                        </FlyInAnimation>
                    </div>
                </div>
                <div className={style.contentBox} style={{ backgroundColor: '#F5F5F5' }}>
                    <MyTitle title='服务数据'></MyTitle>
                    <div className={style.content} style={{ backgroundColor: '#fff', padding: '80px 100px 30px 100px' }}>
                        <div className='flexbet'>
                            {data1.map((item, index) => <div className={style.dataItem} key={index}>
                                <div className='fs30' style={{ color: 'rgb(102,156,219)' }}>
                                    <TweenOne animation={animation[index]}
                                        style={{ display: 'inline' }}
                                    />{index == 3 ? '万' : index == 4 ? '%' : ''}+</div>
                                <div className='fs14 color666'>{item.name}</div>
                            </div>)}
                        </div>
                        <div className={style.dataDesc}>* 总覆盖数据包括分支机构</div>
                        <div className='flexcenter'>
                            <FlyInAnimation show={active3}>
                                <img className={style.img1} src={require('@/assets/business/sbhy/img5.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务案例'></MyTitle>
                    <div className={style.content}>
                        <div className='color333 mb10 ta-c'>商保好药帮助保险公司完成底层赋能，创造交易机会，提高二次开发可能性</div>
                        <div className='flexcenter'>
                            <FlyInAnimation show={active4}>
                                <img className={style.img1} src={require('@/assets/business/sbhy/img6.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                        <div className='color333 mb40 ta-c'>商保好药为惠民保用户提供常见病、慢性病的互联网门诊用药保障</div>
                        <div className={style.caseList}>
                            {data2.map((item, index) =>
                                <FlyInAnimation key={index} show={active5[index]}>
                                    <img className={style.img} style={{ zIndex: index + 100, left: index * 135 }} src={item} alt="" />
                                </FlyInAnimation>)}
                        </div>
                        <div className='color333 mt40 mb40 ta-c'>商保好药关爱特殊群体，助力好药护民</div>
                        <div className='flexbet'>
                            <FlyInAnimation show={active6[0]}>
                                <img className={style.img1} src={require('@/assets/business/sbhy/8_1.png')} alt="" />
                            </FlyInAnimation>
                            <FlyInAnimation show={active6[1]}>
                                <img className={style.img1} src={require('@/assets/business/sbhy/8_2.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}