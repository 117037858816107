import BasicLayout from './BasicLayout'
import PlatformLayout from './PlatformLayout'
import UserLayout from './UserLayout'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import '../common/common.less';
moment.locale('zh-cn');

export default function (props) {
    // let loadingElement = document.getElementById('root')
    // if (loadingElement) {
    //     document.getElementById('loading').style.display = 'none'
    // }
    const { location: { pathname }, location } = props;

    if (pathname === '/user/login') {
        return <UserLayout>
            {props.children}
        </UserLayout>
    } else if (pathname.indexOf('/platform') > -1) { //工作台
        let pName = pathname.substring(1, pathname.length)
        return <ConfigProvider locale={zhCN}>
            <PlatformLayout pathname={pName}>
                {props.children}
            </PlatformLayout>
        </ConfigProvider >
    } else {
        // const permissonArr = JSON.parse(localStorage.getItem('permisson'))
        let pName = pathname.substring(1, pathname.length)
        // if (permissonArr &&  !permissonArr.includes(pName)) {
        //     return <ConfigProvider locale={zhCN}>
        //         <BasicLayout>
        //             <Error />
        //         </BasicLayout>
        //     </ConfigProvider >
        // }
        return <ConfigProvider locale={zhCN}>
            <BasicLayout pathname={pName}>
                {props.children}
            </BasicLayout>
        </ConfigProvider >
    }
}