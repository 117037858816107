import { PRODUCT_CENTER_DETAIL, ROLE_TYEP } from "@/common/constant";
import React, { Component } from "react";
import router from "umi/router";
import style from './companyPromise.less'

const promiseData = [
    {
        icon: require('@/assets/home/promise1.png'),
        title:'药品目录承诺',
        desc: ['按照“临床必需、安全有效、平价保供、改善健康”四大原则选品，覆盖中西药品、营养补充剂、中药饮片和医疗器械四大用药类别']
	},
    {
        icon: require('@/assets/home/promise3.png'),
        title:'物流时效承诺',
        desc: ['客户药品订单物流采用顺丰/京东服务，当日17点前订单承诺当日24点前发出，未发出订单可申请赔偿']
	},
    {
        icon: require('@/assets/home/promise2.png'),
        title:'运营服务承诺',
        desc: ['在宣传销售启动前1-2周，需进行产品宣导与培训通关，双方联合营销推广，保持长期持续运营，客户问题承诺1个工作日内及时反馈']
	},
]
export default class CompanyPromise extends Component {

	render () {
		return (
			<div className={style.promiseContainer}>
                {promiseData.map((item,index)=>{
                    return <div key={index} className={style.boxContianer}>
                            <img src={item.icon} alt="" />
                            <div>{item.title}</div>
                            <div className={style.descBox}>
                                {item.desc?.map((value,i)=>{
                                    return <div key={i}>{value}</div>
                                })}
                            </div>
                    </div>
                })}
			</div>
		);
	}
}
