import React, { Component } from 'react';
import { Row, Col, Modal, Input, InputNumber, message, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import { connect } from 'dva';
const FormItem = Form.Item;
const namespace = 'insuranceStaff'
@Form.create()
@connect(({ insuranceStaff }) => ({
    insuranceStaff
}))
export default class AddRuleModal extends Component {
    componentDidMount() {
        const { form, currentData } = this.props
        if (currentData.id) {
            form.setFieldsValue({
                user_name: currentData.user_name,
                mobile_no: currentData.mobile_no,
                real_name: currentData.real_name,
                email: currentData.email,
                sex: currentData.sex + '' || '2',
            })
        }
    }

    handleSubmit = () => {
        const { form, currentData, onCancel } = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const submitData = values
            let api = `${namespace}/fetchAddStaff`
            if (currentData.id) {
                api = `${namespace}/fetchEditStaff`
                submitData.id = currentData.id
            }
            this.props.dispatch({
                type: api,
                payload: submitData
            }).then(res => {
                if (res) {
                    this.props.dispatch({
                        type: `${namespace}/fetchList`
                    })
                    message.success('操作成功')
                    onCancel()
                }
            })
        })
    }

    render() {
        const { onCancel, currentData, form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        const sexList = [
            { id: '0', name: '男' },
            { id: '1', name: '女' },
            { id: '2', name: '未知' },
        ]
        return (
            <Modal
                open
                title={currentData.id ? '编辑' : '新增'}
                onCancel={onCancel}
                width="500px"
                onOk={this.handleSubmit}
            >
                <Form>
                    <Row gutter={10}>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label='用户名'>
                                {getFieldDecorator('user_name', {
                                    rules: [
                                        { required: true, message: '请输入用户名!' },
                                    ],
                                })(
                                    <Input type='text' disabled={currentData.id ? true : false} maxLength={30} placeholder="请输入用户名（用户名用于登录）" style={{ width: '350px' }} />
                                )}
                            </FormItem>
                        </Col>
                        {currentData.id ? null : <Col span={24}>
                            <FormItem {...formItemLayout} label='密码'>
                                {getFieldDecorator('password', {
                                    rules: [
                                        { required: true, message: '请输入密码!' },
                                    ],
                                })(
                                    <Input type='text' maxLength={30} placeholder="请输入密码" style={{ width: '350px' }} />
                                )}
                            </FormItem>
                        </Col>}
                        <Col span={24}>
                            <FormItem {...formItemLayout} label='手机号'>
                                {getFieldDecorator('mobile_no', {
                                    rules: [
                                        { required: true, message: '请输入手机号!' },
                                    ],
                                })(
                                    <Input type='text' disabled={currentData.id ? true : false} placeholder="请输入手机号" style={{ width: '350px' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label='真实姓名'>
                                {getFieldDecorator('real_name', {
                                    rules: [
                                        { required: true, message: '请输入真实姓名!' },
                                    ],
                                })(
                                    <Input type='text' placeholder="请输入真实姓名" style={{ width: '350px' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label='性别'>
                                {getFieldDecorator('sex')(
                                    <Select style={{ width: '350px' }} placeholder="请选择性别" >
                                        {
                                            sexList.map((item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label='邮箱'>
                                {getFieldDecorator('email')(
                                    <Input type='text' placeholder="请输入邮箱" style={{ width: '350px' }} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}