import React, { Component } from "react";
import qs from "query-string";
import style from "./index.less";
import Consult from "../home/consult";

const data = [
  {
    name: "河北山西分公司",
    address: [
      "河北省石家庄市桥西区裕华西路66号海悦天地",
      "购物广场A座1单元1911号房间",
    ],
    img: require("@/assets/contact/1.png"),
    phone: "19930417119",
  },
  {
    name: "山东河南分公司",
    address: [
      "山东省济南市高新区经十路7000号汉峪金谷",
      "A6-5健康大厦13层1313房间",
    ],
    img: require("@/assets/contact/2.png"),
    phone: "18763460991",
  },
  {
    name: "江西分公司",
    address: ["江西省南昌市红谷滩区世贸路699号联泰七号", "广场A座605室"],
    img: require("@/assets/contact/3.png"),
    phone: "15879158620",
  },
  {
    name: "江苏分公司",
    address: ["江苏省南京市秦淮区新世纪广场A809"],
    img: require("@/assets/contact/4.png"),
    phone: "17751012368",
  },
  {
    name: "内蒙古分公司",
    address: ["呼和浩特市新城区哲里木路天育星园8号楼13层"],
    img: require("@/assets/contact/5.png"),
    phone: "15661013775",
  },
  {
    name: "福建分公司",
    address: [
      "福建省福州市台江区洋中街道洋中路299号",
      "融信双杭城mini墅 2号楼2427",
    ],
    img: require("@/assets/contact/6.png"),
    phone: "18396129086",
  },
  {
    name: "广东分公司",
    address: ["广州市天河区华强路9号1702A房"],
    img: require("@/assets/contact/7.png"),
    phone: "18868118660",
  },
  {
    name: "新疆分公司",
    address: ["新疆乌鲁木齐市天山区光明路19号", "金碧华府A座15楼1501室"],
    img: require("@/assets/contact/8.png"),
    phone: "18690281332",
  },
  {
    name: "辽宁吉林分公司",
    address: [
      "辽宁省沈阳市沈河区惠工街10号卓越大厦3层6室",
      "吉林省长春市南关区长春国际金融中心C座1217号房",
    ],
    img: require("@/assets/contact/9.png"),
    phone: "13269772577/13804329781",
  },
  {
    name: "浙江上海分公司",
    address: ["上海市浦东新区浦东南路1088号中融大厦1611室"],
    img: require("@/assets/contact/10.png"),
    phone: "15088805298",
  },
  {
    name: "贵州云南分公司",
    address: [
      "贵州省贵阳市观山湖区数博大道恒大中心A座1704",
      "云南省昆明市盘龙区东华街道益新德隆小区6幢3104室",
    ],
    img: require("@/assets/contact/11.png"),
    phone: "18869501791/18151534411",
  },
  {
    name: "北京分公司",
    address: ["北京市丰台区西三环南路14号院1号楼", "18层1805-06号"],
    img: require("@/assets/contact/12.png"),
    phone: "13404352777",
  },
  {
    name: "陕西分公司",
    address: ["陕西省雁塔区科技路紫薇龙腾新世界2幢1单元2001室"],
    img: require("@/assets/contact/13.png"),
    phone: "13152135659",
  },
  {
    name: "广西分公司",
    address: ["广西省南宁市平乐大道五象世贸中心2#1007房"],
    img: require("@/assets/contact/14.png"),
    phone: "15651787705",
  },
  {
    name: "甘肃分公司",
    address: ["甘肃省兰州市城关区皋兰路29号扶贫大厦501室"],
    img: require("@/assets/contact/15.png"),
    phone: "15294121826",
  },
  {
    name: "湖北安徽分公司",
    address: [
      "武汉市江汉区唐家墩路7.9.11号武汉菱角湖万达广场A栋",
      "A3单元13A层6室",
    ],
    img: require("@/assets/contact/16.png"),
    phone: "19813765270",
  },
  {
    name: "湖南分公司",
    address: ["湖南省长沙市天心区芙蓉同发1528"],
    img: require("@/assets/contact/17.png"),
    phone: "17320262575",
  },
];
export default class contact extends Component {
  componentDidMount() {
    const scroll = qs.parse(location.search).scroll || "";
    if (scroll == "bottom") {
      this.scrollToAnchor("consult");
    }
  }
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView(true);
      }
    }
  };
  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.banner} style={{ height: "auto", width: "100%" }}>
          <div className={style.desc}>
            <div className={style.title}>联系我们</div>
            <div className={style.text}>
              海南老白开放平台提供专业的售前售后咨询
            </div>
          </div>
          <img src={require("@/assets/contact/top.png")} alt="" />
        </div>
        <div className={style.contentWrapper}>
          <div className={style.content}>
            <img src={require("@/assets/contact/title.png")} alt="" />
            <div className={style.divider} />
            <div className="fs20 mb15 mt40">海南总部</div>
            <div>地址：海南省海口市龙华区长城大厦1704室-18689549218</div>
            <div className="fs20 mb15 mt40">杭州总部</div>
            <div>
              地址：浙江省杭州市拱墅区杭州新天地商务中心12幢1206室-18072878657
            </div>
            <div className="fs20 mb15 mt40">重庆总部/川渝分公司</div>
            <div>
              地址：重庆市民权路英利国际4401-17321922013/成都市高新区天府大道北段1700号环球中心8-1-1815号
            </div>
            <div className={style.list}>
              {data.map((item, index) => (
                <div key={index} className={style.item}>
                  <img src={item.img} alt="" />
                  <div className="mt20 mb10">{item.name}</div>
                  {item.address?.map((value, i) => <div key={i}>{value}</div>)}
                  <div className="mt10">{item.phone}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.box} id="consult">
          <Consult></Consult>
        </div>
      </div>
    );
  }
}
