import React, { Component } from 'react'
import router from 'umi/router'
import { Divider, Row, Col,Radio,Input,Button, message } from 'antd';
import CryptoJS from 'crypto-js'
import { addSuggestion, } from '@/service/drug';
import { getUserMobile } from '@/service/login';
import style from './index.less'
const { TextArea } = Input;
const key = 'S/V9mXqANrI8MToURTMGYqTfcfwiKS4+x68f6YqEBAg='
export default class Service extends Component {
  state = {
    type:'',
    title:'',
    detail:'',
    mobile:'',
    email:'',
    loading:false,
    showInput:false,

  }
  componentDidMount() {
    // getUserMobile().then(res=>{
    //   if (res) {
    //     const data = res.mobile_no
    //     const base = decodeURIComponent(atob(data))
    //     console.log(base);
    //     // console.log(this.decrypt(base));
    //   }
    // })
  }

  decrypt = (text) => {
    let decrypted = CryptoJS.AES.decrypt(text,CryptoJS.enc.Utf8.parse(key),{
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  onChange = (props,value) => {
    this.setState({
      [props]:value
    })
  }
  linkTo = (value) => {
    router.push(value)
  }
  submit = async() => {
    const isLogin = localStorage.getItem('isLogin') || false
    if (!isLogin) {
      message.error({
        content: '请先登录',
        style: {
          marginTop: '20vh',
        },
      });
      return
    }
    const { type,title,detail,mobile,email } = this.state
    if (!type) {
      message.error({
        content: '请选择建议类型',
        style: {
          marginTop: '20vh',
        },
      });
      return
    }
    if (!title) {
      message.error({
        content: '请输入建议标题',
        style: {
          marginTop: '20vh',
        },
      });
      return
    }
    if (!detail) {
      message.error({
        content: '请输入建议详情',
        style: {
          marginTop: '20vh',
        },
      });
      return
    }
    if (!mobile) {
      message.error({
        content: '请输入手机号码',
        style: {
          marginTop: '20vh',
        },
      });
      return
    }
    const params = {
      mobile_no:mobile,
      suggestion_type:type,
      title:title,
      content:detail,
      email:email,
    }
    this.setState({loading:true})
    try {
      const res = await addSuggestion(params)
      if (res) {
        this.setState({loading:false,showInput:true})
        this.resetField()
      }else{
        this.setState({loading:false})
      }
    } catch (error) {
      this.setState({loading:false})
    }
  }
  resetField = () => {
    this.setState({
      type:'',
      title:'',
      detail:'',
      mobile:'',
      email:'',
    })
  }
 
  render() {
    const { type,title,detail,mobile,email,loading,showInput } = this.state
   
    return <div className={style.wrapper} >
    <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
      <div className={style.desc}>
        <div className={style.title}>意见建议</div>
      </div>
      <img src={require("../../../assets/suggestion/banner.png")} alt="" />
    </div>
    {!showInput?<div className={style.content}>
      <div className={style.title}>意见建议</div>
      <div className={style.desc}>提交此页面，将默认您将填写的建议等内容授权给我们。为了能够响应您的建议，我们可能会通过电话方式联系您</div>
      <Divider dashed />
      <Row className={style.inputArea}>
        <Col span={3}><span style={{color:'red'}}>*</span>建议类型</Col>
        <Col span={21}>
          <Radio.Group onChange={(e)=>{this.onChange('type',e.target.value)}} value={type}>
            <Radio value={1}>功能建议</Radio>
            <Radio value={2}>服务建议</Radio>
            <Radio value={3}>我要投诉</Radio>
            <Radio value={4}>其他建议</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row className={style.inputArea}>
        <Col span={3}><span style={{color:'red'}}>*</span>标题</Col>
        <Col span={21}>
          <Input placeholder="请输入建议标题" maxLength={20}  onChange={(e)=>{this.onChange('title',e.target.value)}} value={title}/>
        </Col>
      </Row>
      <Row className={style.inputArea}>
        <Col span={3}><span style={{color:'red'}}>*</span>建议详情</Col>
        <Col span={21}>
          <TextArea autoSize={{minRows: 3}}  maxLength={300} showCount placeholder="请详细描述建议或反馈的内容如：我建议**产品增加**功能，我发现**产品存在**问题，或者我认为**服务存在**问题等"  onChange={(e)=>{this.onChange('detail',e.target.value)}} value={detail}/>
        </Col>
      </Row>
      <Row className={style.inputArea}>
        <Col span={3}><span style={{color:'red'}}>*</span> 联系方式</Col>
        <Col span={10}>
          <Input placeholder="手机号码"  onChange={(e)=>{this.onChange('mobile',e.target.value)}} value={mobile}/>
        </Col>
        <Col span={1}>
          <div></div>
        </Col>
        <Col span={10}>
          <Input placeholder="邮箱"  onChange={(e)=>{this.onChange('email',e.target.value)}} value={email}/>
        </Col>
      </Row>
      <div className='flexend'>
        <Button  type="primary" loading={loading} onClick={this.submit}>提交</Button>
      </div>
    </div>: <div  className={style.content}>
      <div className={style.successMain}>
        <img src={require("../../../assets/suggestion/p1.png")} alt="" />
        <div className={style.successTitle}>已收到您的建议</div>
        <div className={style.successDecs}>我们将会在48小时以内回复您</div>
        <div className={style.buttonWrapper}>
          <Button type='primary' className={style.left} onClick={()=>{this.linkTo(`/home`)}}>返回首页</Button>
          <Button type='primary' ghost onClick={()=>{this.setState({showInput:false})}}>继续提交</Button>
        </div>
      </div>
    </div> }
  </div >
  }
}