import { Component } from 'react';
import { connect } from 'dva';
import { CopyrightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import SiderMenu from './SiderMenu';
import HeaderBox from './HeaderBox/index.js';
const { Footer, Content } = Layout;
import FooterBox from './FooterBox/index.jsx'
@connect(({ global }) => ({
    global
}))
export default class PlatformLayout extends Component {
    render () {
        const { collapsed, login, openKeys, selectedKeys } = this.props.global;
        return (
            <Layout id="root">
                <Layout style={{ background: "#fff" }}>
                    <HeaderBox />
                    <Layout style={{ background: "#fff" }}>
                        <SiderMenu collapsed={collapsed} openKeys={openKeys} selectedKeys={selectedKeys} />
                        <Content ref={this.contentRef} style={{ overflow: 'auto', backgroundColor: '#f5f5f5' }}>
                            {this.props.children}
                        </Content>
                    </Layout>
                    <FooterBox />
                </Layout>
            </Layout>
        );
    }
}