import React, { Component } from 'react';
import { Form, Input, Button, Row, Col } from 'antd'
import { connect } from 'dva';
import style from './Login.less';
import Config from '@/utils/config';
import _ from 'lodash'
import { getUrlParam } from '@/utils/utils';
import { LeftOutlined } from '@ant-design/icons'


const namespace = 'global';

@connect(({ global, loading }) => ({
	global,
}))
export default class Forget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isForget: false
		}
	}

	onFinish = (values) => {
		console.log('form values', values)
	}

	changeShowForget = (show) => {
		this.props.dispatch({
			type: `${namespace}/set`,
			payload: {
				showForget: show
			}
		})
	}

	render () {
		const { isForget } = this.state

		return <div className={style.signInContainer}>
			<Form
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 24 }}
				autoComplete="off"
				onFinish={this.onFinish}
			>
				<Form.Item>
					<div onClick={() => { this.changeShowForget(false) }}><LeftOutlined /></div>
				</Form.Item>
				<Form.Item
					name="phone"
					rules={[{ required: true, message: '请输入手机号!' }]}
				>
					<Input placeholder='请输入手机号' />
				</Form.Item>
				<Form.Item
					name="verifyCode"
					rules={[{ required: true, message: '请输入验证码!' }]}
				>
					<Row gutter={8}>

						<Col span={16}>
							<Input placeholder='请输入短信验证码' />
						</Col>
						<Col span={8}>
							<Button type='primary' ghost>获取验证码</Button>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item
					name="mail"
					rules={[{ required: true, message: '请输入邮箱地址!' }]}
				>
					<Input placeholder='请输入邮箱地址' />
				</Form.Item>


				<Form.Item >
					<Button type="primary" htmlType="submit" style={{ width: '100%' }}>
						重置密码
					</Button>
				</Form.Item>
			</Form>
		</div>
	}
}
