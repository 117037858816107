import React, { useEffect, useState } from "react";
import { Card, Tooltip } from "antd";
import { useDispatch } from "dva";

const namespace = "serviceConfig";
function configuration() {
  const dispatch = useDispatch();
  const bindClientType = localStorage.getItem("bindClientType") || "";

  const [configData, useConfigData] = useState({});
  useEffect(() => {
    getConfig();
    return () => {
      useConfigData({});
    };
  }, []);

  const getConfig = () => {
    dispatch({
      type: `${namespace}/getConfig`,
    }).then((response) => {
      useConfigData(response);
    });
  };

  const handleDownload = (url) => {
    let link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("href", url);
    link.setAttribute("download", "订单列表");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{ margin: 24, padding: 24, background: "#fff", minHeight: 500 }}
    >
      <div
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#333333",
          marginBottom: "20px",
        }}
      >
        第三方对接配置
      </div>
      <Card style={{ width: "400px" }}>
        <p>
          <span className="fwbold ml12">密钥 appKey：</span>
          {configData.app_key}
        </p>
        <p>
          <span className="fwbold ml12">密钥 appSecret：</span>
          {configData.app_secret}
        </p>
        <div className="flex">
          <span className="fwbold ml12">对接文档：</span>
          {configData.document_url && (
            <img
              onClick={() => handleDownload(configData.document_url)}
              style={{ width: "16px", height: "20px" }}
              src={require("@/assets/home/fileDownload.png")}
              alt=""
            />
          )}
          {configData.document_url ? (
            <Tooltip title={configData.document_url}>
              <a
                className="ellipsis"
                style={{ width: 200 }}
                onClick={() => handleDownload(configData.document_url)}
              >
                {bindClientType == "point" && (
                  <span>定点医疗服务商对接文档</span>
                )}
                {bindClientType == "direct" && <span>直付服务商对接文档</span>}
                {bindClientType == "tpa" && <span>TPA服务商对接文档</span>}
              </a>
            </Tooltip>
          ) : (
            <span>-</span>
          )}
        </div>
      </Card>
    </div>
  );
}

export default React.memo(configuration);
