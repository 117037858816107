import React, { Component } from "react";
import { Modal, Select, Checkbox } from "antd";
import { Form } from "@ant-design/compatible";
import { connect } from "dva";
import styles from "./index.less";
const namespace = "proposalFormInfo";
const groupCategoryJson = {
  1: "TPA机构",
  2: "第三方直付机构",
  3: "定点医疗服务商",
};
@Form.create()
@connect(({ proposalFormInfo }) => ({
  proposalFormInfo,
}))
export default class ServiceConfigModal extends Component {
  state = {
    siteConfig: [],
    directConfig: [],
    tpaConfig: [],
    tpaGroupId: [],
    configForm: [],
    tpa: "",
  };
  componentDidMount() {
    const { currentRecord } = this.props;
    Promise.all([
      this.getSupplierList(currentRecord, 1),
      this.getSupplierList(currentRecord, 2),
      this.getSupplierList(currentRecord, 3),
    ]).then((values) => {
      if (values.length === 3 && values[0] && values[1] && values[2]) {
        this.setState({
          tpaConfig: values[0],
          directConfig: values[1],
          siteConfig: values[2],
          tpaGroupId: values[0]
            .filter((v) => {
              return v.selected == "1";
            })
            .map((v) => {
              return v.group_id;
            }),
        });
      }
    });
  }

  getSupplierList = (record, index) => {
    return this.props.dispatch({
      type: `${namespace}/fetchSupplierList`,
      payload: {
        customerId: record.cr_id,
        groupCategory: index,
      },
    });
  };

  onchangeClick = (list) => {
    this.setState({
      tpaGroupId: list,
    });
  };

  handleSubmit = () => {
    const { onCancel, currentRecord } = this.props;
    const { configForm, tpaConfig, tpaGroupId } = this.state;
    let totalForm = [];
    totalForm.push(...configForm);
    totalForm.push(
      ...tpaConfig.map((v) => {
        return {
          customer_id: currentRecord.cr_id,
          group_id: v.group_id,
          selected: tpaGroupId.indexOf(v.group_id) >= 0 ? 1 : 0,
        };
      })
    );
    this.props
      .dispatch({
        type: `${namespace}/setSupplierConfig`,
        payload: totalForm,
      })
      .then((res) => {
        onCancel();
      });
  };

  onServiceSelect = (service, date, type) => {
    // type:0-选中 1-修改理赔周期 2-修改理赔日
    const { configForm } = this.state;
    const { currentRecord } = this.props;
    let config = {
      customer_id: currentRecord.cr_id,
      group_id: service.group_id,
      selected: service.selected,
      claim_cycle_unit: service.claim_cycle_unit,
      claim_cycle_day: service.claim_cycle_day,
    };
    switch (type) {
      case 0:
        config.selected = date;
        service.selected = date;
        break;
      case 1:
        config.claim_cycle_unit = date;
        service.claim_cycle_unit = date;
        break;
      case 2:
        config.claim_cycle_day = date;
        service.claim_cycle_day = date;
        break;
    }
    let index = configForm.findIndex((v) => {
      return v.group_id === config.group_id;
    });
    if (index >= 0) {
      configForm[index] = config;
    } else {
      configForm.push(config);
    }
    this.setState({
      configForm: configForm,
    });
  };

  renderChoice = (list) => {
    const cycleOptions = [
      { label: "自然月", value: "month" },
      { label: "自然周", value: "week" },
    ];
    const dateOptions = [];
    for (let i = 1; i <= 28; i++) {
      dateOptions.push(i);
    }
    const weekOptions = [
      "周一",
      "周二",
      "周三",
      "周四",
      "周五",
      "周六",
      "周日",
    ];
    if (list.length > 0) {
      return list.map((service, index) => {
        return (
          <div className="flexst mt5">
            <Checkbox
              onChange={(value) =>
                this.onServiceSelect(service, value.target.checked ? 1 : 0, 0)
              }
              checked={service.selected == "1"}
            >
              {service.group_name}
            </Checkbox>
            <div className="ml15 ta-c mr5">理赔周期: </div>
            <Select
              disabled={service.selected != 1}
              key={service.claim_cycle_unit}
              defaultValue={
                service.claim_cycle_unit === "month" ? "自然月" : "自然周"
              }
              onChange={(value) => this.onServiceSelect(service, value, 1)}
            >
              {cycleOptions.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <div className="ml15 ta-c mr5">
              {service.claim_cycle_unit === "month" ? "每月:" : "每周:"}
            </div>
            <Select
              disabled={service.selected != 1}
              key={service.claim_cycle_day}
              defaultValue={
                service.claim_cycle_unit === "month"
                  ? dateOptions[service.claim_cycle_day - 1]
                  : weekOptions[service.claim_cycle_day - 1]
              }
              onChange={(value) => this.onServiceSelect(service, value, 2)}
            >
              {service.claim_cycle_unit === "month"
                ? dateOptions.map((item, index) => (
                    <Select.Option value={index + 1} key={index}>
                      {item}
                    </Select.Option>
                  ))
                : weekOptions.map((item, index) => (
                    <Select.Option value={index + 1} key={index}>
                      {item}
                    </Select.Option>
                  ))}
            </Select>
            <div className="ml15 ta-c">
              {service.claim_cycle_unit === "month" ? "日" : ""}
            </div>
          </div>
        );
      });
    } else {
      return <div className="color999">暂无</div>;
    }
  };

  render() {
    const { onCancel } = this.props;
    const { tpaConfig, tpaGroupId, siteConfig, directConfig } = this.state;

    return (
      <Modal
        open
        title="服务商配置"
        onCancel={onCancel}
        width="700px"
        onOk={this.handleSubmit}
      >
        <div>
          <p>定点医疗服务商</p>
          {this.renderChoice(siteConfig)}
          <div className={`flexst mt5 ${styles.checkboxStyle}`}>
            <Checkbox disabled={true} checked={true}>
              重庆亿保医药有限公司第一分公司（网）
            </Checkbox>
            <div className="ml15 ta-c mr5">理赔周期: </div>
            <Select disabled={true} defaultValue={"自然月"}></Select>
            <div className="ml15 ta-c mr5">每月:</div>
            <Select disabled={true} defaultValue={5}></Select>
            <div className="ml15 ta-c">日</div>
          </div>
        </div>
        <div className="mt15">
          <p>直付服务商</p>
          {this.renderChoice(directConfig)}
        </div>
        <div className="mt15">
          <p>TPA服务商</p>
          <Checkbox.Group value={tpaGroupId} onChange={this.onchangeClick}>
            {tpaConfig.length > 0 ? (
              tpaConfig.map((item) => {
                return (
                  <Checkbox key={item.group_id} value={item.group_id}>
                    {item.group_name}
                  </Checkbox>
                );
              })
            ) : (
              <div className="color999">暂无</div>
            )}
          </Checkbox.Group>
        </div>
      </Modal>
    );
  }
}
