import { Result, Button } from 'antd';
export default () =>{

    return (
        <div>
             <Result
                status="warning"
                title="暂无权限,请联系管理员"
                // extra={
                // <Button type="primary" key="console">
                //     Go Console
                // </Button>
                // }
            />
        </div>
    );
}