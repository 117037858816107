import { message } from 'antd';
import JSZip from "jszip";
import FileSaver from "file-saver";
import request from "umi-request";
import { stringify } from 'qs';
import Config from '@/utils/config';

export function formSelectList (selectList) {
    let newList = [];
    if (selectList instanceof Array) {
        selectList.map((item, index) => {
            if (item !== '全部') {
                newList.push({
                    id: item,
                    name: item,
                })
            } else {
                newList.push({
                    id: '',
                    name: item
                })
            }
        })
    }
    return newList
}

export function getUrlParam (paraName) {
    let url = document.location.toString();
    let arrObj = url.split('?');

    if (arrObj.length > 1) {
        let arrPara = arrObj[1].split('&');
        let arr;

        for (let i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split('=');

            if (arr != null && arr[0] == paraName) {
                return arr[1];
            }
        }
        return '';
    } else {
        return '';
    }
}


export function formCheckList (selectList, name = 'name', id = 'id') {
    let newList = []
    if (selectList instanceof Array) {
        selectList.map((item, index) => {
            let obj = {};
            newList.push({
                id: item[id],
                name: item[name]
            })
        })
    }
    return newList
}

export function replaceAllChar (sourceValue, searchValue, replaceValue) {
    if (!searchValue && !replaceValue) return ''
    var reg = new RegExp(searchValue, "g");
    return sourceValue.replace(reg, replaceValue)
}

//下载
export function downFile (blob, filename) {
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}
// 导出文件
export function getAndDownloadFile (url, payload, filename) {
    return fetch(
        `${Config.GatewayAdmin}${url}${payload ? `?${stringify(payload, { arrayFormat: "repeat" })}` : ""
        }`,
        {
            method: "GET",
            responseType: "arraybuffer",
            headers: new Headers({
                Authorization: localStorage.getItem("token")
                    ? `Bearer ${localStorage.getItem("token")}`
                    : "",
                "ebao-uid": localStorage.getItem("userId"),
            }),
        }
    ).then((res) =>
        res.blob().then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            let _filename = filename || "列表文件.xls";
            a.href = url;
            a.download = _filename;
            a.click();
            window.URL.revokeObjectURL(url);
            message.success("导出文件成功！");
        })
    );
}

export function getNameById (arr, id) {
    let name = ''
    if (arr) {
        arr.forEach(item => {
            if (item.id == id) {
                name = item.name
            }
        })
    }
    return name;
}
export function beautStr (str, num) {
    if (str && (str.length > num)) {
        return str.substring(0, num) + "...";
    } else {
        return str;
    }
}

export function outSensitive (str) {
    var pat = /(\d{3})\d*(\d{4})/;
    let leng = str.length - 7;
    let addr = '';
    for (let i = 0; i < leng; i++) {
        addr = addr.concat('*')
    }
    var b = str.replace(pat, `$1${addr}$2`);
    return b;
}

export const modules = {
    toolbar: {
        container:
            [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                // [{ list: 'ordered' }, { list: 'bullet' }],       
                [{ 'align': [] }],
                // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

            ],
    }
}
export const MED_CLASS = {
    0: '未知',
    1: '西药',
    2: '中成药',
}
export const MED_RX = {
    0: '未知',
    1: 'RX',
    2: 'OTC',
}

export const CONSULT_TYPE = {
    0: '快速咨询',
    1: '专科问诊',
    2: '在线开药',
    3: '中医快问',
    4: '用药指导',
    // 5: '看病开药'
}

export const invoiceStateJson = {
    0: '未申请',
    1: '待开票',
    2: '已开票',
    3: '开票失败'
}

export function json2Array (json, id = "id", name = "name") {
    let arr = [];
    for (var key in json) {
        arr.push({
            id: key,
            name: json[key]
        })
    }
    return arr;
}

export function jsonNumKeyToArray (json, id = "id", name = "name") {
    let arr = [];
    for (var key in json) {
        arr.push({
            id: Number(key),
            name: json[key]
        })
    }
    return arr;
}

export function formatPrice (inputStr) {
    if (!inputStr) return inputStr
    let str = inputStr
    str = str.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
    str = str.replace(/^\./g, ""); //验证第一个字符是数字而不是字符          
    str = str.replace(/\.{2,}/g, "."); //只保留第一个.清除多余的       
    str = str.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    str = str.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
    return str
}

export function formatNumber (inputStr) {
    let str = inputStr.replace(/\D/g, '')
    return str
}


// 为String扩展自定义属性, searchValue替换为replaceValue
String.prototype.replaceAll = function (searchValue, replaceValue) {
    var reg = new RegExp(searchValue, "g");
    return this.replace(reg, replaceValue);
}

const getFile = (url) => {
    return new Promise((resolve, reject) => {
        request.get(url, {
            responseType: 'blob'
        }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

/**
 * 批量下载文件并打包
 * @param {array}  data       url array
 * @param {string}  fileName  Zip Filename
 * @return {*}  null
 */
export const downloadAndZip = (data, fileName) => {
    if (!data) return;
    const zip = new JSZip();
    const promises = [];  //用于存储多个promise

    data.img_urls && data.img_urls.forEach((item, index) => {
        const promise = getFile(item).then((res) => {
            const fileName = item.split('/').reverse()[0];
            zip.file(`证照/${fileName}` || index, res, { binary: true });
        })
        promises.push(promise)
    })

    data.publicity_materials_urls && data.publicity_materials_urls.forEach((item, index) => {
        const promise = getFile(item).then((res) => {
            const fileName = item.split('/').reverse()[0];
            zip.file(`企业宣传资料/${fileName}` || index, res, { binary: true });
        })
        promises.push(promise)
    })

    data.enterprise_data_urls && data.enterprise_data_urls.forEach((item, index) => {
        const promise = getFile(item).then((res) => {
            const fileName = item.split('/').reverse()[0];
            zip.file(`企业其他资料/${fileName}` || index, res, { binary: true });
        })
        promises.push(promise)
    })

    Promise.all(promises).then(() => {
        zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",  // STORE：默认不压缩 DEFLATE：需要压缩
            compressionOptions: {
                level: 9               // 压缩等级1~9    1压缩速度最快，9最优压缩方式
            }
        }).then((res) => {
            FileSaver.saveAs(res, fileName ? fileName : "亿保宣传资料.zip") // 利用file-saver保存文件
        })
    })
}


/**
 * 批量下载文件并打包
 * @param {array}  data       url array
 * @param {string}  fileName  Zip Filename
 * @return {*}  null
 */
export const downloadAndZip2 = (data, fileName) => {
    const zip = new JSZip();
    const promises = [];  //用于存储多个promise

    data.forEach((item, index) => {
        const promise = getFile(item).then((res) => {
            const fileName = item.split('/').reverse()[0];
            zip.file(fileName || index, res, { binary: true });
        })
        promises.push(promise)
    })

    Promise.all(promises).then(() => {
        zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",  // STORE：默认不压缩 DEFLATE：需要压缩
            compressionOptions: {
                level: 9               // 压缩等级1~9    1压缩速度最快，9最优压缩方式
            }
        }).then((res) => {
            FileSaver.saveAs(res, fileName ? fileName : "亿保宣传资料.zip") // 利用file-saver保存文件
        })
    })
}

export const downloadFile = (url, fileName) => {
    if (!fileName) {
        fileName = url.split('/').reverse()[0]
    }
    getFile(url).then((res) => {
        FileSaver.saveAs(res, fileName);
    })
}

/** 校验 8-16 位由数字和大小写字母组成的密码 */
export const verifyPassWord = (val) => {
    if (typeof val !== 'string') return false
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/g.test(val)
}

export function setCookie(cName, value, expiredays) {
    console.log(cName, value, expiredays)
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = cName + '=' + escape(value) +
        ((expiredays === null) ? '' : ';path=/; expires=' + exdate.toGMTString());
}

export const getCookie = (cName) => {
    if (document.cookie.length > 0) {
      let cStart = document.cookie.indexOf(`${cName}=`)
      if (cStart !== -1) {
        cStart = cStart + cName.length + 1
        let cEnd = document.cookie.indexOf(';', cStart)
        if (cEnd === -1) {
          cEnd = document.cookie.length
        }
        return decodeURIComponent(document.cookie.substring(cStart, cEnd))
      }
    }
    return ''
  }
  
export const getOpenKeys = (keys,pathArry) => {
    const len = pathArry.length
    if (len < 3) return
    let pathStr = pathArry[1]
    for (let index = 2; index <= pathArry.length-2; index++) {
        pathStr += `/${pathArry[index]}`
        if (!keys.includes(pathStr)) {
            keys.push(pathStr)
        }
    }
}


export const formatVideoValue = (value) => {
    if (Number(value) < 10000) {
        return value
    }else{
        return `${(Number(value) / 10000).toFixed(1)}w`
    }
}