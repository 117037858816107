import React, { Component } from 'react'
import style from './index.less'
import { formatVideoValue } from '@/utils/utils';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { getUrlParam } from '@/utils/utils';
import { connect } from 'dva';

const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    state = {
        likes_count: 0,
        id: getUrlParam('id'),
        collapse: true,
        like_state: false,
        textOverflow: false,
    }
    componentDidMount() {
        const { likes_count } = this.props
        this.setState({
            likes_count,
        })
        // 文本溢出是否
        const descEle = document.getElementById('desc')
        if (descEle && descEle.scrollHeight > descEle.clientHeight) {
            this.setState({textOverflow:true})
        }
        // 点赞状态
        this.getLikeState()
    }
    handleLike = () => {
        const { like_state, id } = this.state
        const likeList = JSON.parse(sessionStorage.getItem('likeList')) || []
        let newList = []
        if (likeList.includes(id)) {
            newList = likeList.filter(item => item != id)
        } else {
            newList = likeList.concat([id])
        }
        this.props.dispatch({
            type: `${namespace}/fetchChangeLike`,
            payload: {
                videoId: id,
                addLikesType: like_state ? 0 : 1,
            }
        }).then(res => {
            this.setState({ likes_count: res })
            sessionStorage.setItem('likeList', JSON.stringify(newList))
            this.getLikeState()
        });

    }
    getLikeState = () => {
        const { id } = this.state
        const likeList = JSON.parse(sessionStorage.getItem('likeList')) || []
        if (likeList.includes(id)) {
            this.setState({
                like_state: true
            })
        } else {
            this.setState({
                like_state: false
            })
        }
    }

    render() {
        const { collapse, like_state, likes_count,textOverflow } = this.state
        const { detail={} } = this.props
        return <div className={style.wrapper}>
            <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
                <div className={style.desc}>
                    <div className={style.title}>新闻动态</div>
                    <div className={style.text}>News updates</div>
                </div>
                <img src={require("../../assets/newsBanner.png")} alt="" />
            </div>

            <div className={style.mainContent} style={{ backgroundColor: '#F9F9F9', padding: '64px 100px', margin: '40px auto 40px auto' }}>
                <div className={`${style.detailTitle} twoline`}>{detail.video_title}</div>
                <div className={style.divide} />
                <div className={style.detailDate}>时间：{detail.updated_at}</div>
                <div id='desc' className={`${style.videoDesc} ${collapse ? 'threeline' : ''}`}>{detail.video_desc}</div>
                {textOverflow && <div className={style.collapseStyle} onClick={() => { this.setState({ collapse: !collapse }) }}>
                    {!collapse ? '收起' : '展开'}&nbsp;&nbsp;{!collapse ? <UpOutlined /> : <DownOutlined />}
                </div>}
                <div className='flexcenter' style={{ marginTop: 60 }}>
                    <video
                        controls
                        src={detail.video_url}
                        width={1000}
                    />
                </div>
                <section style={{ position: 'absolute', right: 100, marginTop: 10 }}>
                    <img style={{ width: 18, height: 12 }} src={require('@/assets/news/view.png')} alt="" />
                    <span className='ml5 mr20'>{formatVideoValue(detail.views_count)}</span>
                    <img style={{ width: 14, height: 12 }} onClick={() => { this.handleLike() }} className="pointer" src={like_state ? require('@/assets/news/like_red.png') : require('@/assets/news/like_gary.png')} alt="" />
                    <span className='ml5'>{formatVideoValue(likes_count)}</span>
                </section>
            </div>
        </div>
    }
}