import easingTypes from 'tween-functions';
import { windowIsUndefined, parsePath } from '../utils';

easingTypes.path = function (_path, _param) {
  var param = _param || {};

  if (windowIsUndefined) {
    return 'linear';
  }

  var pathNode = parsePath(_path);
  var pathLength = pathNode.getTotalLength();
  var rect = param.rect || 100; // path 的大小，100 * 100，

  var lengthPixel = param.lengthPixel || 200; // 线上取点像素，默认分为 200 段。。

  var points = [];

  for (var i = 0; i < lengthPixel - 1; i++) {
    points.push(pathNode.getPointAtLength(pathLength / (lengthPixel - 1) * i));
  }

  points.push(pathNode.getPointAtLength(lengthPixel));
  return function path(t, b, _c, d) {
    var p = easingTypes.linear(t, b, _c, d);
    var timePointX = rect * p; // X 轴的百分比;
    // 取出 x 轴百分比上的点;

    var point = points.filter(function (item) {
      return item.x >= timePointX;
    })[0] || pathNode.getPointAtLength(p * pathLength);
    return 1 - point.y / rect;
  };
};

export default easingTypes;