import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 消费信息列表查询
export async function getConsumeList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/admin/consume/list`, params)
}

// 消费明细
export async function getConsumeDetail(id) {
    return Gateway.get(`${Config.GatewayPlatform}/admin/consume/details?co_id=${id}`)
}
