import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取新闻动态列表
export async function getNewsList(params) {
    return Gateway.post(`${Config.GatewayAdmin}/website/home/news/list`, params)
}
// 新闻动态详情
export async function getNewsDetail(params ) {
    return Gateway.get(`${Config.GatewayAdmin}/website/home/news/detail`,params)
}

// 获取视频列表
export async function getVideoList(params) {
    return Gateway.post(`${Config.GatewayAdmin}/website/home/videos/list`, params)
}
// 视频资讯详情
export async function getVideoDetail(params ) {
    return Gateway.get(`${Config.GatewayAdmin}/website/home/videos/detail`,params)
}
// 点赞/取消点赞
export async function updateLike(params ) {
    return Gateway.get(`${Config.GatewayAdmin}/website/home/videos/likes/update`,params)
}