import { message } from 'antd';
import { getClaimSettingList } from '@/service/platform/businessCenter/claimOperateManage'
import { getUnitList } from '@/service/login';

export default {
    namespace: 'claimSetting',
    state: {
        searchCond: {
            page_size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0,
        insureUnitList: [],
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.claimSetting.searchCond);
            const { items, count } = yield call(getClaimSettingList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: items,
                    totalNum: count,
                },
            });
        },
        *fetchInsureUnitList({ payload }, { call, put, select }) {
            const paramData = {
                agent_id: localStorage.getItem('bindClientId'),
                page: 1,
                page_size: 100
            }
            const res = yield call(getUnitList, paramData)
            const relatedUnitList = res?.map(item => ({ unit_id: item.id, unit_name: item.name }))
            localStorage.setItem('relatedUnitList', JSON.stringify(relatedUnitList) || [])
            yield put({
                type: "set",
                payload: {
                    insureUnitList: res || [],
                },
            });
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};