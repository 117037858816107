import { message } from 'antd';
import { getConsumeList, getConsumeDetail } from '@/service/platform/basicModule/drugOrderManage'

export default {
    namespace: 'expenseInfo',
    state: {
        searchCond: {
            page_size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0,
        consumeDetailList: [],
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.expenseInfo.searchCond);
            const { items, count } = yield call(getConsumeList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: items,
                    totalNum: count,
                },
            });
        },
        *fetchConsumeDetail({ payload }, { call, put, select }) {
            const res = yield call(getConsumeDetail, payload);
            yield put({
                type: "set",
                payload: {
                    consumeDetailList: res,
                },
            });
        },

    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};