import { Component } from "react";
import { connect } from "dva";
import { Layout, Menu, Dropdown, Button } from "antd";
import Link from "umi/link";
import styles from "./index.less";
import router from "umi/router";
import { ROLE_TYEP, ROLE_ENUM_TO_CHINESE } from "@/common/constant";
import { loginOut } from "@/service/login";
import { menu } from "@/layout/SiderMenu/menu";

const { Header } = Layout;
const { SubMenu } = Menu;
const clientTypeToName = {
  agent: "保险公司",
  unit: "投保单位",
  point: "定点医疗服务商",
  direct: "直付服务商",
  tpa: "TPA服务商",
};

const namespace = "global";
@connect(({ global, drug }) => ({
  global,
  drug,
}))
export default class HeaderBox extends Component {
  state = {
    platFormTopMenu: [
      // {
      //   label: '保险公司工作台',
      //   key: '/insurancePlatform/insure',
      // },
    ],
    current: "/platform/insure",
  };

  allMenuItem = (data) => {
    return (
      data &&
      data.map((item, index) => {
        if (item.children_menus && item.children_menus.length) {
          return (
            <SubMenu
              key={item.menu_identify}
              title={
                <span>
                  <span>{item.menu_name}</span>
                </span>
              }
            >
              {this.allMenuItem(item.children_menus || [])}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={item.menu_identify}>
            <Link to={`/${item.menu_identify}`}>
              <span>{item.menu_name}</span>
            </Link>
          </Menu.Item>
        );
      })
    );
  };

  loginOut = async () => {
    const token = localStorage.getItem("token") || "";
    const res = await loginOut(token);
    if (res) {
      localStorage.clear();
      router.push("/");
      const newSearch = {
        page_size: 12,
        page: 1,
        organ_type: "1",
      };
      this.props.dispatch({
        type: `drug/set`,
        payload: { searchCond: newSearch },
      });
    }
  };

  toInsurancePlatform = () => {
    router.push("/platform/index");
  };

  toLogin = () => {
    localStorage.clear();
    router.push(`/login?returnUrl=${encodeURIComponent(location.href)}`);
  };

  nameFormat = (str, len) => {
    let result = "";
    if (str.length > len) {
      result = str.slice(0, len) + "...";
    } else {
      result = str;
    }
    return result;
  };

  onInsuranceMenuClick = (e) => {
    this.setState({
      current: e.key,
    });
    router.push(e.key);
  };

  handleGoUserCenter = () => {
    router.push(`/platform/basicModule/enterpriseManage/configuration`);
  };

  render() {
    const { selectedKeys } = this.props[namespace];
    const { platFormTopMenu, current } = this.state;
    // const roleType =
    //   localStorage.getItem("roleType") || ROLE_TYEP.SOCIAL_ORGANIZATION;
    const roleId = localStorage.getItem("roleId") || "";
    const organName = localStorage.getItem("organName") || "";
    const realName = localStorage.getItem("realName") || "";
    const bindClientType = localStorage.getItem("bindClientType") || "";
    const menuDrop = (
      <Menu>
        <Menu.Item key={"index-0"}>
          <span onClick={this.handleGoUserCenter}>企业中心</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className={styles.header}>
        {window.location.href &&
        window.location.href.indexOf("/platform") > -1 ? (
          <div className={styles.leftBox}>
            <span
              className={styles.logoBox}
              onClick={() => router.push("/home")}
            >
              <img src={require("@/assets/home/logo2.png")}></img>
              <div>{clientTypeToName[bindClientType]}工作台</div>
            </span>
            <Menu
              mode="horizontal"
              selectedKeys={[current]}
              onClick={this.onInsuranceMenuClick}
              style={{ minWidth: 104 * platFormTopMenu.length + "px" }}
              items={platFormTopMenu}
            ></Menu>
          </div>
        ) : (
          <div className={styles.leftBox}>
            <span className={styles.logoBox} onClick={() => router.push("/")}>
              <img src={require("@/assets/home/logo2.png")}></img>
              {/* <div>{ROLE_ENUM_TO_CHINESE[roleType]}服务平台</div> */}
              <div>海南老白健康科技有限公司</div>
            </span>
              <Menu style={{flex:1}} mode="horizontal" selectedKeys={selectedKeys}>
                {this.allMenuItem(menu)}
              </Menu>
          </div>
        )}
        <div className={styles.right}>
          {!!localStorage.getItem("token") ? (
            <div>
              {window.location.href.indexOf("/platform") > -1 &&
                localStorage.getItem("roleName") && (
                  <div className={styles.roleStyle}>
                    {localStorage.getItem("roleName")}
                  </div>
                )}
              您好，
              {bindClientType == 'point' || bindClientType == 'direct' || bindClientType == 'tpa' ? (
                <Dropdown overlay={menuDrop} placement="bottomCenter">
                  <span>
                    {`${this.nameFormat(organName, 15)}-${this.nameFormat(
                      realName,
                      6
                    )}`}
                  </span>
                </Dropdown>
              ) : (
                <span>
                  {`${this.nameFormat(organName, 15)}-${this.nameFormat(
                    realName,
                    6
                  )}`}
                </span>
              )}
              <Button
                className="ml15"
                onClick={() => {
                  this.loginOut();
                }}
              >
                退出登录
              </Button>
              {window.location.href.indexOf("/platform") == -1 &&
              localStorage.getItem("bindClientType") ? (
                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={this.toInsurancePlatform}
                >
                  进入工作台
                </Button>
              ) : null}
            </div>
          ) : (
            <Button
              type="primary"
              ghost
              style={{ cursor: "pointer" }}
              onClick={this.toLogin}
            >
              登录
            </Button>
          )}
        </div>
      </Header>
    );
  }
}
