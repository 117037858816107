import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取员工列表
export async function getStaffList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/user/query/list`, params)
}
// 新增员工
export async function getAddStaff(params) {
    return Gateway.post(`${Config.GatewayPlatform}/user/add`, params)
}
// 修改员工
export async function getEditStaff(params) {
    return Gateway.post(`${Config.GatewayPlatform}/user/update`, params)
}