import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import moment from 'moment';
import AddModel from './addModal';
import _ from 'lodash';

const namespace = 'insuranceStaff';
@connect(({ insuranceStaff }) => ({ insuranceStaff }))
export default class InsuranceStaff extends Component {
    state = {
        currentData: {},
        showAddModel: false,
    }
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            if (v) {
                searchCond[k] = v
            } else {
                delete searchCond[k]
            }
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.page_size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const { showAddModel, currentData } = this.state
        const searchList = [
            { key: "id", type: "INPUT", placeholder: "用户id", width: '220px' },
            { key: "mobile_no", type: "INPUT", placeholder: "手机号", width: '220px' },
            { key: "real_name", type: "INPUT", placeholder: "真实姓名", width: '220px' },
        ]
        const columns = [
            { title: '用户id', dataIndex: 'id', align: 'center' },
            { title: '手机号', dataIndex: 'mobile_no', align: 'center' },
            { title: '用户名', dataIndex: 'user_name', align: 'center' },
            { title: '真实姓名', dataIndex: 'real_name', align: 'center' },
            { title: '邮箱', dataIndex: 'email', align: 'center', },
            { title: '用户身份', dataIndex: 'role_name', align: 'center',render:text=>text?.map(item=>item.role_name).join(',') },
            { title: '操作人员', dataIndex: 'Operator_name', align: 'center' },
            {
                title: '操作', dataIndex: 'x', align: 'center', fixed: 'right', render: (text, record) => (
                    <span>
                        <Button type='primary' onClick={() => { this.setState({ showAddModel: true, currentData: record }) }}>编辑</Button>
                    </span>
                )
            },
        ]
        const pagination = {
            pageSize: searchCond.page_size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            {/* <Button className='mb10' type='primary' onClick={() => { this.setState({ showAddModel: true, currentData: {} }) }}>新增</Button> */}
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
                searchReset={this.searchReset}
            />
            <Table
                rowKey="id"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
            />
            {showAddModel && <AddModel currentData={currentData} onCancel={() => this.setState({ showAddModel: false, currentData: {} })} />}
        </div>
    }
}