import React, { Component } from 'react'
import style from './index.less'
import { Input, Button, Pagination, message } from 'antd';
import { getUrlParam } from '@/utils/utils';
import { connect } from 'dva';
import router from 'umi/router'

const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    state = {
        detail:{},
    }
    componentDidMount() {
        this.getDetail()
    }
    getDetail = () => {
        const id = getUrlParam('id')
        this.props.dispatch({
            type: `${namespace}/fetchNewsDetail`,
            payload:{newsId:id}
        }).then(res=>{
            this.setState({detail:res})
        });
    }
   
    render() {
        const { detail } = this.state
        return <div className={style.wrapper}>
            <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
                <div className={style.desc}>
                    <div className={style.title}>新闻动态</div>
                    <div className={style.text}>News updates</div>
                </div>
                <img src={require("../../assets/newsBanner.png")} alt="" />
            </div>
            <div className={style.mainContent} style={{backgroundColor:'#F9F9F9',padding:'64px 100px',margin:'40px auto 40px auto'}}>
                <div className={style.detailTitle}>{detail.title}</div>
                <div className={style.divide} />
                <div className={style.detailDate}>时间：{detail.present_time?.replace(/-/g,'/')}</div>
                <div className={style.newsContent}>
                    <div dangerouslySetInnerHTML={{__html:detail.content_major}}></div>
                </div>
            </div>
        </div >
    }
}