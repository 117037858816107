import {Component} from 'react';
import styles from './UserLayout.less';

export default class  UserLayout extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.top}>
            
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}