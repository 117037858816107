import { message } from 'antd';
import { getHolderList } from '@/service/platform/basicModule/proposalFormManagement'

export default {
    namespace: 'policyholder',
    state: {
        searchCond: {
            page_size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.policyholder.searchCond);
            const { records, total } = yield call(getHolderList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: records,
                    totalNum: Number(total),
                },
            });
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};