
import React, { Component } from 'react'
import { CopyrightOutlined } from '@ant-design/icons';
import router from 'umi/router'
import { Layout } from 'antd';
import styles from './index.less'

export default class FooterBox extends Component {
    constructor(props) {
        super(props)
    }
    linkTo = (value) => {
        router.push(value)
    }
    render() {
        const { show,scrollToTop } = this.props;
        return <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.content} onClick={()=>{this.linkTo('/service/contact')}}>
                    <img src={require("../../assets/kefu.png")} alt="" />
                    <div>联系</div>
                    <div>我们</div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.content} onClick={()=>{this.linkTo('/service/suggestions')}}>
                    <img src={require("../../assets/suggestion.png")} alt="" />
                    <div>意见</div>
                    <div>建议</div>
                </div>
            </div>
            {show && <div className={styles.icon} onClick={scrollToTop}><img src={require("../../assets/toTop.png")} alt="" /></div>}
        </div>
    }
}