import { message } from 'antd';
import { data } from '../data.js'

export default {
    namespace: 'TPADetail',
    state: {
        searchCond: {
            size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            console.log(data);
            const searchCond = JSON.parse(JSON.stringify(yield select((state) => state.TPADetail.searchCond)))
            console.log("searchCond",searchCond);
            const page = searchCond.page
            const size = searchCond.size
            delete searchCond.page
            delete searchCond.size
            let filterData = JSON.parse(JSON.stringify(data))
            const filterValue = (key,value) => {
                return filterData.filter(item=>item[key].includes(value))
            } 
            Object.entries(searchCond).forEach(([k, v]) => {
                filterData = filterValue(k,v)
            });
            console.log('filterData',filterData);
            yield put({
                type: "set",
                payload: {
                    dataList: filterData.slice((page-1)*size,page*size-1),
                    totalNum: filterData.length,
                },
            });
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};