const socialProvideData = [
  {
    backgroundImg: require('@/assets/home/social/provide1.png'),
    imgWidth:208,
    desc: '药品保障',
    maskContent: [
      { title: '', content: ['以“临床必需、安全有效、平价保供、改善健康”为药品遴选原则，为用户提供“厂家到患者”端对端药品服务。药品保障目录覆盖15+个科室、350+种疾病、1600+药品，以及保健品、医疗器械等非药品1000+种'] },
    ] 
  },
  {
    backgroundImg: require('@/assets/home/social/provide2.png'),
    imgWidth:208,
    desc: '目录定制',
    maskContent: [
      { title: '', content: ['可根据需要和群体特征进行综合评估，定制专属药品目录，科学、规范、精细、动态管理，保障合理用药需求'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/social/provide3.png'),
    imgWidth:208,
    desc: '健康管理',
    maskContent: [
      { title: '', content: ['提供慢病管理、就医绿通、CAR-T权益、陪诊陪护、协助就医、健康体检，疫苗接种、心理咨询和健康消费等丰富的健康管理服务'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/social/provide4.png'),
    imgWidth:208,
    desc: '社群运营',
    maskContent: [
      { title: '', content: ['搭建用户社群，专业医生7*12小时在线为用户提供疾病答疑、用药咨询、报告解读、健康改善和知识科普等服务'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/social/provide5.png'),
    imgWidth:208,
    desc: '系统集成',
    maskContent: [
      { title: '1、自主研发', content: ['百人技术团队，年研发投入超2000万，自主搭建健康管理平台、互联网医疗购药平台、商保集采平台,自主研发PBM系统服务模式'] },
      { title: '2、自有专利', content: ['获得6项专利、23个软件著作权，多个发明专利被国家知识产权局受理'] },
      { title: '3、自有资质', content: ['通过ISO9001质量管理体系认证、信息系统安全等级保护三级等多项业内权威的服务体系认证'] },
    ]
  },
]

const insuranceProvideData = [
  {
    backgroundImg: require('@/assets/home/insurance/provide1.png'),
    imgWidth:260,
    desc: '锚定收益',
    maskContent: [
      { title: '', content: ['通过供应链创新优化医药流通中间环节利润结构，将传统模式中中间商所获收益用于弥补保司所承担的赔付风险，从而为保司节省服务成本、提高收益'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/insurance/provide2.png'),
    imgWidth:260,
    desc: '品牌强化',
    maskContent: [
      { title: '', content: ['系统对接、定制集成，确保品牌充分展示，提供统一接口，支持短信、微信推送等客户服务手段升级，帮助品牌露出和强化'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/insurance/provide3.png'),
    imgWidth:260,
    desc: '服务延伸',
    maskContent: [
      { title: '', content: ['除全面的药品保障和互联网诊疗服务外，延伸慢病管理、就医绿通、CAR-T权益、陪诊陪护、协助就医、健康消费服务等丰富的增值服务、满足客户多元需求'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/insurance/provide4.png'),
    imgWidth:260,
    desc: '数字资产',
    maskContent: [
      { title: '', content: ['统一管理用户健康数据，加密传输、加密存储、两地三中心备份、定时备份(代码与日志系统)，实现不可篡改、可溯源数据，可充分保障数据安全'] },
    ]
  }
]

const hrProvideData = [
  {
    backgroundImg: require('@/assets/home/hr/provide1.png'),
    imgWidth:260,
    desc: '药品保障',
    maskContent: [
      { title: '', content: ['目录包含中西药品、营养补充剂、中药饮片和医疗器械四大用药类别，覆盖15+个科室、350+种疾病、1600+药品，以及非药品1000+种，能够充分满足员工大部分常见病、慢性病用药需求'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/hr/provide2.png'),
    imgWidth:260,
    desc: '增值服务',
    maskContent: [
      { title: '', content: ['集成互联网诊疗、慢病管理、就医绿通、CAR-T权益、陪诊陪护、协助就医、健康消费服务等丰富的增值服务，满足员工多元需求'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/hr/provide3.png'),
    imgWidth:260,
    desc: '增强福祉',
    maskContent: [
      { title: '', content: ['通过供应链创新优化医药流通中间环节利润结构，为员工提供端对端的用药服务，并提供积分商城服务，让员工买得到、用得掉、花得完，大大降低医疗费用支出、增强员工福利感知'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/hr/provide4.png'),
    imgWidth:260,
    desc: '结果可视',
    maskContent: [
      { title: '', content: ['沉淀员工健康管理服务数据，管理更加自主、高效、可视化，数据安全更有保障，数据利用和开发更加便捷'] },
    ]
  }
]

const pharmacyProvideData = [
  {
    backgroundImg: require('@/assets/home/pharmacy/provide1.png'),
    imgWidth:346,
    desc: '百亿级的商保医药市场',
    maskContent: [
      { title: '', content: ['丰富的商保业务资源和敏锐的行业洞察力，为药企提供广阔的药品销售市场'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/pharmacy/provide2.png'),
    imgWidth:346,
    desc: '品牌宣传和新药推广',
    maskContent: [
      { title: '', content: ['“智慧员福”“普惠好药”“商保好药”三大平台、200+万用户，可帮助药企完成品牌宣传和新药推广'] },
    ]
  },
  {
    backgroundImg: require('@/assets/home/pharmacy/provide3.png'),
    imgWidth:348,
    desc: '高效的合作协议',
    maskContent: [
      { title: '', content: ['专业的采购团队，从达成合作到药品入选目录流程都由专业人员操作把关，从合同用印到商保入库，流程严谨细致，合作高效便捷'] },
    ]
  },
  
]



export const socialData = {
  provideData: socialProvideData,
}
export const insuranceData = {
  provideData: insuranceProvideData,
}
export const hrData = {
  provideData: hrProvideData,
}
export const pharmacyData = {
  provideData: pharmacyProvideData,
}

