import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Modal } from 'antd';
import styles from './index.less'

export default class PDFView extends Component {
	state = {
		totalPage: 1,
		pdfUrl: '', // 文件资料地址
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ totalPage: numPages })
	}

	render () {
		const { pdfUrl = '' } = this.props
		const { totalPage } = this.state

		return (
			<Modal width='80vw' height='80vh' visible={true} wrapClassName={styles.preview} onCancel={this.props.onCancel} footer={null}>
				<Document
					file={pdfUrl}   //文件路径
					onLoadSuccess={this.onDocumentLoadSuccess} //成功加载文档后调用
					onLoadError={console.error} //加载失败时调用
					loading="正在努力加载中，请稍后" //加载时提示语句
					externalLinkTarget={'_blank'}
				>
					{new Array(totalPage).fill('').map((i, index) => index > 0 && <Page key={index} pageNumber={index} loading='正在努力加载中，请稍后'></Page>)}
				</Document>
			</Modal>
		)
	}
}
