import React, { Component } from "react";
import CheckBox from "@/component/CheckBox";
import { Table, Tooltip } from "antd";
import { connect } from "dva";
import moment from "moment";
import _ from "lodash";

const namespace = "expenseInfo";
@connect(({ expenseInfo }) => ({ expenseInfo }))
export default class ExpenseInfo extends Component {
  componentDidMount() {
    this.getList();
  }

  getList = () => {
    this.props.dispatch({
      type: `${namespace}/fetchList`,
    });
  };

  searchSubmit = (data) => {
    const { searchCond } = this.props[namespace];
    Object.entries(data).forEach(([k, v]) => {
      if (k === "time") {
        if (v && v.length > 0) {
          searchCond.start_day = `${moment(v[0]).format("YYYY-MM-DD")}`;
          searchCond.end_day = `${moment(v[1]).format("YYYY-MM-DD")}`;
        } else {
          delete searchCond.start_time;
          delete searchCond.end_day;
        }
      } else {
        searchCond[k] = v;
      }
    });
    searchCond.page = 1;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.getList();
  };

  searchReset = () => {
    const searchCond = { page_size: 10, page: 1 };
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.getList();
  };

  handleTableChange = (pagination) => {
    const { searchCond } = this.props[namespace];
    searchCond.page_size = pagination.pageSize;
    searchCond.page = pagination.current;
    this.props.dispatch({
      type: `${namespace}/set`,
    });
    this.getList();
  };

  //   checkDetail = (record) => {
  //     router.push({
  //       pathname: "/platform/basicModule/drugOrderManage/consumeDetail",
  //       state: {
  //         co_id: record.co_id,
  //       },
  //     });
  // this.setState({showDetailModal:true,currentData:record})
  // this.props.dispatch({
  //     type: `${namespace}/fetchConsumeDetail`,
  //     payload: record.id,
  // }).then(res=>{
  //     if (res) {
  //         this.setState({showDetailModal:true,currentData:res})
  //     }
  // });
  //   };

  render() {
    const { searchCond, totalNum, dataList } = this.props[namespace];
    const searchList = [
      {
        key: "cr_name",
        type: "INPUT",
        placeholder: "保单名称",
        width: "220px",
      },
      {
        key: "time",
        type: "RANGEDATE",
        width: "280px",
        placeholder: ["开始时间", "结束时间"],
        rangeDateLabel: { firstTime: "start_day", secondTime: "end_day" },
      },
    ];
    const sexMap = {
      0: "未知",
      1: "男",
      2: "女",
    };
    const columns = [
      {
        title: "消费时间",
        dataIndex: "consumed_at",
        align: "center",
        width: "150px",
      },
      {
        title: "对应保单id",
        dataIndex: "policy_no",
        align: "center",
        render: (text) => <div style={{ width: "90px" }}>{text || "-"}</div>,
      },
      {
        title: "保单名称",
        dataIndex: "cr_name",
        align: "center",
        render: (text) => <div style={{ width: "90px" }}>{text || "-"}</div>,
      },
      {
        title: "类型",
        dataIndex: "type",
        align: "center",
        width: "150px",
        render: () => "线上",
      },
      {
        title: "金额",
        dataIndex: "amount",
        align: "center",
        width: "150px",
        render: (text, _record) => (text ? Number(text).toFixed(2) : 0),
      },
      {
        title: "额度消耗",
        dataIndex: "insured_used_amount",
        align: "center",
        width: "150px",
        render: (text, _record) => (text ? Number(text).toFixed(2) : 0),
      },
      {
        title: "外部订单号",
        dataIndex: "out_trade_no",
        align: "center",
        render: (text) => <div style={{ width: "100px" }}>{text || "-"}</div>,
      },
      {
        title: "投保单位",
        dataIndex: "unit_name",
        align: "center",
        render: (text) => <div style={{ width: "100px" }}>{text || "-"}</div>,
      },
      {
        title: "商家集团",
        dataIndex: "group_name",
        align: "center",
        width: "150px",
      },
      {
        title: "商家",
        dataIndex: "trade_company_name",
        align: "center",
        width: "150px",
      },
      {
        title: "商铺",
        dataIndex: "trade_company_retail_shop_name",
        align: "center",
        render: (text) => (
          <Tooltip title={text}>
            <div className="ellipsis" style={{ width: "80px" }}>
              {text || "-"}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "现金支付",
        dataIndex: "cash_amount",
        align: "center",
        width: "150px",
        render: (text) => (text ? Number(text).toFixed(2) : 0),
      },
    ];

    const pagination = {
      pageSize: searchCond.page_size,
      total: totalNum,
      showQuickJumper: true,
      current: searchCond.page,
      showTotal: (total, range) => {
        return (
          <span>
            目前显示{range[0]}-{range[1]} 条,共 {total} 条
          </span>
        );
      },
    };

    return (
      <div
        style={{ margin: 24, padding: 24, background: "#fff", minHeight: 360 }}
      >
        <CheckBox
          searchList={searchList}
          searchSubmit={this.searchSubmit}
          fields={searchCond}
          searchReset={this.searchReset}
        />
        <Table
          rowKey={(record, index) => record.co_id}
          columns={columns}
          pagination={pagination}
          dataSource={dataList}
          onChange={this.handleTableChange}
          className="mt15"
        />
      </div>
    );
  }
}
