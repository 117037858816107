export const data = [
    {
     "case_no": "100090920231107000100017C6",
     "case_state": 5,
     "pay_amount": 104,
     "need_pay_amount": 104,
     "actual_pay_amount": 104,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C1",
     "case_state": 5,
     "pay_amount": 104,
     "need_pay_amount": 104,
     "actual_pay_amount": 104,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C2",
     "case_state": 3,
     "pay_amount": 14,
     "need_pay_amount": 14,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C4",
     "case_state": 3,
     "pay_amount": 104,
     "need_pay_amount": 104,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试二",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C5",
     "case_state": 5,
     "pay_amount": 14,
     "need_pay_amount": 14,
     "actual_pay_amount": 14,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C5A",
     "case_state": 5,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 10,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8",
     "case_state": 3,
     "pay_amount": 14.4,
     "need_pay_amount": 14.4,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "DingDang001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8A",
     "case_state": 3,
     "pay_amount": 5.6,
     "need_pay_amount": 5.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8B",
     "case_state": 3,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8_1",
     "case_state": 3,
     "pay_amount": 14.4,
     "need_pay_amount": 14.4,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "DingDang001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8_2",
     "case_state": 3,
     "pay_amount": 5.6,
     "need_pay_amount": 5.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C8_3",
     "case_state": 3,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C9_1",
     "case_state": 3,
     "pay_amount": 41.6,
     "need_pay_amount": 41.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C2_1",
     "case_state": 3,
     "pay_amount": 14,
     "need_pay_amount": 14,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C5_1",
     "case_state": 3,
     "pay_amount": 44,
     "need_pay_amount": 44,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试四",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C5_2",
     "case_state": 3,
     "pay_amount": 50,
     "need_pay_amount": 50,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试四",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C4_1",
     "case_state": 3,
     "pay_amount": 44,
     "need_pay_amount": 44,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试3",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C10_1",
     "case_state": 3,
     "pay_amount": 14,
     "need_pay_amount": 14,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C6_1",
     "case_state": 3,
     "pay_amount": 36,
     "need_pay_amount": 36,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试3",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C7_1",
     "case_state": 3,
     "pay_amount": 8,
     "need_pay_amount": 8,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试3",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C7_2",
     "case_state": 3,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试3",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C7_3",
     "case_state": 3,
     "pay_amount": 20,
     "need_pay_amount": 20,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试3",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C12_3",
     "case_state": 3,
     "pay_amount": 9.6,
     "need_pay_amount": 9.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C12_4",
     "case_state": 3,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C13_3",
     "case_state": 5,
     "pay_amount": 5.6,
     "need_pay_amount": 5.6,
     "actual_pay_amount": 5.6,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C13_4",
     "case_state": 5,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 10,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C13_5",
     "case_state": 5,
     "pay_amount": 20,
     "need_pay_amount": 20,
     "actual_pay_amount": 20,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231108000100008C10_1",
     "case_state": 3,
     "pay_amount": 0.4,
     "need_pay_amount": 0.4,
     "actual_pay_amount": 0,
     "accident_name": "测试3",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C14_3",
     "case_state": 5,
     "pay_amount": 5.6,
     "need_pay_amount": 5.6,
     "actual_pay_amount": 5.6,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C14_4",
     "case_state": 5,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 10,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C15_1",
     "case_state": 3,
     "pay_amount": 9.6,
     "need_pay_amount": 9.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C15_2",
     "case_state": 3,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C16_1",
     "case_state": 3,
     "pay_amount": 45.6,
     "need_pay_amount": 45.6,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C16_2",
     "case_state": 3,
     "pay_amount": 20,
     "need_pay_amount": 20,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "100090920231107000100017C17_1",
     "case_state": 3,
     "pay_amount": 2.4,
     "need_pay_amount": 2.4,
     "actual_pay_amount": 0,
     "accident_name": "测试4",
     "rpter_name": "测试4",
     "policy_no": "DingDang001",
     "cr_name": "测试保单1",
     "unit_name": "测试投保单位1"
    },
    {
     "case_no": "10009020231110000100001_1",
     "case_state": 5,
     "pay_amount": 15,
     "need_pay_amount": 15,
     "actual_pay_amount": 15,
     "accident_name": "小明",
     "rpter_name": "小明",
     "policy_no": "DingDang001",
     "cr_name": "测试保单2",
     "unit_name": "测试投保单位2"
    },
    {
     "case_no": "10009020231110000100001_2",
     "case_state": 5,
     "pay_amount": 6,
     "need_pay_amount": 6,
     "actual_pay_amount": 6,
     "accident_name": "小明",
     "rpter_name": "小明",
     "policy_no": "DingDang001",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "10009020231110000100001_3",
     "case_state": 5,
     "pay_amount": 10,
     "need_pay_amount": 10,
     "actual_pay_amount": 10,
     "accident_name": "小明",
     "rpter_name": "小明",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231110000100001_4",
     "case_state": 5,
     "pay_amount": 4,
     "need_pay_amount": 4,
     "actual_pay_amount": 4,
     "accident_name": "小明",
     "rpter_name": "小明",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "10009020231110000100001_5",
     "case_state": 5,
     "pay_amount": 20,
     "need_pay_amount": 20,
     "actual_pay_amount": 10,
     "accident_name": "小明",
     "rpter_name": "小明",
     "policy_no": "20231025_001",
     "cr_name": "测试保单",
     "unit_name": "测试投保单位"
    },
    {
     "case_no": "testcn001_001",
     "case_state": 5,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 65,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn001_002",
     "case_state": 5,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 65,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_002",
     "case_state": 3,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_001",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_003",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_004",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_005",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno",
     "cr_name": "测试保单3",
     "unit_name": "测试投保单位3"
    },
    {
     "case_no": "testcn002_006",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_001",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_002",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_003",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_004",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_005",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_006",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_008",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    },
    {
     "case_no": "testcn003_009",
     "case_state": 1,
     "pay_amount": 65,
     "need_pay_amount": 65,
     "actual_pay_amount": 0,
     "accident_name": "杰瑞",
     "rpter_name": "汤姆",
     "policy_no": "testclaimno1",
     "cr_name": "测试保单4",
     "unit_name": "测试投保单位4"
    }
   ]