import { getOpenKeys } from '@/utils/utils';
import router from 'umi/router';
import { getMenuFn } from '@/layout/SiderMenu/menu.js'
import { message } from 'antd';
import _ from 'lodash'
import Config from '@/utils/config'
import { menu } from '@/layout/SiderMenu/menu'

export default {
  namespace: 'global',
  state: {
    collapsed: false,
    login: false,
    openKeys: [],
    selectedKeys: ['home'],
    medCode: null,
    userMenu: [],
    tipData: {
      "audit_count": 0,
      "task_count": 0,
    },
    authFileCenter: false,
    showQrcode:false
  },
  effects: {
    // *logout({ _ }, { call, put }) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   yield put({
    //     type: 'changeLoginState',
    //     payload: false
    //   })
    //   router.push('/login');
    // },
  },
  reducers: {
    changeLoginState(state, { payload }) {
      return {
        ...state,
        login: payload
      }
    },
    changeKeys(state, { payload }) {
      const pathArry = payload.split('/');
      let keys = state.openKeys;
      let selectKeys = [];
      if (window.location.href.indexOf('/platform') > -1) {
        getOpenKeys(keys,pathArry)
        selectKeys.push(payload.substring(1, payload.length));
      }else{
        // if (!keys.includes(pathArry[1])) {
          keys.push(pathArry[1])
          selectKeys.push(pathArry[1]);
        // }
      }
      return {
        ...state,
        openKeys: keys,
        selectedKeys: selectKeys,
      }
    },
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        const mainEle = document.getElementsByClassName('ant-layout-content');
        if (mainEle.length) {
          mainEle[0].scrollTo(0, 0)
        }
        dispatch({ type: 'changeKeys', payload: pathname });
        // 设置登录过期
        // if (localStorage.getItem('token') && !getCookie('LBToken')) { // 排除未登录状态
        //   localStorage.clear()
        // }
      })
    }
  }
};