// import {
//   getStoreState,
// } from '@/service/cooperation';

const namespace = 'service';
export default {
  namespace,
  state: {
    authInfo: 0,
  },

  effects: {
    // *fetchStoreState({ payload }, { call, put, select }) {
    //   const rsp = yield call(getStoreState, payload);
    //   if (rsp) {
    //     yield put({
    //       type: 'set',
    //       payload: {
    //         authInfo: rsp,
    //       },
    //     })
    //   }
    // },
  },

  reducers: {
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
};
