import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table } from 'antd'
import { connect } from 'dva';
import router from "umi/router";
import moment from 'moment';
// import AddProposalForm from './addProposalForm';
import _ from 'lodash';
import ServiceConfigModal from './serviceConfigModal';

const namespace = 'proposalFormInfo';
@connect(({ proposalFormInfo, loading }) => ({ proposalFormInfo }))
export default class ProposalFormInfo extends Component {
  state = {
    showAddModal: false,
    currentRecord: {},
    isOnlyShow: false,
  }

  componentDidMount() {
    this.props.dispatch({
      type: `${namespace}/fetchInsureUnitList`
    })
    this.props.dispatch({
      type: `${namespace}/fetchList`
    })
  }

  searchSubmit = data => {
    const { searchCond } = this.props[namespace];
    Object.entries(data).forEach(([k, v]) => {
      if (k === 'time') {
        if (v && v.length > 0) {
          searchCond.start_time = `${moment(v[0]).format('YYYY-MM-DD')} 00:00:00`
          searchCond.end_time = `${moment(v[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          delete searchCond.start_time
          delete searchCond.end_time
        }
      } else {
        searchCond[k] = v;
      }
    });
    searchCond.page = 1;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: searchCond,
    });
    this.props.dispatch({
      type: `${namespace}/fetchList`
    });
  }
  handleTableChange = pagination => {
    const { searchCond } = this.props[namespace];
    searchCond.size = pagination.pageSize;
    searchCond.page = pagination.current;
    this.props.dispatch({
      type: `${namespace}/set`,
      payload: {
        searchCond,
      },
    });
    this.props.dispatch({
      type: `${namespace}/fetchList`,
    });
  };

  showOrCloseAddModal = (value, record) => {
    this.setState({
      showAddModal: value,
      currentRecord: record ? record : {},
      //   isOnlyShow: record && record.cnt > 0
      isOnlyShow: true
    })
  }

  render() {
    const { searchCond, totalNum, dataList, insuranceList, insureUnitList } = this.props[namespace]
    const { showAddModal, currentRecord, isOnlyShow } = this.state
    const roleId = localStorage.getItem("roleId") || "";
    const diseaseTypeJson = {
      'ZHYF': '智慧员福-保险',
      'ZHYF_QY': '智慧员福-权益卡',
      'ZHYF_FUND': '智慧员福-基金活化',
    }
    const searchList = [
      { key: "policy_no", type: "INPUT", placeholder: "保单号", width: '220px' },

      {
        key: "unit_name",
        type: "INPUT",
        placeholder: "投保单位",
        width: '200px',
      },
      {
        key: "time",
        name: '保单起止日期',
        type: "RANGEDATE",
        width: '280px',
        placeholder: ['开始时间', '结束时间'],
        rangeDateLabel: { firstTime: 'start_time', secondTime: 'end_time' }
      }
    ]
    const columns = [
      { title: '保单号', dataIndex: 'policy_no', align: 'center' },
      { title: '保单名称', dataIndex: 'cr_name', align: 'center' },
      { title: '保险公司', dataIndex: 'agt_name', align: 'center' },
      { title: '投保单位', dataIndex: 'unit_name', align: 'center' },
      {
        title: '产品类型', dataIndex: 'disease_type', align: 'center', render: text => (
          <span>{diseaseTypeJson[text]}</span>
        )
      },
      { title: '公共理赔额度', dataIndex: 'public_amount', align: 'center' },
      { title: '公共冻结金额', dataIndex: 'frozen_public_amount', align: 'center' },
      { title: '投保人数', dataIndex: 'cnt', align: 'center' },
      { title: '投保日期', dataIndex: 'insure_date', align: 'center' },
      { title: '到期日期', dataIndex: 'validity_date', align: 'center' },
      {
        colSpan: roleId == 8 ? 0 : 1,
        title: '操作', dataIndex: 'x', align: 'center', width: roleId == 8 ? 0 : 150, fixed: 'right', render: (_, record) => (
          roleId != 8 ? (<span>
            {/* <Button type='primary' onClick={()=>this.showOrCloseAddModal(true,record)}>{record.cnt > 0 ? '查看':'编辑'}</Button> */}

            <Button
              type='primary'
              className='ml15 mr15'
              onClick={() => {
                router.push({
                  pathname: '/platform/basicModule/proposalFormManagement/policyholder',
                  state: {
                    cr_id: record.cr_id,
                    cr_name: record.cr_name
                  },
                })
              }}
            >查看投保人</Button>
            <Button type='primary' className='mt15' onClick={()=>this.showOrCloseAddModal(true,record)}>服务商配置</Button>
          </span>) : ''
        )
      },
    ]
    const pagination = {
      pageSize: searchCond.size,
      total: totalNum,
      showQuickJumper: true,
      current: searchCond.page,
      showTotal: (total, range) => {
        return (
          <span>
            目前显示{range[0]}-{range[1]} 条,共 {total} 条
          </span>
        );
      },
    }
    return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
      <div className='mb15'>
        <CheckBox
          searchList={searchList}
          searchSubmit={this.searchSubmit}
          fields={searchCond}
        />
      </div>
      <Table
        rowKey={record => record.cr_id}
        pagination={pagination}
        columns={columns}
        dataSource={dataList}
        onChange={this.handleTableChange}
        scroll={{ x: true }}
      />
      {showAddModal && <ServiceConfigModal currentRecord={currentRecord} onCancel={()=>this.showOrCloseAddModal(false)} />}
    </div>
  }
}