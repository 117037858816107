import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取招聘列表
export async function getRecruitList(params) {
    return Gateway.post(`${Config.GatewayAdmin}/website/home/recruit/list`, params)
}
// 招聘详情
export async function getRecruitDetail(params ) {
    return Gateway.get(`${Config.GatewayAdmin}/website/home/recruit/detail`,params)
}