import React from 'react';
export function toArrayChildren(children) {
  var ret = [];
  React.Children.forEach(children, function (c) {
    ret.push(c);
  });
  return ret;
}
export function findChildInChildrenByKey(children, key) {
  var ret = null;

  if (children) {
    children.forEach(function (c) {
      if (ret || !c) {
        return;
      }

      if (c.key === key) {
        ret = c;
      }
    });
  }

  return ret;
}
export function mergeChildren(prev, next) {
  var ret = []; // For each key of `next`, the list of keys to insert before that key in
  // the combined list

  var nextChildrenPending = {};
  var pendingChildren = [];
  var followChildrenKey = null;
  prev.forEach(function (c) {
    if (!c) {
      return;
    }

    if (c.key && findChildInChildrenByKey(next, c.key)) {
      if (pendingChildren.length) {
        nextChildrenPending[c.key] = pendingChildren;
        pendingChildren = [];
      }

      followChildrenKey = c.key;
    } else if (c.key) {
      pendingChildren.push(c);
    }
  });

  if (!followChildrenKey) {
    ret = ret.concat(pendingChildren);
  }

  next.forEach(function (c) {
    if (!c) {
      return;
    }

    if (c.key && nextChildrenPending.hasOwnProperty(c.key)) {
      // eslint-disable-line no-prototype-builtins
      ret = ret.concat(nextChildrenPending[c.key]);
    }

    ret.push(c);

    if (c.key === followChildrenKey) {
      ret = ret.concat(pendingChildren);
    }
  });
  return ret;
}
export function transformArguments(arg, key, i) {
  var result;

  if (typeof arg === 'function') {
    result = arg({
      key: key,
      index: i
    });
  } else {
    result = arg;
  }

  return result;
}
export function getChildrenFromProps(props) {
  return props && props.children;
}