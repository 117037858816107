import Config from "@/utils/config";
import Gateway from "@/utils/Gateway";

// 投保单位下的服务商
export async function getServiceInsureList(params) {
  return Gateway.post(`${Config.GatewayPlatform}/service/insure/list`, params);
}

// 获取服务商对接配置 key secret 对接文档
export async function getServiceConfig(params) {
  return Gateway.get(
    `${Config.GatewayPlatform}/config/getServiceConfig`,
    params
  );
}
