import React, { Component } from 'react'
import { Carousel } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import Certification from '../home/insurance/certification'
import style from './index.less'
import FlyInAnimation from '../../component/flyInAnimation'
import { introData, socialData1, processImgs, socialData2, advantageData } from './data'
TweenOne.plugins.push(Children);

export default class Join extends Component {
    constructor(props) {
		super(props);
		this.state = {
            tab: 1,
            tabList: [
                { id: 1, name: '公司介绍', eleId: 'intro' },
                { id: 2, name: '企业文化', eleId: 'culture' },
                { id: 3, name: '发展历程', eleId: 'process' },
                { id: 4, name: '核心优势', eleId: 'advantage' },
                { id: 5, name: '荣誉资质', eleId: 'certification' },
                { id: 6, name: '社会担当', eleId: 'society' },
            ],
            animation: [[{}, {}], [{}, {}], [{}, {}], [{}, {}]],
            active1: false,
            active2: [false, false, false, false, false, false],
            active2TimerDisable: false,
            showCertification: false,
            active3: [false, false],
            active4: [false, false],
            active5: [false, false, false],
            autoplay: false,
        }
		this.carouselRef1 = React.createRef();
		this.carouselRef2 = React.createRef();
	}
    
    componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        this.activeAnimation(pos.top)
    }
    activeAnimation = (positionY) => {
        const { active2TimerDisable, active2, active3, active4, active5 } = this.state
        if (positionY < -270) {
            const animationList = introData.map(item => {
                return [{ Children: { value: item.value1, floatLength: 0 }, duration: 2000 }, { Children: { value: item.value2, floatLength: 0 }, duration: 2000 }]
            })
            this.setState({
                animation: animationList,
                tab: 1,
            })
        }
        if (positionY < -1000) {
            this.setState({
                active1: true,
                tab: 2,
            })
        }
        if (positionY < -1970) {
            this.setState({ tab: 3 })
            if (!active2TimerDisable) {
                let index = 0
                this.setState({ active2TimerDisable: true })
                const timer = setInterval(() => {
                    active2[index] = true
                    index++
                    this.setState({ active2 })
                }, 600);
                setTimeout(() => {
                    clearInterval(timer)
                }, 3600);
            }
        }
        if (positionY < -2870) {
            active3[0] = true
            this.setState({active3,tab: 4})
            setTimeout(()=>{
                active3[1] = true
                this.setState({active3})
                // setTimeout(() => {
                //     this.setState({autoplay:true})
                // }, 1000);
            },1000)
        }
        if (positionY < -3720) {
            this.setState({ showCertification: true,tab: 5 })
        }
        if (positionY < -5170) {
            active4[0] = true
            this.setState({ active4,tab: 6 })
            setTimeout(() => {
                active4[1] = true
                this.setState({ active4 })
            }, 1000);
        }
        if (positionY < -5570) {
            active5[0] = true
            this.setState({ active5 })
            setTimeout(() => {
                active5[1] = true
                this.setState({ active5 })
                setTimeout(() => {
                    active5[2] = true
                    this.setState({ active5 })
                }, 1000);
            }, 1000);
        }

    }
    changeTab = (item) => {
        this.setState({ tab: item.id })
        this.scrollToAnchor(item.eleId)
    }
    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView(true)
            }
        }
    }
    render() {
        const { tabList, tab, animation, active1, active2, active3, showCertification, active4, active5,autoplay } = this.state
        return <div className={style.wrapper}>
            <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
                <div className={style.desc}>
                    <div className={style.title}>关于老白</div>
                    <div className={style.text}>数字化医药福利管理企业</div>
                </div>
                <img src={require("@/assets/about_us/top.png")} alt="" />
            </div>
            <div className={style.tabWrappr}>
                <div className={style.tabBox}>
                    {tabList.map(item => <div key={item.id} onClick={() => { this.changeTab(item) }} className={tab == item.id ? `${style.tabBtn} ${style.active}` : style.tabBtn}>{item.name}</div>)}
                </div>
            </div>
            <div style={{ width: '100%', backgroundColor: '#fff' }} id='intro'>
                <div className={style.box}>
                    <div className='ta-c fs34 color111'>公司介绍</div>
                    <div className='fs14 mt40'>
                        <div className='mb20'>
                            海南老白健康科技有限公司（简称“老白健康”）是我国数字化医药福利管理业务的开创者，是一家涵盖“智慧员福”、“商保好药”、“普惠好药”三大核心业务的“数宇化医药福利管理”综合服务平台，提供保险创新方案设计、医药供应链整体解决方案、互联网医疗与健康管理等服务，具备为合作伙伴提供医药险整体解决方案的能力和覆盖全国的销售推动和服务能力。
                        </div>
                        <div className='mb20'>
                            老白健康的使命是增强我国多层次医疗药品保障的可及性与均衡性，助力用户享受健康快乐的生活。老白健康的愿景是成为全世界最受尊敬的医药福利管理企业，为每个中国家庭提供健康服务。其核心业务是数宇化医药福利管理，通过商保集采和互联网医药服务，提升医疗资源的合理分配，优化医疗服务质量，控制医疗费用和负担，促进健康管理和疾病预防。这种服务广泛应用于商业健康保险领域，大大提高了被保险人的获得感、幸福感和安全感，进一步增进了民生福祉。
                        </div>
                        <div className='mb20'>
                            当前，老白健康的服务已在大企业员工福利、中小企业二次开发、新市民、乡村振兴、家庭和个人客户等业务场景形成了丰富的案例，得到了合作伙伴的一致认可。截至目前，老白健康已与超过60家保险公司总公司建立了合作关系，服务客户单位超2000家，服务用户数超200万。
                        </div>
                        <div>
                            未来，老白健康将始终坚持以习近平新时代中国特色社会主义思想为指导，以超越用户需求为目标，通过创新引领行业变革，不断增强我国多层次医疗保障服务的可及性与均衡性，减轻用户购药负担，提高用户生活品质，增强民生福祉。
                        </div>
                    </div>
                    <div className={`${style.introList} flexbet`}>
                        <div className={style.introItem}>
                            <img src={require('@/assets/about_us/intro1.png')} alt="" />
                            <div className='mt20'>合作<span className='color0661C9 fs24'><TweenOne animation={animation[0][0]} style={{ display: 'inline' }} />+</span>头部药企</div>
                            <div>目录覆盖<span className='color0661C9 fs24'><TweenOne animation={animation[0][1]} style={{ display: 'inline' }} />+</span>sku</div>
                        </div>
                        <div className={style.introItem}>
                            <img src={require('@/assets/about_us/intro2.png')} alt="" />
                            <div className='mt20'>合作保险公司总部<span className='color0661C9 fs24'><TweenOne animation={animation[1][0]} style={{ display: 'inline' }} />+</span></div>
                            <div>合作保险公司省分公司<span className='color0661C9 fs24'><TweenOne animation={animation[1][1]} style={{ display: 'inline' }} />+</span></div>
                        </div>
                        <div className={style.introItem}>
                            <img src={require('@/assets/about_us/intro3.png')} alt="" />
                            <div className='mt20'>合作互联网医院<span className='color0661C9 fs24'><TweenOne animation={animation[2][0]} style={{ display: 'inline' }} />+</span></div>
                            <div>累计处方订单<span className='color0661C9 fs24'><TweenOne animation={animation[2][1]} style={{ display: 'inline' }} /></span>单</div>
                        </div>
                        <div className={style.introItem}>
                            <img src={require('@/assets/about_us/intro4.png')} alt="" />
                            <div className='mt20'>服务用户人数<span className='color0661C9 fs24'><TweenOne animation={animation[3][0]} style={{ display: 'inline' }} />万+</span></div>
                            <div>帮助节约医疗费用<span className='color0661C9 fs24'><TweenOne animation={animation[3][1]} style={{ display: 'inline' }} />万+</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <img src={require('@/assets/about_us/img1.png')} style={{ width: '100%',paddingTop:71,marginTop:-71 }} alt="" id='culture' />
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <div className={style.box} style={{ padding: '60px 0 30px 0' }}>
                    <div className='color0661C9 fs28'>公司使命</div>
                    <div className={style.divider} />
                    <div className='fs20 color333 mb40'>增强我国多层次医疗保障服务的可及性与均衡性，帮助人民享受健康美好的生活</div>
                    <div className='color0661C9 fs28'>公司愿景</div>
                    <div className={style.divider} />
                    <div className='fs20 color333 mb40'>成为全世界最值得尊敬的医药福利管理企业，到2030年，服务每一个中国家庭</div>
                    <div className='color0661C9 fs28'>“北斗七星”价值观</div>
                    <div className={style.divider} />
                </div>
            </div>
            <div style={{ width: '100%', backgroundColor: '#F9F9F9' }}>
                <div className={style.box} style={{ padding: '60px 0' }}>
                    < FlyInAnimation show={active1}>
                        <img src={require('@/assets/about_us/values.png')} className={style.imgHidden} alt="" />
                    </FlyInAnimation>
                </div>
            </div>
            <img src={require('@/assets/about_us/img2.png')} style={{ width: '100%',paddingTop:71,marginTop:-71 }} alt="" id='process' />
            <div className={style.processWrapper}>
                <div className={style.devDivider} />
                <div className={style.processContent}>
                    {processImgs.map((item, index) =>
                        <FlyInAnimation key={index} show={active2[index]}>
                            <img className={style.img} style={{ zIndex: index + 100, left: index * 190 }} src={item} alt="" />
                        </FlyInAnimation>
                    )}
                </div>
            </div>
            <img src={require('@/assets/about_us/img3.png')} style={{ width: '100%',paddingTop:71,marginTop:-71 }} alt="" id='advantage' />
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <div className={style.box} style={{ padding: '60px 0' }}>
                    <div className={style.carouselIcon}>
                        <LeftCircleOutlined style={{cursor:'pointer'}} onClick={()=>{this.carouselRef1.current.prev();this.carouselRef2.current.prev()}}/>
                        <RightCircleOutlined style={{cursor:'pointer'}} onClick={()=>{this.carouselRef1.current.next();this.carouselRef2.current.next()}}/>
                    </div>
                    <div className={style.caseItem}>
                            <FlyInAnimation show={active3[0]}>
                                <div style={{width:660,opacity:0}}>
                                    <Carousel autoplay ref={this.carouselRef1} dots={false}>
                                        {advantageData.map((item,index)=><img style={{verticalAlign:'bottom'}} key={index} src={item.img} alt="" />)}
                                    </Carousel>
                                </div>
                            </FlyInAnimation>
                            <FlyInAnimation show={active3[1]}>
                                <div className={style.descriptionHidden}>
                                    <div className={style.descriptionWrapper}>
                                        <Carousel autoplay ref={this.carouselRef2} >
                                            {advantageData.map((item,index)=>
                                                <div key={index} className={style.description}>
                                                    <div className='fs24 mb10'>{item.title}</div>
                                                    <div className='fs16'>{item.content}</div>
                                                </div>
                                            )}
                                        </Carousel>
                                    </div>
                                </div>
                            </FlyInAnimation>
                    </div>
                </div>
            </div>
            <img src={require('@/assets/about_us/img4.png')} style={{ width: '100%',paddingTop:71,marginTop:-71 }} alt="" id='certification' />
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <div className={style.box} style={{ padding: '60px 0', height: 1467 }}>
                    {showCertification && <Certification />}
                </div>
            </div>
            <img src={require('@/assets/about_us/img5.png')} style={{ width: '100%',paddingTop:71,marginTop:-71 }} alt="" id='society' />
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <div className={style.box} style={{ padding: '60px 0' }}>
                    <div className='flexbt'>
                        {socialData1.map((item, index) =>
                            <FlyInAnimation key={index} show={active4[index]}>
                                <div className={style.socialItem}>
                                    <div className='mb25 fwbold'>{item.title}</div>
                                    <img src={item.img} alt="" />
                                    <div className={style.maskItem}>
                                        <div className='fwbold' style={{ margin: '50px 0 28px 0' }}>{item.title}</div>
                                        <div className='fs16' style={{textAlign:'justify'}}>{item.maskContent}</div>
                                    </div>
                                </div>
                            </FlyInAnimation>
                        )}
                    </div>
                    <div className='flexbt' style={{ marginTop: 4 }}>
                        {socialData2.map((item, index) =>
                            <FlyInAnimation key={index} show={active5[index]}>
                                <div className={style.socialItem}>
                                    <div className='mb25 fwbold'>{item.title}</div>
                                    <img src={item.img} alt="" />
                                    <div className={style.maskItem}>
                                        <div className='fwbold' style={{ marginBottom: 18 }}>{item.title}</div>
                                        <div className='fs16' style={{textAlign:'justify'}}>{item.maskContent}</div>
                                    </div>
                                </div>
                            </FlyInAnimation>
                        )}
                    </div>
                </div>
            </div>
        </div >
    }
}