import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取quick-bi生成的url
export async function getShareUrl(params) {
    return Gateway.get(`${Config.GatewayPlatform}/ali/createShareUrl`, params)
}

// 获取dashboard生成的url
export async function getDashUrl(params) {
    return Gateway.get(`${Config.GatewayPlatform}/dashboard/dashurl`, params)
}

