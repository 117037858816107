import style from "./index.less";
import TweenOne from "rc-tween-one";
import Children from "rc-tween-one/lib/plugin/ChildrenPlugin";
import { drugInfoList } from "./data.js";

TweenOne.plugins.push(Children);
const data = [260, 15, 350, 2600];
function medicinalService() {
  return (
    <div className={style.wrapper}>
      <div className={style.banner} style={{ height: "auto", width: "100%" }}>
        <div className={style.desc}>
          <div className={style.title}>药品服务</div>
        </div>
        <img src={require("@/assets/medical.png")} />
      </div>
      <div className={style.infoContent}>
        <div className={style.wrapper}>
          {data.map((item, num) => (
            <div key={num} className={style.item}>
              <span>
                <TweenOne
                  animation={{
                    Children: {
                      value: item,
                      floatLength: 0,
                    },
                    duration: 2000,
                  }}
                  style={{ display: "inline" }}
                />
              </span>
              +
            </div>
          ))}
        </div>
      </div>
      <div className={style.qrCode}>
        <div className={style.qrCodeItem}>
          <img src={require("@/assets/medical/qrCode.png")} />
          <div>智慧员福</div>
        </div>
        <div className={style.qrCodeItem}>
          <img src={require("@/assets/medical/qrCode1.png")} />
          <div>商保好药</div>
        </div>
        <div className={style.qrCodeItem}>
          <img src={require("@/assets/medical/qrCode2.png")} />
          <div>普惠好药</div>
        </div>
      </div>
      <div className={style.medicalShow}>
        <div className={style.medicalShowItem}>
          <div className={style.title}>部分合作药企展示</div>
          <img src={require("@/assets/medical/enterprise.png")} />
        </div>
      </div>
      <div className={style.medicalKey}>
        <div className={style.title}>部分重点品种展示</div>
        <div className={style.content}>
          {drugInfoList.map((item) => {
            return (
              <div className={style.contentItem}>
                <img src={item.url} style={{ width: "75%" }} />
                <div>{item.name}</div>
              </div>
            );
          })}
        </div>
        {/* <img src={require("@/assets/medical/enterprise.png")} /> */}
      </div>
    </div>
  );
}
export default medicinalService;
