import { message } from 'antd';
import {
    getNewsList,
    getNewsDetail,
    getVideoList,
    getVideoDetail,
    updateLike,
} from '@/service/news'

export default {
    namespace: 'news',
    state: {
        selectedKey: '1',
        newsSearchCond: {
            page: 1,
            page_size: 6,
        },
        newsDataList: [],
        newsTotalNum: 0,
        videoSearchCond: {
            page: 1,
            page_size: 15,
        },
        videoDataList: [],
        videoTotalNum: 6,
    },
    effects: {
        *fetchNewsList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.news.newsSearchCond);
            const { items, count } = yield call(getNewsList, searchCond);
            yield put({
                type: "set",
                payload: {
                    newsDataList: items,
                    newsTotalNum: count,
                },
            });
        },
        *fetchNewsDetail({ payload }, { call, put, select }) {
            const res = yield call(getNewsDetail, payload);
            return res
        },

        *fetchVideoList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.news.videoSearchCond);
            const { items, count } = yield call(getVideoList, searchCond);
            yield put({
                type: "set",
                payload: {
                    videoDataList: items,
                    videoTotalNum: count,
                },
            });
        },
        *fetchVideoDetail({ payload }, { call, put, select }) {
            const res = yield call(getVideoDetail, payload);
            return res
        },
        *fetchChangeLike({ payload }, { call, put, select }) {
            const res = yield call(updateLike, payload);
            return res
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};