export enum ROLE_TYEP {
  /** 社会组织 Social organization*/
  SOCIAL_ORGANIZATION = 'SOCIAL_ORGANIZATION',
  /** 保险公司-个险 Individual*/
  INSURANCE_INDIVIDUAL = 'INSURANCE_INDIVIDUAL',
  /** 保险公司-团险*/
  INSURANCE_GROUP = 'INSURANCE_GROUP',
  /** 企业人资*/
  COMPANY_HR = 'COMPANY_HR',
  /** 药企*/
  PHARMACY = 'PHARMACY',
}

export const ROLE_ENUM_TO_CHINESE = {
  [ROLE_TYEP.SOCIAL_ORGANIZATION]: '社会组织',
  [ROLE_TYEP.INSURANCE_INDIVIDUAL]: '保险公司-个险',
  [ROLE_TYEP.INSURANCE_GROUP]: '保险公司-团险',
  [ROLE_TYEP.COMPANY_HR]: '企业人资',
  [ROLE_TYEP.PHARMACY]: '药企',
}

export const ROLE_ID_ENUM_TO_CHINESE = {
  1: '社会组织',
  2: '保险公司-个险',
  3: '保险公司-团险',
  4: '企业人资',
  5: '药企',
}

export enum PRODUCT_CENTER_DETAIL {
  /** 保险公司   直付理赔 */
  ZHIFULIPEI = 'Zhifulipei',
  /** 保险公司   拍照理赔 */
  PAIZHAOLIPEI = 'Paizhaolipei',
  /** 保险公司   TPA理赔 */
  TPALIPEI = 'TPAlipei',
  /** 保险公司   互联网门诊 */
  HULIANWANGMENZHEN = 'Hulianwangmenzhen',
  /** 保险公司   智慧员福 */
  ZHIHUIYUANFU = 'Zhihuiyuanfu',
  /** 保险公司   惠亿保 */
  HUIYIBAO = 'Huiyibao',
  /** 保险公司   弹性福利平台 */
  TANXINGFULIPINGTAI = 'Tanxingfulipingtai',
  /** 保险公司   在线问诊 */
  ZAIXIANWENZHEN = 'Zaixianwenzhen',
  /** 保险公司   O2O送药上门 */
  O2OSONGYAOSHANGMEN = 'O2Osongyaoshangmen',
  /** 保险公司   健康管理 */
  JIANKANGGUANLI = 'Jiankangguanli',
  /** 保险公司   消费医疗 */
  XIAOFEIYILIAO = 'Xiaofeiyiliao',
  /** 保险公司   协助就医 */
  XIEZHUJIUYI = 'Xiezhujiuyi',
  /** 保险公司   三折购药 */
  SANZHEGOUYAO = 'Sanzhegouyao',
  /** 保险公司   健康宣教 */
  JIANKANGXUANJIAO = 'Jiankangxuanjiao',
  /** 药房   药品采购 */
  YAOPINGCAIGOU = 'Yaopingcaigou',
  /** 药房    直付购药 */
  ZHIFUGOUYAO = 'Zhifugouyao',
  /** 药房   O2O送药上门 */
  O2OOPHARMACY = 'O2OPharmacy',
  /** 药房   在线开方 */
  ZAIXIANKAIFANG = 'Zaixiankaifang',
}

export const materialTypeJson = {
  0: '未知',
  1: '开票明细',
  2: '理赔申请书',
  3: '消费明细',
  4: '电子发票',
  5: '诊疗票消费明细',
  6: '客户授权委托书',
  7: '电子处方',
  8: '复诊开方记录影像件',
  9: '专家咨询影像件',
  10: '单病种影像件',
  11: '身份证影像件正面',
  12: '电子病历',
  13: '随诊记录影像件',
  14: '身份证影像件反面',
  15: '用户签名'
}