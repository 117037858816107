import React, { Component } from 'react'
import router from 'umi/router'
import style from './index.less'
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import "swiper/swiper-bundle.min.css";
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
TweenOne.plugins.push(Children);
const data = [260, 60, 2000, 200, 250, 2600]
const bannerData = [
	{ key: '1', img: require('../../assets/home/homeBanner1.png'),link:'/service/contact?scroll=bottom'},
	{ key: '2', img: require('../../assets/home/homeBanner2.png'),link:'/joinUs'},
	{ key: '3', img: require('../../assets/home/homeBanner3.png'),link:'/medicinalService' },
	{ key: '4', img: require('../../assets/home/homeBanner4.png'),link:'/notice' },
]
export default class HomeBanner extends Component {
	state = {
		animation:[]
	} 
	componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        if (pos.top < -500) {
            const animationList = data.map(item => {
                return {
                    Children: {
                        value: item,
                        floatLength: 0,
                    },
                    duration: 2000,
                }
            })
            this.setState({
                animation: animationList
            })
        }
    }
	pageToDetail = (obj) => {
		router.push(obj.link)
	}
	render() {
		const { animation } = this.state
		return (
			<div className={style.productBanner} style={{ height: 'auto', width: '100%' }} >
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
					loop
					autoplay={{
						delay: 10000,
					}}
				>
					{bannerData.map(obj => <SwiperSlide key={obj.key}>
						<div className={`${style.staticBanner}`}>
							<img onClick={() => { this.pageToDetail(obj) }} style={{ width: '100%', cursor: 'pointer' }} src={obj.img} alt='' />
							{/* <img onClick={() => { this.pageToDetail(obj) }} style={{ width: '100%', cursor: 'pointer',borderTopLeftRadius:30,borderTopRightRadius:30 }} src={obj.img} alt='' /> */}
						</div>
					</SwiperSlide>)}
				</Swiper>
				<div className={style.infoContent}>
					<div className={style.wrapper}>
						{data.map((item, num) => <div key={num} className={style.item}><span>
						<TweenOne animation={animation[num]}
							style={{ display: 'inline',fontWeight:'bold' }}
							/>
						</span>{num === 3 ? '万' : ''}+</div>)}
					</div>
				</div>
			</div>
		)
	}
}