import React, { Component } from "react";
import style from './certification.less'
import FlyInAnimation from '../../../component/flyInAnimation'
const data1 = [
    require('@/assets/home/certification/1_1.png'),
    require('@/assets/home/certification/1_2.png'),
    require('@/assets/home/certification/1_3.png'),
    require('@/assets/home/certification/1_4.png'),
]
const data2 = [
    require('@/assets/home/certification/2_1.png'),
    require('@/assets/home/certification/2_2.png'),
    require('@/assets/home/certification/2_3.png'),
    require('@/assets/home/certification/2_4.png'),
    require('@/assets/home/certification/2_5.png'),
]
const data3 = [
    require('@/assets/home/certification/3_1.png'),
    require('@/assets/home/certification/3_2.png'),
    require('@/assets/home/certification/3_3.png'),
    require('@/assets/home/certification/3_4.png'),
    require('@/assets/home/certification/3_5.png'),
]

export default class Certification extends Component {
    state = {
        certificationTimerDisable: false,
        certificationActive: [false,false,false,false,false]
    }
    componentDidMount(){
        const { formType } = this.props
        const { certificationTimerDisable,certificationActive } = this.state
        // 服务资质动画
        const startPositon = formType === 'home' ? -4900 : -3650
        if ( !certificationTimerDisable) {
            let index = 0
            this.setState({ certificationTimerDisable: true })
            const timer = setInterval(() => {
                certificationActive[index] = true
                index++
                this.setState({ certificationActive })
            }, 600);
            setTimeout(() => {
                clearInterval(timer)
            }, 3000);
        }
    }
    render() {
        const { certificationActive } = this.state
        return (
            <div className={style.container}>
                <div className={style.list}>
                    {data1.map((item, index) =>
                        <FlyInAnimation key={index} show={certificationActive[index]}>
                            <img className={style.img} style={{ zIndex: index + 100, left: index * 272 }} src={item} alt="" />
                        </FlyInAnimation>)}
                </div>
                <div className={style.list}>
                    {data2.map((item, index) =>
                        <FlyInAnimation key={index} show={certificationActive[index]}>
                            <img key={index} className={style.img} style={{ zIndex: index + 100, left: index * 220 }} src={item} alt="" />
                        </FlyInAnimation>
                    )}
                </div>
                <div className={style.list}>
                    {data3.map((item, index) =>
                        <FlyInAnimation key={index} show={certificationActive[index]}>
                            <img key={index} className={style.img} style={{ zIndex: index + 100, left: index * 220 }} src={item} alt="" />
                        </FlyInAnimation>
                    )}
                </div>
            </div>
        );
    }
}
