import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import { routerRedux } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    redirect: '/home',
    exact: true,
    _title: '海南老白开放平台',
    _title_default: '海南老白开放平台',
  },
  {
    path: '/login',
    component: require('../../layout/Entry/index.jsx').default,
    exact: true,
    _title: '海南老白开放平台',
    _title_default: '海南老白开放平台',
  },
  {
    path: '/register',
    component: require('../../layout/Entry/index.jsx').default,
    exact: true,
    _title: '海南老白开放平台',
    _title_default: '海南老白开放平台',
  },
  {
    path: '/qualification',
    component: require('../qualification/index.jsx').default,
    exact: true,
    _title: '海南老白开放平台',
    _title_default: '海南老白开放平台',
  },
  {
    path: '/',
    component: require('../../layout').default,
    routes: [
      {
        path: '/home',
        component: require('../home/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/notice',
        component: require('../home/notice/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/service/contact',
        component: require('../service/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/service/suggestions',
        component: require('../service/contract/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/aboutus',
        component: require('../aboutUs/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/prod/detail',
        component: require('../drug/detail.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/news',
        component: require('../news/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/news/newsInfoDetail',
        component: require('../news/newsInfoDetail.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/news/videoDetail',
        component: require('../news/detailComponents.js').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/joinUs',
        component: require('../join/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/joinUs/detail',
        component: require('../join/detail.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/business',
        component: require('../business/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/medicinalService',
        component: require('../medicinalService/index.jsx').default,
        exact: true,
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
      {
        path: '/platform',
        routes: [
          {
            path: '/platform/index',
            component: require('../platform/platformIndex/index.js').default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/noPermission',
            component: require('../platform/error/index.js').default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/dataMoudle/healthManage',
            component: require('../platform/dataMoudle/healthManage.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/dataMoudle/proposalFormManage',
            component: require('../platform/dataMoudle/proposalFormManage.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/dataMoudle/expenseAndUserManage',
            component: require('../platform/dataMoudle/expenseAndUserManage.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/dataMoudle/claimManage',
            component: require('../platform/dataMoudle/claimManage.js').default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/insuranceManage/insuranceStaff',
            component: require('../platform/basicModule/insuranceManage/insuranceStaff/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/insuredUnitManagement/unitManagement',
            component: require('../platform/basicModule/insuredUnitManagement/unitManagement/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/insuredUnitManagement/unitStaff',
            component: require('../platform/basicModule/insuredUnitManagement/unitStaff/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path:
              '/platform/basicModule/proposalFormManagement/proposalFormInfo',
            component: require('../platform/basicModule/proposalFormManagement/proposalFormInfo/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/proposalFormManagement/policyholder',
            component: require('../platform/basicModule/proposalFormManagement/policyholder/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path:
              '/platform/basicModule/serviceProvidorManagement/organization',
            component: require('../platform/basicModule/serviceProvidorManagement/organization/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/drugOrderManage/expenseInfo',
            component: require('../platform/basicModule/drugOrderManage/expenseInfo/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/drugOrderManage/consumeDetail',
            component: require('../platform/basicModule/drugOrderManage/expenseInfo/consumeDetail.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/detailManage/claimDetail',
            component: require('../platform/basicModule/detailManage/claimDetail/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/detailManage/quotaDetail',
            component: require('../platform/basicModule/detailManage/quotaDetail/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/detailManage/TPADetail',
            component: require('../platform/basicModule/detailManage/TPADetail/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/detailManage/assuredDetail',
            component: require('../platform/basicModule/detailManage/assuredDetail.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/serviceManage/groupManageer',
            component: require('../platform/basicModule/serviceManage/groupManageer/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/basicModule/enterpriseManage/configuration',
            component: require('../platform/basicModule/enterpriseManage/configuration/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/businessCenter/claimOperateManage/claimSetting',
            component: require('../platform/businessCenter/claimOperateManage/claimSetting/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/report/quotaReport',
            component: require('../platform/report/quotaReport/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
          {
            path: '/platform/report/claimReport',
            component: require('../platform/report/claimReport/index.js')
              .default,
            exact: true,
            _title: '海南老白开放平台',
            _title_default: '海南老白开放平台',
          },
        ],
        _title: '海南老白开放平台',
        _title_default: '海南老白开放平台',
      },
    ],
    _title: '海南老白开放平台',
    _title_default: '海南老白开放平台',
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
