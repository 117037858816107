import { Menu } from 'antd';
import React, { Component } from 'react';
import { connect } from 'dva';
import router from 'umi/router';
import { getMenuFn } from '@/layout/SiderMenu/platformMenu.js'

@connect(({ global }) => ({
  global
}))
export default class PlatformIndex extends Component {

  componentDidMount() {
    let menuList = []
    this.parseTreeJson(getMenuFn(), menuList)
    if (menuList.length>0) {
      router.push('/' + menuList[0].menu_identify)
    }else{
      router.push('/platform/noPermission')
    }
  }
  parseTreeJson = (data, menuList) => {
    if (!data) return
    data.forEach(element => {
      if (element.children_menus && element.children_menus.length > 0) {
        this.parseTreeJson(element.children_menus, menuList)
      } else {
        menuList.push(element);
      }
    });
  }
  render = () => {
    return (
      <div></div>
    )
  }
}
