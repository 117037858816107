import { extend } from 'umi-request';
import { notification, message } from 'antd';
import router from 'umi/router';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = error => {
  const { response = {} } = error;
  let errortext = codeMessage[response.status] || response.statusText;
  const { status, url } = response;
  if (status === 401) {
    // errortext = '未登录或登录已过期，请重新登录。'
    // // @HACK
    // window.g_app._store.dispatch({
    //   type: 'global/logout',
    // });
    // sessionStorage.setItem('revoke', true);
    localStorage.removeItem("token");
    const returnUrl = window.location.href
    const host = window.location.origin

    window.location.href = `${host}/login/?returnUrl=${encodeURIComponent(returnUrl)}`
    return {}
  }
  const data = {
    rc: 1,
    msg: errortext,
    data: {}
  }
  return Promise.resolve(data);
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理 
});
/**
 * 请求拦截器加入token
 */
request.interceptors.request.use((url, options) => {
  options.headers = {
    ...options.headers,
    'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
  }

  return (
    {
      url: `${url}`,
      options: {
        ...options,
      },
    }
  );
});

//request.interceptors.response.use(async (response) => {
// let  data = await response.clone().json();
// return response;
//})

export default request;