import { message } from "antd";
import request from './request.js'
const Gateway = {};
/**
 * @param URL
 * @param input 同fetch-api
 * @param silence 是否静默错误！ true:则业务代码处理错误提示
 */
Gateway.execute = (path, input = {}, silence = false) => {
    return request(path, { ...input }).then((data) => {
        if (silence) {
            return data
        } else {
            if (data) {
                if (data.rc !== 0) {
                    message.error(data.msg)
                    console.error(data.msg)
                    return Promise.reject(data.msg ? data.msg : '接口异常')
                }
                return data.data
            }
        }
    });
}
Gateway.get = (path, params = {}, silence = false) => {
    return Gateway.execute(path, { params: params }, silence);
}
Gateway.post = (path, data = {}, silence = false) => {
    return Gateway.execute(path, { method: 'post', data: data }, silence);
}
Gateway.put = (path, data = {}, silence = false) => {
    return Gateway.execute(path, { method: 'put', data: data }, silence);
}
Gateway.del = (path, data = {}, silence = false) => {
    return Gateway.execute(path, { method: 'delete', data: data }, silence);
}
Gateway.down = (path, params = {}, silence = true) => {
    return Gateway.execute(path, { responseType: 'blob', params: params }, silence);
}

export default Gateway;