import React, { Component } from 'react'
import style from './index.less'
import { Pagination } from 'antd';
import { connect } from 'dva';
import router from 'umi/router'
const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchNewsList`
        });
    }
    handleTableChange = (current) => {
        const { newsSearchCond } = this.props[namespace];
        newsSearchCond.page = current;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: { newsSearchCond },
        });
        this.getList()
    };
    linkTo = (id) => {
        router.push(`/news/newsInfoDetail?id=${id}`)
    }
    render() {
        const { newsSearchCond, newsTotalNum, newsDataList = [] } = this.props[namespace]
        return <div >
            <div className={style.mainContent}>
                {newsDataList.map(item => <div key={item.id} onClick={() => { this.linkTo(item.id) }} className={`${style.boxContainer} flexbt`}>
                    <div className={style.date}>{item.present_time.replace(/-/g, '/')}</div>
                    <div className={style.newsText}>
                        <div className={`${style.newsTitle} twoline`}>{item.title}</div>
                        <div className={`${style.newsDesc} threeline`}>{item.content_snapshot}</div>
                    </div>
                    <img src={item.thumbnail} alt="" />
                </div>)}
            </div>
            {newsTotalNum === 0 ? <div className={style.emptyWrapper}><img src={require("../../assets/empty.png")} alt="" /><div className={style.desc}>暂无数据</div></div> : <div className={style.paginationWrapper}>
                <Pagination
                    total={newsTotalNum}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条`}
                    onChange={this.handleTableChange}
                    pageSize={newsSearchCond.page_size}
                    current={newsSearchCond.page}
                />
            </div>}
        </div >
    }
}