import { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, DatePicker, Checkbox, Cascader, Switch } from 'antd';
import moment from 'moment'
const FormItem = Form.Item;
const { RangePicker, MonthPicker } = DatePicker;
const { Option } = Select;
function mapPropsToFields (props) {
    const { fields, searchList, monthRange } = props;
    if (fields) {
        const formFileds = {}
        Object.keys(fields).map((item) => {
            formFileds[item] = Form.createFormField({ value: fields[item] })
        })
        searchList.map((item, index) => {
            if (item.type === 'DATE') {
                formFileds[item.key] = Form.createFormField({ value: moment(fields[item.key]) })
            } else if (item.type === 'MONTHRANGE') {
                if (monthRange && monthRange.length > 0) {
                    formFileds[item.key] = Form.createFormField(
                        { value: [moment(monthRange[0]), moment(monthRange[1])] }
                    )
                }
            } else if (item.type === 'RANGEDATE') {
                if (item.key === 'time') {
                    const { firstTime = 'start_time', secondTime = 'end_time' } = item.rangeDateLabel
                    if (fields && fields[secondTime]) {
                        formFileds[item.key] = Form.createFormField(
                            { value: [moment(fields[firstTime]), moment(fields[secondTime])] }
                        )
                    }

                } else if (item.key === 'time2') {
                    if (fields.accept_start_time && fields.accept_end_time) {
                        formFileds[item.key] = Form.createFormField(
                            { value: [moment(fields.accept_start_time), moment(fields.accept_end_time)] }
                        )
                    }

                } else {
                    const { firstTime = 'start_time', secondTime = 'end_time' } = item.rangeDateLabel
                    if (fields && fields[secondTime]) {
                        formFileds[item.key] = Form.createFormField(
                            { value: [moment(fields[firstTime]), moment(fields[secondTime])] }
                        )
                    }
                }
            }else if (item.type === 'MONTH') {
                if (fields[item.key]) {
                  formFileds[item.key] = Form.createFormField({ value: moment(fields[item.key]) })
                }
            }
        })
        return formFileds
    }
}
@Form.create({ mapPropsToFields: mapPropsToFields })
export default class CheckBox extends Component {
    handleReset = () => {
        if (this.props.searchReset) {
            this.props.searchReset()
        } else {
            this.props.form.resetFields();
        }
    }
    handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.props.form.validateFields((err, values) => {
            Object.entries(values).forEach(([k, v]) => {
                if (typeof v === 'string') {
                    values[k] = v.trim()
                }
            });
            this.props.searchSubmit(values);
        });
    }
    handleExport = () => {
        if (this.props.searchExport) {
            this.props.searchExport();
        }
    }
    initFormList = () => {
        const { getFieldDecorator } = this.props.form;
        const { searchList, fields } = this.props;
        let formItemList = [];
        if (searchList && searchList.length > 0) {
            searchList.map((item, index) => {
                const { name, key, params, placeholder, options = [], width = '120px', onSelect, onSearch, onChange, fieldNames, onPanelChange, disabled } = item;
                let itemObj = null;
                switch (item.type) {
                    case 'INPUT':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <Input style={{ width: width }} placeholder={placeholder} />
                            )}
                        </FormItem>;
                        break;
                    case 'SELECT':
                        itemObj = <FormItem label={name} key={index} >
                            {getFieldDecorator(key, { ...params })(
                                <Select style={{ width: width }} placeholder={placeholder} onSelect={onSelect} fieldNames={fieldNames} disabled={disabled}>
                                    {
                                        options.map((select) => {
                                            return <Option value={select.id} key={select.id}>{select.name}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>;
                        break;
                    case 'CASCADER':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <Cascader options={options} fieldNames={fieldNames} onChange={onChange} style={{ width: width, display: 'inline-block' }} />
                            )}
                        </FormItem>
                        break;
                    case 'SEARCHSELECT':
                        itemObj = <FormItem label={name} key={index} >
                            {getFieldDecorator(key, { ...params })(
                                <Select style={{ width: width }} placeholder={placeholder} onSelect={onSelect} showSearch filterOption={false} onSearch={onSearch}>
                                    {
                                        options.map((select) => {
                                            return <Option value={select.id} key={select.id}>{select.name}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>;
                        break;
                    case 'DATE':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <DatePicker style={{ width: width, display: 'inline-block' }} />
                            )}
                        </FormItem>;
                        break;
                    case 'RANGEDATE':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <RangePicker style={{ width: width }}
                                // onChange={()=>{
                                //     if(item.autoSearch) {
                                //         this.handleSearch()
                                //     }
                                // }}
                                />
                            )}
                        </FormItem>
                        break;
                    case 'MONTHRANGE':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <RangePicker
                                    onPanelChange={onPanelChange}
                                    onChange={onChange}
                                    placeholder={['开始月份', '结束月份']}
                                    mode={['month', 'month']}
                                    format="YYYY-MM"
                                    style={{ width: width }}
                                />
                            )}
                        </FormItem>
                        break;
                    case 'MONTH':
                        itemObj = (
                          <FormItem label={name} key={key}>
                            {getFieldDecorator(key, { ...params })(
                              <MonthPicker
                                style={{ width, display: 'inline-block' }}
                                placeholder={placeholder}
                                month
                              />
                            )}
                          </FormItem>
                        )
                        break;
                    case 'CHECKBOX':
                        itemObj = <FormItem key={index}>
                            {getFieldDecorator(key, { valuePropName: 'checked' })(
                                <Checkbox>{name}</Checkbox>
                            )}
                        </FormItem>
                        break;
                    case 'SWITCH':
                        itemObj = <FormItem label={name} key={index}>
                            {getFieldDecorator(key, { ...params })(
                                <Switch></Switch>
                            )}
                        </FormItem>
                }
                formItemList.push(itemObj)
            })
        }

        return formItemList;
    }
    render () {
        return (
            <Form layout="inline">
                {this.initFormList()}
                <FormItem>
                    <Button type="primary" onClick={this.handleSearch}>
                        查询
                    </Button>
                    {!this.props.isNoReset && (
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                            重置
                        </Button>
                    )}
                    {this.props.isExport && (
                        <Button
                            style={{ marginLeft: 8 }}
                            onClick={this.handleExport}
                            type="primary"
                        >
                            {this.props.searchName}
                        </Button>
                    )}
                    {this.props.otherJSX}
                </FormItem>
            </Form>
        );
    }
}