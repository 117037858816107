import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'


// 分页条件查询额度报表
export async function getQuotaReportReport(params) {
    return Gateway.post(`${Config.GatewayPlatform}/check/consume/pageByCondition`, params)
}

// 分页条件查询理赔报表
export async function getClaimReportReport(params) {
    return Gateway.post(`${Config.GatewayPlatform}/check/policy/pageByCondition`, params)
}

