import { message } from 'antd';
import {
    getDrugList,
    getDrugDetail
} from '@/service/drug'

export default {
    namespace: 'drug',
    state: {
        searchCond: {
            page_size: 12,
            page: 1,
            organ_type:'1',
        },
        dataList: [],
        totalNum: 0,
    },
    effects: {

        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.drug.searchCond);
            const { items, count } = yield call(getDrugList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: items,
                    totalNum: count,
                },
            });
        },

        *getProductDetail({ payload }, { call, put, select }) {
            const res = yield call(getDrugDetail, payload);
            return res
        },


    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};