import { Component } from 'react';
import { connect } from 'dva';
import { CopyrightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import SiderMenu from './SiderMenu';
import HeaderBox from './HeaderBox/index.js';
import FloatWindow from '../component/FloatWindow'
import { getUrlParam } from '@/utils/utils';
const { Footer, Content } = Layout;
import FooterBox from './FooterBox/index.jsx'
@connect(({ global }) => ({
    global
}))
export default class BasicLayout extends Component {
    state = {
        show: false
    }
    componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        if (pos.top < 60) {
            this.setState({ show: true })
        } else {
            this.setState({ show: false })
        }
    }
    scrollToTop = () => {
        const layout = document.getElementsByClassName('ant-layout-content');
        layout[0].scrollTo(0, 0)
    }
    render() {
        const { global } = this.props;
        const { show } = this.state;
        const { collapsed, login, openKeys, selectedKeys } = global;
        const hideSideMenu = getUrlParam('hideSideMenu')
        return (
            <Layout id="root">
                {/* {!hideSideMenu && <SiderMenu collapsed={collapsed} openKeys={openKeys} selectedKeys={selectedKeys} />} */}
                <Layout style={{ background: "#F4F8FC" }}>
                    <HeaderBox />
                    {/* background: '#f0f2f5' */}
                    <Content ref={this.contentRef} style={{ height: '100%', minWidth: '1400px', overflow: 'auto' }}>
                        <div style={{ background: '#F4F8FC', minHeight: '91.7%', minWidth: '1400px', borderRadius: 4 }}>
                            <div className='point'></div>
                            {this.props.children}
                        </div>
                        <FooterBox />
                        <FloatWindow show={show} scrollToTop={() => { this.scrollToTop() }}></FloatWindow>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}