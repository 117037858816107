import { PRODUCT_CENTER_DETAIL, ROLE_TYEP } from "@/common/constant";
import React, { Component } from "react";
import router from "umi/router";
import style from './provide.less'

export default class Provide extends Component {
	render () {
		const { data,active } = this.props
        const { desc='',backgroundImg=require('@/assets/home/social/provide1.png'),maskContent=[],imgWidth} = data
		return (
			<div className={style.provideContainer} style={active?{opacity:1}:{}}>
                <div className={style.top}>{desc}</div>
                <img style={{width:imgWidth}} src={backgroundImg} alt="" />
                <div className={style.maskItem}>
					{maskContent.map((item, index) => {
                        return <div className={style.maskContent} key={index}>
                            <div>{item.title}</div>
                            {item.content?.map((value,i)=>{
                                return <div style={{textAlign:'justify'}} key={i}>{value}</div>
                            })}
                        </div>
                    })}
				</div>
			</div>
		);
	}
}
