import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'

// 获取投保单位列表
export async function getUnitList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/insured/unit/pageByAgentId`, params)
}

// 获取投保人员列表
export async function getUnitStaffList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/card/pageByAgentId`, params)
}
