import { colorRegExp as _colorRegExp } from 'style-utils';
export function noop() {}
export var colorRegExp = _colorRegExp;
export var shadowExp = /.*shadow$/gi;
export var windowIsUndefined = !(typeof window !== 'undefined' && window.document && window.document.createElement);
export var getTime = Date.now;
export function parsePath(path) {
  if (typeof path === 'string') {
    if (path.charAt(0).match(/m/i)) {
      var domPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      domPath.setAttributeNS(null, 'd', path);
      return domPath;
    }

    return document.querySelector(path);
  } else if (path.style) {
    return path;
  }

  throw new Error('Error while parsing the path');
}
export var initAnimProps = {
  type: 'to',
  duration: 450,
  delay: 0,
  repeat: 0,
  repeatDelay: 0,
  appearTo: 0,
  yoyo: false,
  ease: 'easeInOutQuad',
  onStart: noop,
  onUpdate: noop,
  onComplete: noop,
  onRepeat: noop,
  startAt: {}
};
export var initProps = {
  animation: {},
  paused: false,
  reverse: false,
  delay: 0,
  repeat: 0,
  repeatDelay: 0,
  yoyo: false,
  moment: null,
  resetStyle: false,
  regionStartTime: 0,
  regionEndTime: undefined,
  onChange: noop,
  onChangeTimeline: noop
};
export var getInitProps = function getInitProps(props) {
  var p = {};
  Object.keys(initProps).forEach(function (k) {
    p[k] = typeof props[k] === 'undefined' ? initProps[k] : props[k];
  }); // 时间去掉小数点;

  if (p.delay) {
    p.delay = Math.round(p.delay);
  }

  if (props.repeatDelay) {
    p.repeatDelay = Math.round(p.repeatDelay);
  }

  return p;
};
export var dataToArray = function dataToArray(d) {
  if (Array.isArray(d) || !d) {
    return d;
  }

  if (d.length) {
    var t = Array.prototype.slice.call(d);
    return t.length ? [d] : t;
  }

  return [d];
};
export var flatArray = function flatArray(d) {
  return d.length === 1 ? d[0] : d;
};
export var getAnimatePos = function getAnimatePos(animate, _ref) {
  var _ref$appearTo = _ref.appearTo,
      appearTo = _ref$appearTo === void 0 ? 0 : _ref$appearTo,
      _ref$delay = _ref.delay,
      delay = _ref$delay === void 0 ? 0 : _ref$delay,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 450 : _ref$duration,
      repeat = _ref.repeat,
      repeatDelay = _ref.repeatDelay;

  if (typeof appearTo === 'string') {
    return 0;
  }

  var appearToTime;
  var v = 0;

  if (typeof animate.appearTo === 'number') {
    return animate.appearTo;
  }

  if (typeof animate.appearTo === 'string' && animate.appearTo.charAt(1) === '=') {
    v = parseFloat(animate.appearTo.replace('=', ''));
  }

  appearToTime = appearTo + delay + duration + v;

  if (repeat === -1) {
    appearToTime = Number.MAX_VALUE;
  } else if (repeat) {
    appearToTime = appearTo + delay + duration * (repeat + 1) + (repeatDelay || 0) * repeat + v;
  }

  return appearToTime;
};
export var getDefaultStart = function getDefaultStart(key) {
  switch (key) {
    case 'opacity':
    case 'scaleX':
    case 'scaleY':
    case 'scale':
      return 1;

    default:
      return 0;
  }
};
export var getStartAtValue = function getStartAtValue(start, startAt) {
  var value = typeof start === 'string' ? parseFloat(start) : start;
  return getValue(value, startAt.vars, 1, startAt.count, startAt.unit);
};
export var getValue = function getValue(startVars, endVars, ratio) {
  var count = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var unit = arguments.length > 4 ? arguments[4] : undefined;
  return count === '+=' || count === '-=' ? startVars + endVars * ratio + (unit || 0) : (endVars - startVars) * ratio + startVars + (unit || 0);
};
export var getComputedStyle = function getComputedStyle(target) {
  if (!target) {
    return {};
  }

  var style = typeof window !== 'undefined' && document.defaultView ? document.defaultView.getComputedStyle(target) : {};
  return style;
};
export var styleValueToArray = {
  margin: 1,
  padding: 1,
  borderWidth: 1,
  borderRadius: 1
};
export function startConvertToEndUnit(_ref2) {
  var target = _ref2.target,
      computedStyle = _ref2.computedStyle,
      style = _ref2.style,
      value = _ref2.value,
      startUnit = _ref2.startUnit,
      endUnit = _ref2.endUnit,
      fixed = _ref2.fixed,
      isOriginWidth = _ref2.isOriginWidth,
      useCurrentTarget = _ref2.useCurrentTarget;

  if (windowIsUndefined) {
    return value;
  }

  var horiz = /(?:Left|Right|Width|X)/i.test(style) || isOriginWidth;
  horiz = style === 'padding' || style === 'marign' ? true : horiz;
  var t = style.indexOf('border') !== -1 || style.indexOf('translate') !== -1 || style === 'transformOrigin' || useCurrentTarget ? target : target.parentNode || document.body;
  t = fixed ? document.body : t;
  var pix;
  var htmlComputedStyle;
  var warPix; // transform 在 safari 下会留着单位，chrome 下会全部转换成 px;

  switch (startUnit) {
    case '%':
      pix = parseFloat(value) / 100 * (horiz ? t.clientWidth : t.clientHeight);
      break;

    case 'vw':
      pix = parseFloat(value) * document.body.clientWidth / 100;
      break;

    case 'vh':
      pix = parseFloat(value) * document.body.clientHeight / 100;
      break;

    case 'em':
      if (!computedStyle) {
        warPix = 'em';
        pix = parseFloat(value);
      } else {
        pix = parseFloat(value) * parseFloat(computedStyle.fontSize);
      }

      break;

    case 'rem':
      {
        if (!computedStyle) {
          warPix = 'rem';
          pix = parseFloat(value);
        } else {
          htmlComputedStyle = window.getComputedStyle(document.getElementsByTagName('html')[0]);
          pix = parseFloat(value) * parseFloat(htmlComputedStyle.fontSize);
        }

        break;
      }

    default:
      pix = parseFloat(value);
      break;
  }

  switch (endUnit) {
    case '%':
      pix = pix ? pix * 100 / (horiz ? t.clientWidth : t.clientHeight) : 0;
      break;

    case 'vw':
      pix = parseFloat(value) / document.body.clientWidth * 100;
      break;

    case 'vh':
      pix = parseFloat(value) / document.body.clientHeight * 100;
      break;

    case 'em':
      if (!computedStyle) {
        warPix = 'em';
        pix = parseFloat(value);
      } else {
        pix = parseFloat(value) / parseFloat(computedStyle.fontSize);
      }

      break;

    case 'rem':
      {
        if (!computedStyle) {
          warPix = 'rem';
          pix = parseFloat(value);
        } else {
          htmlComputedStyle = htmlComputedStyle || window.getComputedStyle(document.getElementsByTagName('html')[0]);
          pix = parseFloat(value) / parseFloat(htmlComputedStyle.fontSize);
        }

        break;
      }

    default:
      break;
  }

  if (warPix) {
    console.warn("Warning: Element is not 'DOM, can not use '".concat(warPix, "', automatically convert to animation units."));
  }

  return pix;
}
export function getTransformValue(t) {
  if (typeof t === 'string') {
    return t;
  }

  var perspective = t.perspective;
  var angle = t.rotate;
  var rotateX = t.rotateX;
  var rotateY = t.rotateY;
  var sx = t.scaleX;
  var sy = t.scaleY;
  var sz = t.scaleZ;
  var skx = t.skewX;
  var sky = t.skewY;
  var translateX = typeof t.translateX === 'string' ? t.translateX : "".concat(t.translateX, "px");
  var translateY = typeof t.translateY === 'string' ? t.translateY : "".concat(t.translateY, "px");
  var translateZ = typeof t.translateZ === 'string' ? t.translateZ : "".concat(t.translateZ, "px");
  var sk = skx || sky ? "skew(".concat(skx, "deg,").concat(sky, "deg)") : '';
  var an = angle ? "rotate(".concat(angle, "deg)") : '';
  var ss = sx !== 1 || sy !== 1 || sz !== 1 ? "scale3d(".concat(sx, ",").concat(sy, ",").concat(sz, ")") : '';
  var rX = rotateX ? "rotateX(".concat(rotateX, "deg)") : '';
  var rY = rotateY ? "rotateY(".concat(rotateY, "deg)") : '';
  var per = perspective ? "perspective(".concat(perspective, "px)") : '';
  var defaultTranslate = ss || an || rX || rY || sk ? '' : 'translate(0px, 0px)';
  var translate = t.translateZ ? "translate3d(".concat(translateX, ",").concat(translateY, ",").concat(translateZ, ")") : (t.translateX || t.translateY) && "translate(".concat(translateX, ",").concat(translateY, ")") || defaultTranslate;
  return "".concat(per, " ").concat(translate, " ").concat(ss, " ").concat(an, " ").concat(rX, " ").concat(rY, " ").concat(sk).trim();
}
/* export const styleToString = (v: IObject) => {
  let s = '';
  Object.keys(v).forEach(k => {
    s += `${toCssLowerCase(k)}: ${v[k]}; `;
  });
  return s;
};*/

export var initFilterValue = {
  brightness: 1,
  saturate: 1,
  contrast: 1,
  grayScale: 0,
  hueRotate: '0deg',
  sepia: 0,
  invert: 0
};