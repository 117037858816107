import React, { Component } from 'react'
import style from './index.less'
import { connect } from 'dva';
import NewsInfo from './newsInfo'
import VideoInfo from './videoInfo'
const bannerData = [
    { key: "1", text: "新闻资讯" },
    { key: "2", text: "视频资讯" },
]
const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    
    handleSelect = (selectedKey) => {
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: { selectedKey },
        });
    }
    render() {
        const { selectedKey } = this.props[namespace]
        return <div className={style.wrapper}>
            <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
                <div className={style.desc}>
                    <div className={style.title}>新闻动态</div>
                    <div className={style.text}>News updates</div>
                </div>
                <img src={require("../../assets/newsBanner.png")} alt="" />
            </div>
            <div className={style.selectedModule}>
                {bannerData.map((item) => {
                    return <div key={item.key} className={style.moduleItem}
                        style={{ borderLeft: item.key == 1 ? "1px solid #ddd" : "" }}
                        onClick={() => { this.handleSelect(item.key) }}
                    >
                        {item.key == selectedKey && <img src={require("@/assets/joinUs/selected.png")} />}
                        <span style={{ color: item.key == selectedKey ? "#fff" : "" }}>{item.text}</span>
                    </div>
                })}
            </div>
            {selectedKey === '1' && <NewsInfo />}
            {selectedKey === '2' && <VideoInfo />}
        </div >
    }
}