import React, { Component } from 'react';
import { connect } from 'dva';

const namespace = 'dataMoudle';
@connect(({ dataMoudle }) => ({ dataMoudle }))
export default class ClaimManage extends Component {
    componentDidMount() {
        this.props.dispatch({
            type: `${namespace}/fetchDashUrl`,
            payload:{
                dashboard:36,
            }
        })
    }
    render() {
        const { url } = this.props[namespace]
        return <div style={{ height: '100%' }}>
            <iframe
            ref="iframe"
            src={url}
            frameBorder="0"
            width="99%"
            height="100%"
            scrolling="yes"
            />
        </div>
    }
}