import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'
import { fetch } from 'dva';
import { message } from 'antd'

export const checkExist = (params) => Gateway.post(`${Config.Passport}/checkExist?opName=${params.opName}`)

export const loginOut = (token) => Gateway.post(`${Config.Passport}/oauth/revoke/${token}`)

export const getUserinfo = () => Gateway.get(`${Config.GatewayAdmin}/me`)

export const getUnitList = (data) => Gateway.post(`${Config.GatewayPlatform}/enterprise/listByAgentId`, data)

export const getUserMobile = () => Gateway.get(`${Config.Passport}/getMobileNo`)


export const getImageCaptcha = async () => {
	try {
		const res = await fetch(`https://leocaptcha.jianbaolife.com/get_captcha`, { method: 'get' })
		if (!res.ok) throw 'Response not OK!'
		const result = await res.blob()

		return {
			captchaImg: window.URL.createObjectURL(result),
			captchaToken: res.headers.get('lp-captcha-token'),
		}
	}
	catch (err) {
		console.error('Get image captcha error!')
	}
}

export const checkImgCaptcha = async (params) => {
	const res = await fetch(`https://leocaptcha.jianbaolife.com/check_captcha?captcha_code=${params.captcha_code}`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json', 'lp-captcha-token': params.captchaToken },
	});
	return res.json();
}

export const sendCode = async (params) => {
	const res = await fetch(`${Config.Passport}/send_verify_code?${params.username ? 'username=' + params.username + '&' : ''}${params.opMobile ? 'opMobile=' + params.opMobile : ''}`, {
		headers: { 'Content-Type': 'application/json', 'lp-validate': params.captchaToken },
	});
	return res.json();
}

export const signIn = async (params) => {
	try {
		const res = await fetch(`${Config.Passport}/login`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body:
				encodeURIComponent('username') +
				'=' +
				encodeURIComponent(params.username) +
				'&' +
				encodeURIComponent('password') +
				'=' +
				encodeURIComponent(params.password) +
				'&' +
				encodeURIComponent('verify_code') +
				'=' +
				encodeURIComponent(params.verifyCode),
		})
		if (res.ok) {
			const result = await res.json()
			if (result.rc == 0) {
				return result.data;
			} else {
				message.error(result.msg);
			}
		}
	} catch (error) {
		console.log(error);
	}
	
}
