import { message } from 'antd';
import { getShareUrl, getDashUrl } from '@/service/platform/dataMoudle'

export default {
    namespace: 'dataMoudle',
    state: {
        url:'',
    },
    effects: {
        *fetchUrl({ payload }, { call, put, select }) {
            const res = yield call(getShareUrl, payload);
            yield put({
                type: "set",
                payload: {
                    url: res,
                },
            });
        },
        *fetchDashUrl({ payload }, { call, put, select }) {
            const res = yield call(getDashUrl, payload);
            yield put({
                type: "set",
                payload: {
                    url: res,
                },
            });
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};