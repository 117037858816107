import { PureComponent } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Menu, Layout, Badge } from "antd";
import Link from "umi/link";
import styles from "./index.less";
import { getMenuFn } from "./platformMenu.js";
import { connect } from "dva";

const { SubMenu } = Menu;
const { Sider } = Layout;
let timer = null;

@connect(({ global, loading }) => ({
  global,
}))
export default class SiderMenu extends PureComponent {
  state = {
    USERMENU: [],
    // tipKeyList: ["audit_count", "task_count"],
  };
  componentDidMount() {
    this.setState({
      USERMENU: getMenuFn(),
    });
  }

  componentWillUnmount() {
    // clearInterval(timer);
  }

  renderNodes = (data) => {
    const { tipData } = this.props["global"];
    return data.map((item, index) => {
      if (item.children_menus && item.children_menus.length) {
        return (
          <SubMenu
            key={item.menu_identify}
            title={
              <span>
                <LegacyIcon type={item.menu_icon} />
                <span>{item.menu_name}</span>
              </span>
            }
          >
            {this.renderNodes(item.children_menus || [])}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.menu_identify}>
          <Link to={`/${item.menu_identify}`}>
            {item.level == 1 && <LegacyIcon type={item.menu_icon} />}
            {/* <span>{item.menu_name}</span> */}
            {item.tipKey ? (
              <Badge
                count={Number(tipData[item.tipKey])}
                overflowCount={99}
                offset={[17, 7]}
              >
                <span className="self-badge">{item.menu_name}</span>
              </Badge>
            ) : (
              <span>{item.menu_name}</span>
            )}
          </Link>
        </Menu.Item>
      );
    });
  };

  allMenuItem = (data) => {
    return data?.map((item, index) => {
      if (item.children_menus && item.children_menus.length) {
        return (
          <SubMenu
            key={item.menu_identify}
            title={
              <span>
                <LegacyIcon type={item.menu_icon} />
                <span>{item.menu_name}</span>
              </span>
            }
          >
            {this.allMenuItem(item.children_menus || [])}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.menu_identify}>
          <Link to={`/${item.menu_identify}`}>
            {item.level == 1 && <LegacyIcon type={item.menu_icon} />}
            <span>{item.menu_name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };

  render() {
    const { global, collapsed, openKeys, selectedKeys } = this.props;
    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="md"
        width={220}
        className={styles.sider}
      >
        {/* <div className={styles.logo}>
          <img src={require('@/assets/logo.png')} alt="logo" />
          <h3>老白开放平台</h3>
        </div> */}
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={selectedKeys}
          selectedKeys={selectedKeys}
          className={styles.munuBox}
        >
          {/* {this.renderNodes(this.state.USERMENU || [])} */}
          {this.allMenuItem(this.state.USERMENU || [])}
        </Menu>
      </Sider>
    );
  }
}
