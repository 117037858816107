const Config = {};


Config.GatewayAdmin = API_ENV === 'production' ? 'https://partneroa-gateway.laobai.com' : 'https://partneroa-gateway.test.laobai.com'
Config.GatewayPlatform = API_ENV === 'production' ? 'https://partneroa-gateway.laobai.com/platform' : 'https://partneroa-gateway.test.laobai.com/platform'
Config.Passport = API_ENV === 'production' ? 'https://partneroa-passport.laobai.com' : 'https://partneroa-passport.test.laobai.com'

// 工作台健康数据模块
Config.healthManageUrl = API_ENV === 'production' ? 'https://corp.laobai.com' : 'https://corp.test.laobai.com'

export default Config;
