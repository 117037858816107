import React, { Component } from 'react'
import { Affix } from 'antd';
import qs from 'query-string'
import style from './index.less'
import FlyInAnimation from '../../component/flyInAnimation'
import Zhyf from './component/zhyf'
import Phhy from './component/phhy'
import Sbhy from './component/sbhy'

export default class Join extends Component {
    state = {
        tab: 1,
        tabList: [
            { id: 1, name: '智慧员福' },
            { id: 2, name: '商保好药' },
            { id: 3, name: '普惠好药' },
        ],
        bannerActive: false,
    }
    componentDidMount() {
        const tab = qs.parse(location.search).tab || '1'
        // banner动画
        this.setState({tab, bannerActive:true })
    }
    changeTab = (id) => {
        this.setState({ tab: id })
        const anchorElement = document.getElementById('topBanner');
        if (anchorElement) { anchorElement.scrollIntoView(true) }
    }
    
    render() {
        const { tabList, tab } = this.state
        return <div className={style.wrapper}>
            <div className={style.banner} style={{ height: 'auto', width: '100%' }} id="topBanner">
                <img src={require("../../assets/business/topBanner.png")} alt="" />
                <FlyInAnimation show={this.state.bannerActive}>
                    <div className={style.desc}>
                        <img src={require("../../assets/business/topDesc.png")} alt="" />
                    </div>
                </FlyInAnimation>
            </div>
            <div className={style.tabWrappr}>
                <div className={style.tabBox}>
                    {tabList.map(item => <div key={item.id} onClick={() => { this.changeTab(item.id) }} className={tab == item.id ? `${style.tabBtn} ${style.active}` : style.tabBtn}>{item.name}</div>)}
                </div>
            </div>
            { tab == 1 ? <Zhyf/> : tab == 2 ? <Sbhy/> : <Phhy/> }
        </div >
    }
}