
import React, { Component } from 'react'
import { CopyrightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import styles from './index.less'
import router from 'umi/router'
import Link from 'umi/link';

const { Footer, Content } = Layout;
export default class FooterBox extends Component {

  render() {
    const host = window.location.host
    return <Footer className={styles.footerContainer}>
      {host.indexOf('laobaiyy') > -1 ? <div>
        <span>海南老白健康科技有限公司 <CopyrightOutlined />2021-2024 laobaiyy.com版权所有 <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        >ICP证：琼ICP备2021005742号</a>
        </span>
      </div> : <div className={`${styles.copyright} flexbet`}>
        <div>
          <div>
            <span><a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
            >浙ICP备11001156号-7 ICP证:浙B2-20200881</a>
            </span>
            <span className='ml10 mr10'>|</span>
            <span>专属客服热线：4008089697</span>
            <span className='ml10 mr10'>|</span>
            <span><Link target="_blank" to={`/qualification?type=1`}>营业执照</Link></span>
            <span className='ml10 mr10'>|</span>
            <span><Link target="_blank" to={`/qualification?type=2`}>增值电信业务经营许可证</Link></span>
            <span className='ml10 mr10'>|</span>
            <span><Link target="_blank" to={`/qualification?type=3`}>互联网药品信息服务资格证书号(浙)-经营性-2019-0106</Link></span>
          </div>
          <div>
            <span><Link target="_blank" to={`/qualification?type=4`}>药品网络交易第三方平台备案编号(浙)网药平台备字〔2023〕第000001-000号</Link></span>
            <span className='ml10 mr10'>|</span>
            <span><Link target="_blank" to={`/qualification?type=5`}>医疗器械网络交易服务第三方平台备案号(浙)网械平台备字 [2023] 第00004号</Link></span>
          </div>
          <div>
            <span>Copyright<CopyrightOutlined /> 2021-2024laobai.com 版权所有</span>
          </div>
        </div>
        <div className={styles.qrContainer}>
          <div style={{width:60}}>
            <img src={require('@/assets/qr1.png')} alt="" />
            <div className='mt10 ta-c'>商保好药</div>
          </div>
          <div style={{width:60}}>
            <img src={require('@/assets/qr2.png')} alt="" />
            <div className='mt10 ta-c'>智慧员福</div>
          </div>
          <div style={{width:60}}>
            <img src={require('@/assets/qr3.png')} alt="" />
            <div className='mt10 ta-c'>普惠好药</div>
          </div>
        </div>
      </div>}
    </Footer>
  }
}