export const menu = [
  {
    menu_name: "基础模块",
    menu_identify: "platform/basicModule",
    menu_icon: "appstore",
    children_menus: [
      {
        menu_name: "保险公司管理",
        menu_identify: "platform/basicModule/insuranceManage",
        menu_icon: "insurance",
        children_menus: [
          {
            menu_name: "员工管理",
            menu_identify:
              "platform/basicModule/insuranceManage/insuranceStaff",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
      {
        menu_name: "投保单位管理",
        menu_identify: "platform/basicModule/insuredUnitManagement",
        menu_icon: "profile",
        children_menus: [
          {
            menu_name: "单位管理",
            menu_identify:
              "platform/basicModule/insuredUnitManagement/unitManagement",
            menu_icon: "",
            children_menus: null,
          },
          {
            menu_name: "投保人员管理",
            menu_identify:
              "platform/basicModule/insuredUnitManagement/unitStaff",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
      {
        menu_name: "保单管理",
        menu_identify: "platform/basicModule/proposalFormManagement",
        menu_icon: "fileProtect",
        children_menus: [
          {
            menu_name: "保单详情",
            menu_identify:
              "platform/basicModule/proposalFormManagement/proposalFormInfo",
            menu_icon: "",
            children_menus: null,
          },
          // {
          //     "menu_name": "投保人保单详情",
          //     "menu_identify": "platform/basicModule/proposalFormManagement/policyholder",
          //     "menu_icon": "",
          //     "children_menus": null
          // },
        ],
      },
      {
        menu_name: "服务商管理",
        menu_identify: "platform/basicModule/serviceProvidorManagement",
        menu_icon: "team",
        children_menus: [
          {
            menu_name: "机构管理",
            menu_identify:
              "platform/basicModule/serviceProvidorManagement/organization",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
      // {
      //     "menu_name": "药品订单管理",
      //     "menu_identify": "platform/basicModule/drugOrderManage",
      //     "menu_icon": "unorderedList",
      //     "children_menus": [
      //         {
      //             "menu_name": "消费信息",
      //             "menu_identify": "platform/basicModule/drugOrderManage/expenseInfo",
      //             "menu_icon": "",
      //             "children_menus": null
      //         },
      //     ]
      // },
      {
        menu_name: "明细管理",
        menu_identify: "platform/basicModule/detailManage",
        menu_icon: "profile",
        children_menus: [
          {
            menu_name: "被保险人明细",
            menu_identify: "platform/basicModule/detailManage/assuredDetail",
            menu_icon: "",
            children_menus: null,
          },
          {
            menu_name: "消费明细",
            menu_identify: "platform/basicModule/drugOrderManage/expenseInfo",
            menu_icon: "",
            children_menus: null,
          },
          {
            menu_name: "额度明细",
            menu_identify: "platform/basicModule/detailManage/quotaDetail",
            menu_icon: "",
            children_menus: null,
          },
          {
            menu_name: "理赔明细",
            menu_identify: "platform/basicModule/detailManage/claimDetail",
            menu_icon: "",
            children_menus: null,
          },
          {
            menu_name: "TPA明细",
            menu_identify: "platform/basicModule/detailManage/TPADetail",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
      {
        menu_name: "服务商管理",
        menu_identify: "platform/basicModule/serviceManage",
        menu_icon: "team",
        children_menus: [
          {
            menu_name: "商家集团管理",
            menu_identify: "platform/basicModule/serviceManage/groupManageer",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
      {
        menu_name: "企业中心",
        menu_identify: "platform/basicModule/enterpriseManage",
        menu_icon: "team",
        children_menus: [
          {
            menu_name: "配置信息",
            menu_identify:
              "platform/basicModule/enterpriseManage/configuration",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
    ],
  },
  {
    menu_name: "业务中心模块",
    menu_identify: "platform/businessCenter",
    menu_icon: "deployment-unit",
    children_menus: [
      {
        menu_name: "理赔操作管理",
        menu_identify: "platform/businessCenter/claimOperateManage",
        menu_icon: "home",
        children_menus: [
          {
            menu_name: "保单理赔配置",
            menu_identify:
              "platform/businessCenter/claimOperateManage/claimSetting",
            menu_icon: "",
            children_menus: null,
          },
        ],
      },
    ],
  },
  {
    menu_name: "报表模块",
    menu_identify: "platform/report",
    menu_icon: "fileText",
    children_menus: [
      {
        menu_name: "额度报表",
        menu_identify: "platform/report/quotaReport",
        menu_icon: "",
        children_menus: null,
      },
      {
        menu_name: "理赔报表",
        menu_identify: "platform/report/claimReport",
        menu_icon: "",
        children_menus: null,
      },
    ],
  },
  {
    menu_name: "数据模块",
    menu_identify: "platform/dataMoudle",
    menu_icon: "database",
    children_menus: [
      {
        menu_name: "健康服务数据",
        menu_identify: "platform/dataMoudle/healthManage",
        menu_icon: "safetyCertificate",
        children_menus: null,
        // "children_menus": [
        //     {
        //         "menu_name": "健康状况",
        //         "menu_identify": "platform/dataMoudle/healthManage/situation",
        //         "menu_icon": "",
        //         "children_menus": null
        //     },
        //     {
        //         "menu_name": "慢病管理",
        //         "menu_identify": "platform/dataMoudle/healthManage/warn",
        //         "menu_icon": "",
        //         "children_menus": null
        //     },
        //     {
        //         "menu_name": "健康监测",
        //         "menu_identify": "platform/dataMoudle/healthManage/measure",
        //         "menu_icon": "",
        //         "children_menus": null
        //     },
        //     {
        //         "menu_name": "健康宣教",
        //         "menu_identify": "platform/dataMoudle/healthManage/publicity",
        //         "menu_icon": "",
        //         "children_menus": null
        //     },
        // ]
      },
      {
        menu_name: "保单维度数据",
        menu_identify: "platform/dataMoudle/proposalFormManage",
        menu_icon: "",
        children_menus: null,
      },
      {
        menu_name: "消费和用户数据",
        menu_identify: "platform/dataMoudle/expenseAndUserManage",
        menu_icon: "",
        children_menus: null,
      },
      {
        menu_name: "理赔维度数据",
        menu_identify: "platform/dataMoudle/claimManage",
        menu_icon: "",
        children_menus: null,
      },
    ],
  },
];

function getMenu(arr, addArr) {
  let selectedArr = localStorage.getItem("userMenu")
    ? JSON.parse(localStorage.getItem("userMenu")).map((item) => {
        return item.menu_identify;
      })
    : [];
  arr.forEach((item) => {
    if (selectedArr.includes(item.menu_identify)) {
      let newObj = { ...item, children_menus: [] };
      addArr.push(newObj);
      if (item.children_menus) {
        getMenu(item.children_menus, newObj.children_menus);
      }
    }
  });
}

export function getMenuFn() {
  let selectedArr = localStorage.getItem("userMenu")
    ? JSON.parse(localStorage.getItem("userMenu")).map((item) => {
        return item.menu_identify;
      })
    : [];
  let returnData = [];
  getMenu(menu, returnData);
  localStorage.setItem("permisson", JSON.stringify(selectedArr));
  selectedArr.map((str) => {
    if (str && str.includes("_role")) {
      localStorage.setItem("loginRole", str.replace("_role", ""));
    }
  });
  console.log("当前登录角色", localStorage.getItem("loginRole"));
  return returnData;
}
