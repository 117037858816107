import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import style from "./index.less";

export default class FlyInAnimation extends Component {
  render() {
    const { show } = this.props;
    return (
      <CSSTransition
        in={show}
        timeout={1000}
        classNames={{
          enter: style.flyEnter,
          enterActive: style.flyEnterActive,
          enterDone: style.flyEnterDone,
        }}
      >
        {this.props.children}
      </CSSTransition>
    );
  }
}
