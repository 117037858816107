export const bannerData = [
  { key: 1, text: "成长成才" },
  { key: 2, text: "机会与舞台" },
  { key: 3, text: "星光引路" },
  { key: 4, text: "加入我们" },
];
export const peopleArr = [
  {
    key: 1,
    name: "吴晨曦",
    university: "毕业于武汉理工大学",
    head: require("@/assets/joinUs/head1.png"),
    intro:
      "2020年8月加入老白健康，现任创新发展事业部业务部总经理。经过总部的储备干部培训，他已经成为公司的业务精英。在O2O商家签约、电子处方签约以及慢病保销售业务等方面都有出色的表现。",
  },
  {
    key: 2,
    name: "康金伟",
    university: "毕业于浙江大学",
    head: require("@/assets/joinUs/head2.png"),
    intro:
      "2021年7月加入老白健康，公司第三批管培生，现任创新发展事业部渠道部总经理。入司以来，历任品牌公关部管培生、渠道总监、市场督导部总经理。他能力突出，为公司发展做出了积极贡献。",
  },
  {
    key: 3,
    name: "孙瑜",
    university: "毕业于伦敦大学学院UCL",
    head: require("@/assets/joinUs/head3.png"),
    intro:
      "2020年7月加入老白健康。现任浙江上海分公司总经理。在公司的培养下，她入司以来，历任HRBP、项目经理、部门负责人、分公司总经理，任职经历丰富，可谓职能多面手。",
  },
  {
    key: 4,
    name: "刘鸿",
    university: "毕业于中央财经大学",
    head: require("@/assets/joinUs/head4.png"),
    intro:
      "2019年6月加入老白健康，现任北京分公司总经理。从设计产品到对接渠道，她在自己的专业领域为老白的保险创新做出了诸多贡献。如今，她还成为了公司内部保险领域的专业讲师，从接受培训到培训他人。",
  },
  {
    key: 5,
    name: "金维川",
    university: "毕业于西南大学",
    head: require("@/assets/joinUs/head5.png"),
    intro:
      "2021年12月加入老白健康，现任川渝分公司总经理。在公司的培养下，他表现突出、成长迅速，是业务精英也是新同事的优秀导师。",
  },
];

export const abilityArr = [
  { key: 1, icon: require("@/assets/joinUs/icon1.png"), text: "判断力" },
  { key: 2, icon: require("@/assets/joinUs/icon2.png"), text: "沟通力" },
  { key: 3, icon: require("@/assets/joinUs/icon3.png"), text: "影响力" },
  { key: 4, icon: require("@/assets/joinUs/icon4.png"), text: "好奇心" },
  { key: 5, icon: require("@/assets/joinUs/icon5.png"), text: "创造力" },
  { key: 6, icon: require("@/assets/joinUs/icon6.png"), text: "勇气" },
  { key: 7, icon: require("@/assets/joinUs/icon7.png"), text: "激情" },
  { key: 8, icon: require("@/assets/joinUs/icon8.png"), text: "诚实" },
  { key: 9, icon: require("@/assets/joinUs/icon9.png"), text: "无私" },
];

export const ourData = [
  {
    img: require("@/assets/joinUs/image1.png"),
    title: "新人培训",
    content:
      "老白发展及文化价值观、职场礼仪、工作目标及压力管理、业务知识框架构建等",
  },
  {
    img: require("@/assets/joinUs/image14.png"),
    title: "新人培训",
    content:
      "老白发展及文化价值观、职场礼仪、工作目标及压力管理、业务知识框架构建等",
  },
  {
    img: require("@/assets/joinUs/image11.png"),
    title: "新人培训",
    content:
      "老白发展及文化价值观、职场礼仪、工作目标及压力管理、业务知识框架构建等",
  },
  {
    img: require("@/assets/joinUs/image12.png"),
    title: "新人培训",
    content:
      "老白发展及文化价值观、职场礼仪、工作目标及压力管理、业务知识框架构建等",
  },
  {
    img: require("@/assets/joinUs/image13.png"),
    title: "新人培训",
    content:
      "老白发展及文化价值观、职场礼仪、工作目标及压力管理、业务知识框架构建等",
  },
];

export const ourData1 = [
  {
    img: require("@/assets/joinUs/image2.png"),
    title: "青年干部培训",
    content:
      "公众演讲表达PTT培训、经营汇报管理、顾问式营销、业务专项培训、TTT等",
  },
  {
    img: require("@/assets/joinUs/image21.png"),
    title: "青年干部培训",
    content:
      "公众演讲表达PTT培训、经营汇报管理、顾问式营销、业务专项培训、TTT等",
  },
  {
    img: require("@/assets/joinUs/image22.png"),
    title: "青年干部培训",
    content:
      "公众演讲表达PTT培训、经营汇报管理、顾问式营销、业务专项培训、TTT等",
  },
  {
    img: require("@/assets/joinUs/image23.png"),
    title: "青年干部培训",
    content:
      "公众演讲表达PTT培训、经营汇报管理、顾问式营销、业务专项培训、TTT等",
  },
  {
    img: require("@/assets/joinUs/image24.png"),
    title: "青年干部培训",
    content:
      "公众演讲表达PTT培训、经营汇报管理、顾问式营销、业务专项培训、TTT等",
  },
];

export const ourData2 = [
  {
    img: require("@/assets/joinUs/image3.png"),
    title: "领导力发展计划",
    content:
      "宏观政治理解、视野拓展、中美关系及十四五纲要解读、华为“铁三角”工作法学习等",
  },
  {
    img: require("@/assets/joinUs/image31.png"),
    title: "领导力发展计划",
    content:
      "宏观政治理解、视野拓展、中美关系及十四五纲要解读、华为“铁三角”工作法学习等",
  },
  {
    img: require("@/assets/joinUs/image32.png"),
    title: "领导力发展计划",
    content:
      "宏观政治理解、视野拓展、中美关系及十四五纲要解读、华为“铁三角”工作法学习等",
  },
  {
    img: require("@/assets/joinUs/image33.png"),
    title: "领导力发展计划",
    content:
      "宏观政治理解、视野拓展、中美关系及十四五纲要解读、华为“铁三角”工作法学习等",
  },
  {
    img: require("@/assets/joinUs/image34.png"),
    title: "领导力发展计划",
    content:
      "宏观政治理解、视野拓展、中美关系及十四五纲要解读、华为“铁三角”工作法学习等",
  },
];
