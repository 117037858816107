import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import _ from 'lodash';

const namespace = 'unitStaff';
@connect(({ unitStaff }) => ({ unitStaff }))
export default class UnitStaff extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            if (v) {
                searchCond[k] = v
            } else {
                delete searchCond[k]
            }
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.page_size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const sexObj = {
            1: '男',
            2: '女'
        }
        const searchList = [
            { key: "id", type: "INPUT", placeholder: "投保人员id", width: '220px' },
            { key: "mobile", type: "INPUT", placeholder: "投保人手机号", width: '220px' },
            { key: "name", type: "INPUT", placeholder: "姓名", width: '220px' },
            { key: "insured_unit_name", type: "INPUT", placeholder: "所属单位", width: '220px' },
        ]
        const columns = [
            { title: '投保人员id', dataIndex: 'id', align: 'center', width: '150px' },
            { title: '投保人手机号', dataIndex: 'mobile', align: 'center', width: '150px' },
            { title: '姓名', dataIndex: 'name', align: 'center', width: '150px' },
            { title: '身份证号', dataIndex: 'identity_no', align: 'center', width: '150px' },
            { title: '性别', dataIndex: 'sex', align: 'center', width: '150px', render: text => sexObj[text] },
            { title: '出生日期', dataIndex: 'birthday', align: 'center', width: '150px' },
            { title: '所属单位', dataIndex: 'insured_unit_name', align: 'center', width: '150px' },
            { title: '状态', dataIndex: 'mc_state', align: 'center', width: '150px' },
        ]
        const pagination = {
            pageSize: searchCond.page_size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
            />
            <Table
                rowKey="id"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
            />
        </div>
    }
}