import Config from '@/utils/config';
import Gateway from '@/utils/Gateway'


// 查看投保单列表
export async function getProposalFormList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/customer/list`, params)
}

// 查看投保人列表 
export async function getHolderList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/customer/holder/list`, params)
}

// 查询服务商列表
export async function getSupplierList(params) {
    return Gateway.post(`${Config.GatewayPlatform}/supplier/listByTypeAndId?customerId=${params.customerId}&groupCategory=${params.groupCategory}`, params)
}

// 修改服务商配置接口
export async function setSupplierConfig(params) {
    return Gateway.post(`${Config.GatewayPlatform}/supplier/update`, params)
}