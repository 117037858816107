import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import moment from 'moment';
import _ from 'lodash';

const namespace = 'TPADetail';
@connect(({ TPADetail }) => ({ TPADetail }))
export default class QuotaDetail extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            if (v) {
                searchCond[k] = v;
            }else{
                delete searchCond[k]
            }
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const caseStateList = [
            {id:1,name:'待向保司报案'},
            {id:2,name:'报案成功'},
            {id:3,name:'业务处理失败'},
            {id:4,name:'网络超时失败'},
            {id:5,name:'结案成功'},
            {id:6,name:'网络超时失败-二次提交'},
            {id:7,name:'重新处理'},
        ]
        const searchList = [
            { key: "case_no", type: "INPUT", placeholder: "案件流水号", width: '220px' },
            { key: "policy_no", type: "INPUT", placeholder: "保单号", width: '220px' },
            { key: "unit_name", type: "INPUT", placeholder: "投保单位名称", width: '220px' },
            { key: "accident_name", type: "INPUT", placeholder: "主被保险人", width: '220px' },
            { key: "rpter_name", type: "INPUT", placeholder: "报案人", width: '220px' },
            { key: "case_state", type: "SELECT", placeholder: "案件状态", options: caseStateList, width: '200px' },
        ]
        const columns = [
            { title: '案件流水号', dataIndex: 'case_no', align: 'center', width: '150px' },
            { title: '案件状态', dataIndex: 'case_state', align: 'center', width: '150px', render: (text, record) => {
                const obj = caseStateList.find(item=>item.id == record.case_state) 
                return obj?obj.name:'-'
            }},
            { title: '申请金额', dataIndex: 'pay_amount', align: 'center', width: '100px' },
            { title: '应理赔金额', dataIndex: 'need_pay_amount', align: 'center', width: '130px'},
            { title: '实际应理赔金额', dataIndex: 'actual_pay_amount', align: 'center', width: '150px' },
            { title: '被保险人姓名', dataIndex: 'accident_name', align: 'center', width: '150px' },
            { title: '报案人姓名', dataIndex: 'rpter_name', align: 'center', width: '100px' },
            { title: '关联保单号', dataIndex: 'policy_no', align: 'center', width: '100px' },
            { title: '保单名称', dataIndex: 'cr_name', align: 'center', width: '150px' },
            { title: '投保单位名称', dataIndex: 'unit_name', align: 'center', width: '150px' },
            // {
            //     title: '操作', dataIndex: '', align: 'center', fixed: 'right', width: '200px',
            //     render: (text, record) => (
            //         <>
            //             <Button style={{ marginRight: '10px' }} type='primary' >查看详情</Button>
            //         </>
            //     )
            // },
        ]
        const pagination = {
            pageSize: searchCond.size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
                searchReset={this.searchReset}
            />
            <Table
                rowKey="case_no"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
                scroll={{ x: true }}
            />
        </div>
    }
}