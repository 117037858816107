import React, { Component } from "react";
import style from "./index.less";
import { Col, Row, Input, Button, message, Select, Form, Popover } from "antd";
import { sendCode, getImageCaptcha, checkImgCaptcha } from "@/service/login";
import { addConsultRecord } from "@/service/drug";
import { CloseCircleOutlined } from "@ant-design/icons";
export default class Home extends Component {
  state = {
    companyName: "",
    userName: "",
    companyType: undefined,
    pos: "",
    mobile: "",
    email: "",
    verifyCode: "",
    countDown: 0,
    openImgCaptcha: false,
    captchaImg: "",
    captchaToken: "",
    disabled: true,
  };
  onChange = (props, value) => {
    this.setState({
      [props]: value,
    });
  };
  runGetCaptchaCountDown = () => {
    let { countDown } = this.state;
    countDown = 60;
    this.interval = setInterval(() => {
      --countDown;
      this.setState({ countDown });
      if (countDown === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  };
  changeImgCaptchaPopOver = async (show) => {
    const { mobile } = this.state;
    if (!mobile) {
      message.warning("请输入手机号!");
      return;
    }
    if (!show) {
      this.setState({
        openImgCaptcha: false,
        captchaImg: "",
        captchaToken: "",
      });
      return;
    }
    const res = await getImageCaptcha();
    if (res) {
      this.setState({
        openImgCaptcha: true,
        captchaImg: res.captchaImg,
        captchaToken: res.captchaToken,
      });
    }
  };
  imgCaptcha = () => {
    const { captchaImg, imageCaptcha } = this.state;
    return (
      <div>
        <div>
          <img
            style={{ width: "100%", marginBottom: "7px" }}
            src={captchaImg}
            alt="点击刷新"
            onClick={() => this.changeImgCaptchaPopOver(true)}
          />
        </div>
        <Row>
          <Col span={18}>
            <Input
              value={imageCaptcha}
              onChange={(e) => {
                this.onChange("imageCaptcha", e.target.value);
              }}
              placeholder="请输入图片上的验证码"
              onPressEnter={() => this.checkImgCaptcha()}
            />
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={() => this.checkImgCaptcha()}>
              确定
            </Button>
          </Col>
        </Row>
        <div
          style={{ textAlign: "center", fontSize: "20px", margin: "0 0 5px 0" }}
          onClick={() => {
            this.setState({
              openImgCaptcha: false,
              captchaImg: "",
              captchaToken: "",
            });
          }}
        >
          <CloseCircleOutlined />
        </div>
      </div>
    );
  };
  checkImgCaptcha = async () => {
    const { captchaToken, imageCaptcha, mobile } = this.state;
    if (!imageCaptcha) {
      message.warning("请输入图形验证码!");
      return;
    }
    if (!mobile) {
      message.warning("请输入手机号!");
      return;
    }
    const params = { captchaToken, captcha_code: imageCaptcha };
    const res = await checkImgCaptcha(params);
    if (res.rc == 0) {
      let params = {
        captchaToken: res.data,
        opMobile: mobile,
      };
      //发送短信验证码
      const emsRes = await sendCode(params);
      if (emsRes.rc == 0) {
        message.success("发送成功！");
        this.changeImgCaptchaPopOver(false);
        this.runGetCaptchaCountDown();
        this.setState({ disabled: false });
      } else {
        message.error(emsRes.msg);
      }
    } else {
      message.error(res.msg);
      this.formRef.current.setFields([
        {
          name: "imageCaptcha",
          errors: ["验证码输入错误!"],
        },
      ]);
    }
  };
  submit = async () => {
    const {
      userName,
      companyName,
      pos,
      companyType,
      mobile,
      email,
      verifyCode,
    } = this.state;
    const parmas = {
      organ_name: companyName,
      organ_type: companyType,
      user_name: userName,
      organ_post: pos,
      mobile_no: mobile,
      email: email,
      verify_code: verifyCode,
    };
    if (!companyName) {
      message.error("请输入公司/机构名称");
      return;
    }
    if (!companyType) {
      message.error("请选择机构类型");
      return;
    }
    if (!userName) {
      message.error("请输入姓名");
      return;
    }
    if (!pos) {
      message.error("请输入岗位");
      return;
    }
    if (!mobile) {
      message.error("请输入手机号");
      return;
    }
    const res = await addConsultRecord(parmas);
    if (res) {
      message.success("提交成功");
      this.resetField();
    }
  };
  resetField = () => {
    this.setState({
      companyName: "",
      userName: "",
      companyType: undefined,
      pos: "",
      mobile: "",
      email: "",
      verifyCode: "",
      countDown: 0,
      openImgCaptcha: false,
      captchaImg: "",
      captchaToken: "",
      disabled: true,
    });
  };

  render() {
    const {
      userName,
      companyName,
      pos,
      companyType,
      mobile,
      email,
      verifyCode,
      countDown,
      openImgCaptcha,
      disabled,
    } = this.state;
    const options = [
      { value: "1", label: "社会组织" },
      { value: "2", label: "保险公司-个险" },
      { value: "3", label: "保险公司-团险" },
      { value: "4", label: "企业人资" },
      { value: "5", label: "药企" },
    ];
    return (
      <div className={style.consultWrapper}>
        <div style={{ width: "45%" }}>
          <Row className={style.inputArea}>
            <Col span={5}>
              <span style={{ color: "red" }}>*</span>公司/机构名称
            </Col>
            <Col span={19}>
              <Input
                placeholder="请输入您的公司/机构名称"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("companyName", e.target.value);
                }}
                value={companyName}
              />
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={5}>
              <span style={{ color: "red" }}>*</span>机构类型
            </Col>
            <Col span={19}>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="选择机构类型"
                value={companyType}
                onChange={(value) => {
                  this.onChange("companyType", value);
                }}
                options={options}
              />
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={5}>
              <span style={{ color: "red" }}>*</span>姓名
            </Col>
            <Col span={19}>
              <Input
                placeholder="请输入您的姓名"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("userName", e.target.value);
                }}
                value={userName}
              />
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={5}>
              <span style={{ color: "red" }}>*</span>岗位
            </Col>
            <Col span={19}>
              <Input
                placeholder="请输入您的岗位"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("pos", e.target.value);
                }}
                value={pos}
              />
            </Col>
          </Row>
        </div>
        <div style={{ width: "45%" }}>
          <Row className={style.inputArea}>
            <Col span={3}>
              <span style={{ color: "red" }}>*</span>手机号
            </Col>
            <Col span={21}>
              <Input
                placeholder="请输入您的手机号"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("mobile", e.target.value);
                }}
                value={mobile}
              />
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={3}>邮箱</Col>
            <Col span={21}>
              <Input
                placeholder="请输入您的邮箱"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("email", e.target.value);
                }}
                value={email}
              />
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={3}>
              <div></div>
            </Col>
            <Col span={15}>
              <Input
                placeholder="请输入验证码"
                maxLength={20}
                onChange={(e) => {
                  this.onChange("verifyCode", e.target.value);
                }}
                value={verifyCode}
              />
            </Col>
            <Col span={1}>
              <div></div>
            </Col>

            <Col span={5}>
              <Popover
                content={this.imgCaptcha()}
                title={null}
                trigger="click"
                open={openImgCaptcha}
              >
                <Button
                  type="primary"
                  block
                  disabled={!!countDown}
                  ghost
                  onClick={() => {
                    this.changeImgCaptchaPopOver(true);
                  }}
                >
                  {countDown ? countDown : "获取验证码"}
                </Button>
              </Popover>
            </Col>
          </Row>
          <Row className={style.inputArea}>
            <Col span={3}>
              <div></div>
            </Col>
            <Col span={21}>
              <Button
                block
                type="primary"
                disabled={disabled}
                onClick={this.submit}
              >
                立即咨询
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
