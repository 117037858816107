import { getServiceConfig } from "@/service/platform/basicModule/serviceManage";

export default {
  namespace: "serviceConfig",
  state: {
    totalNum: 0,
  },
  effects: {
    *getConfig({ payload }, { call }) {
      const response = yield call(getServiceConfig, payload);
      return response;
    },
  },

  reducers: {
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
