import React, { Component } from 'react'
import { Divider } from 'antd';
import style from './index.less'

export default class Title extends Component {
	state = {
	}

	render () {
		const { title = '' } = this.props

		return <div className={style.myTitleContainer}>
			{/* <div className={style.colorLine}></div> */}
			<div className={style.title}>{title}</div>
		</div>
	}
}