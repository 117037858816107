import React, { Component } from 'react'
import style from './index.less'
import { getUrlParam } from '@/utils/utils';
import { connect } from 'dva';
const namespace = "drug";
@connect(({ drug }) => ({
  drug,
}))
export default class DrugDetail extends Component {
  state = {
    prodDetail: {},
  }
  componentDidMount() {
    const id = getUrlParam('prodId')
    this.props.dispatch({
      type: `${namespace}/getProductDetail`,
      payload: id,
    }).then(res => {
      if (res) {
        this.setState({ prodDetail: res })
      }
    })
  }

  render() {
    const roleType = localStorage.getItem('roleType') || 'SOCIAL_ORGANIZATION'
    const { prodDetail } = this.state
    const { product_image_url, supplier, product_spec, composition, applicable_scope, notes, valid_date, product_license, manu_facturer, bar_code,product_price='暂无' } = prodDetail
    return <div className={style.wrapper}>
      <div className={style.banner} style={{ height: 'auto', width: '100%' }}>
        <div className={style.desc}>
          <div className={style.title}>{roleType === 'COMPANY_HR' || roleType === 'INSURANCE_GROUP' ? '员福药品目录' : '商保药品目录'}</div>
          <div className={style.text}>商保集采 品种入库 库存保证</div>
        </div>
        <img src={roleType === 'COMPANY_HR' || roleType === 'INSURANCE_GROUP' ? require("../../assets/prodDetail.png") : require("../../assets/productBanner.png")} alt="" />
      </div>
      <div className={style.detailContent}>
        <div className={style.left}><img src={product_image_url} alt="" /></div>
        <div className={style.right}>
          <div className={style.price}>商保目录价格：<span style={{color:'#E02020'}}>¥{product_price}</span></div>
          <div className={style.content}>
            {supplier && <p>【商家】:{supplier}</p>}
            {product_spec && <p>规格:{product_spec}</p>}
            {/* {composition && <p>结构及组成:{composition}</p>}
            {applicable_scope && <p>适用范围:{applicable_scope}</p>}
            {notes && <p>注意事项:{notes}</p>}
            {valid_date && <p>有效期:{valid_date}</p>} */}
            {product_license && <p>批准文号:{product_license}</p>}
            {manu_facturer && <p>生产企业:{manu_facturer}</p>}
            {bar_code && <p>条形码:{bar_code}</p>}
          </div>
        </div>
      </div>
    </div >
  }
}