import React, { Component } from 'react'
import style from './index.less'
import { formatVideoValue } from '@/utils/utils';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { getUrlParam } from '@/utils/utils';
import VideoDetail from './videoDetail'
import { connect } from 'dva';

const namespace = "news";
@connect(({ news }) => ({
    news,
}))
export default class News extends Component {
    state = {
        detail: {},
        likes_count: 0,
        id: getUrlParam('id'),
    }
    componentDidMount() {
        // 详情
        this.getDetail()
    }
    getDetail = () => {
        const { id } = this.state
        this.props.dispatch({
            type: `${namespace}/fetchVideoDetail`,
            payload: { videoId: id }
        }).then(res => {
            this.setState({
                detail: res,
                likes_count: res.likes_count,
            })
        });
    }
    
    render() {
        const { detail,likes_count } = this.state
        return <div>
            {detail.id && <VideoDetail detail={detail} likes_count={likes_count}/>}
        </div>
    }
}