import React, { Component } from "react";
import style from "./index.less";
import { Table } from "antd";
import { getUrlParam } from "@/utils/utils";
import { connect } from "dva";
import router from "umi/router";

const namespace = "join";
@connect(({ join }) => ({
  join,
}))
export default class JoinDetail extends Component {
  state = {
    detail: {},
  };
  componentDidMount() {
    this.getDetail();
  }
  getDetail = () => {
    const id = getUrlParam("id");
    this.props
      .dispatch({
        type: `${namespace}/fetchRecruitDetail`,
        payload: { recruitId: id },
      })
      .then((res) => {
        this.setState({ detail: res });
      });
  };

  render() {
    const { detail } = this.state;
    console.log("detail", detail);
    const columns = [
      { title: "岗位名称", dataIndex: "position_name", align: "center" },
      { title: "所属部门", dataIndex: "department", align: "center" },
      { title: "工作地点", dataIndex: "region", align: "center" },
    ];
    const dataList = [
      {
        recruit_id: detail.recruit_id,
        position_name: detail.position_name,
        department: detail.department,
        region: `${detail.province_code}·${detail.city_name}`,
      },
    ];
    return (
      <div className={style.wrapper}>
        <div className={style.banner} style={{ height: "auto", width: "100%" }}>
          {/* <div className={style.desc}>
            <div className={style.title}>加入老白</div>
          </div> */}
          <img src={require("../../assets/joinBanner.png")} alt="" />
        </div>
        <div className={style.mainContent}>
          <div className={style.mainTitle}>岗位详情</div>
          <Table
            rowKey="recruit_id"
            columns={columns}
            dataSource={dataList}
            pagination={false}
          />
          <div className={style.subTitle}>岗位职责</div>
          <div>{detail.position_responsibility}</div>
          <div className={style.subTitle}>任职要求</div>
          <div>{detail.position_requirement}</div>
          <div className={style.subTitle}>简历投递邮箱：quanyafei@e-hy.com</div>
        </div>
      </div>
    );
  }
}
