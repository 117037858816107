import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import moment from 'moment';
import _ from 'lodash';

const namespace = 'organization';
@connect(({ organization }) => ({ organization }))
export default class Organization extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
          searchCond[k] = v;
        });
        searchCond.page = 1;
        this.props.dispatch({
          type: `${namespace}/set`,
          payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const searchList = [
            { key: "tc_no", type: "INPUT", placeholder: "商家id", width: '220px' },
            { key: "tc_name", type: "INPUT", placeholder: "商家名称", width: '220px' },
            { key: "tc_mobile", type: "INPUT", placeholder: "联系电话", width: '220px' },
            { key: "tc_contact", type: "INPUT", placeholder: "联系人", width: '220px' },
        ]
        const columns = [
            { title: '商家id', dataIndex: 'tc_no', align: 'center', width: '150px' },
            { title: '商家名称', dataIndex: 'tc_name', align: 'center', width: '150px' },
            { title: '联系人', dataIndex: 'tc_contact', align: 'center', width: '100px' },
            { title: '联系电话', dataIndex: 'tc_mobile', align: 'center', width: '100px' },
            { title: '状态', dataIndex: 'tc_state', align: 'center', width: '130px' },
            { title: '返点数据', dataIndex: 're_bate', align: 'center', width: '90px' },
            { title: '开户银行', dataIndex: 'bank', align: 'center', width: '150px' },
            { title: '银行账号', dataIndex: 'bank_account', align: 'center', width: '100px', },
            { title: '类型', dataIndex: 'organization_type', align: 'center', width: '110px',render: record => '定点医疗机构'  },
            { title: '备注', dataIndex: 'tc_remark', align: 'center', width: '150px' },
            {
                title: '操作', dataIndex: '', align: 'center', fixed: 'right', width: '200px',
                render: (text, record) => (
                    <>
                        <Button style={{ marginRight: '10px' }} type='primary' >修改</Button>
                    </>
                )
            },
        ]
        const pagination = {
            pageSize: searchCond.size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <Button className='mb10' type='primary'>新增</Button>
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
                searchReset={this.searchReset}
            />
            <Table
                rowKey="id"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
                scroll={{ x: true }}
            />
        </div>
    }
}