export const drugInfoList = [
  {
    name: "舒筋健腰丸（陈李济）",
    url:
      "https://img-o.laobaiyy.com/jianbao/2023/04/07/14/b6d63216d7fe40c3bcbb4c816dc568f2.jpg",
  },
  {
    name: "消痛贴膏（奇正）",
    url:
      "https://img.ebaohaoyao.com/2024-06-12-7c38f78839ba547c9b41f06e14384d2a.jpg",
  },
  {
    name: "双氯芬酸二乙胺乳胶剂",
    url:
      "https://img-o.laobaiyy.com/jianbao/2023/04/10/16/4dfad9f05adf4e6d8b235238905bc36b.jpg",
  },
  {
    name: "云南白药胶囊",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-06-06-7d54872c37b944daa48069c72f245951-1686012223.png?x-oss-process=style/quality",
  },
  {
    name: "三金片",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-03-20-0a623f655b774706a4f289f5e1f12ab5-1679301600.png?x-oss-process=style/quality",
  },
  {
    name: "马应龙麝香痔疮膏",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-05-08-91d25e9ddfc9441199448f4599772ee6-1683538603.png?x-oss-process=style/quality",
  },
  {
    name: "养血清脑颗粒",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-03-27-f9a27834f7df40c896503e8b8b6ba011-1679906928.png?x-oss-process=style/quality",
  },
  {
    name: "莫匹罗星软膏",
    url:
      "https://img-o.laobaiyy.com/jianbao/2016/05/19/193818859741486e96e0c14663de1477.jpg?x-oss-process=style/quality",
  },
  {
    name: "碳酸钙D3片",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-03-24-6cf625e8cdbb4e1d842007f51ab6d78a-1679648353.png?x-oss-process=style/quality",
  },
  {
    name: "桂林西瓜霜",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-03-20-a3669c39f98d4c0a91720aea4290e31e-1679290750.png?x-oss-process=style/quality",
  },
  {
    name: "肺力咳合剂",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-09-22-8705e395b706414282a05f610b98fc6b-1695368957.jpg?x-oss-process=style/quality",
  },
  {
    name: "妇科千金片",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-07-12-ef648f11ce284ca5a7123eeeee932626-1689141870.jpg?x-oss-process=style/quality",
  },
  {
    name: "百令胶囊",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-09-15-351396291fbf49ec807180c9f6e2d753-1694747410.jpg?x-oss-process=style/quality",
  },
  {
    name: "开喉剑喷雾剂",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-09-21-2bbe83e2b1074a8ba397084ae984e0c4-1695268268.jpg?x-oss-process=style/quality",
  },
  {
    name: "丁桂儿脐贴",
    url:
      "https://img-o.laobaiyy.com/jianbao/2023/04/07/13/a7adb0aacd8d4e76aa0ce302b32f6c4c.jpg",
  },
  {
    name: "布洛芬混悬滴剂",
    url:
      "https://img.ebaohaoyao.com/2023-12-15-bda15428d7f7c4e8334423e2193bbb3c.jpg",
  },
  {
    name: "蓝芩口服液",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-03-28-44ce46da91234e48982dca4d39ca9130-1679970287.png?x-oss-process=style/quality",
  },
  {
    name: "盐酸氨溴索口服溶液",
    url:
      "https://img-o.laobaiyy.com/jianbao/2023/04/12/17/94677ed176c0474b90d83c3f15f1e296.png",
  },
  {
    name: "龙牡壮骨颗粒",
    url:
      "https://img.ebaohaoyao.com/2023-12-26-ee737e5d6b66184b861af05c8e4324ac.jpg",
  },
  {
    name: "小儿豉翘清热颗粒",
    url:
      "https://hcrm-scm-laobai.laobaiyy.com/2023-09-14-a4ffb65b7b7243bab77aaa4f16ed2811-1694679296.jpg?x-oss-process=style/quality",
  },
];
