import React, { Component } from 'react'
import style from './zhyf.less'
import MyTitle from '@/component/title/title'
import FlyInAnimation from '../../../component/flyInAnimation'
const img1 = [
    require('@/assets/business/zhyf/4_1.png'),
    require('@/assets/business/zhyf/4_2.png'),
    require('@/assets/business/zhyf/4_3.png'),
    require('@/assets/business/zhyf/4_4.png'),
]
export default class ZhyfTab extends Component {
    state = {
        active1: [false, false],
        active2: [false, false, false, false],
        active2TimerDisable: false,
        active3:false,
        active4:false,
    }
    componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
        const { active1 } = this.state
        // 服务案例动画
        active1[0] = true
        this.setState({ active1 })
        setTimeout(() => {
            active1[1] = true
            this.setState({ active1 })
        }, 1000);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        this.setState({ positionY: pos.top })
        this.activeAnimation(pos.top)
    }
    activeAnimation = (positionY) => {
        const { active2, active2TimerDisable, active3 } = this.state
        if (positionY < -200 && !active2TimerDisable) {
            let index = 0
            this.setState({ active2TimerDisable: true })
            const timer = setInterval(() => {
                active2[index] = true
                index++
                this.setState({ active2 })
            }, 600);
            setTimeout(() => {
                clearInterval(timer)
            }, 2400);
        }
        if (positionY < -1400) {
            this.setState({ active3:true })
        }
        if (positionY < -2100) {
            this.setState({ active4:true })
        }
    }
    render() {
        const { active1, active2,active3,active4 } = this.state
        return (
            <div className={style.pageWrapper}>
                <div className={style.topBanner}>
                    <img src={require('@/assets/business/zhyf/top.png')} alt="" />
                </div>
                <div className={style.boxWrapper}>
                    <div className={style.boxContent}>
                        <div className='fs24 fwbold'>“买得到、用得掉、花得完”</div>
                        <div className={style.line} />
                        <div className='flexbet mb40'>
                            <div className={style.fontStyle} style={{ width: 520 }}>“智慧员福”是老白健康面向大企业员工福利场景推出的数字化医药福利管理解决方案，通过供应链创新优化医药流通中间环节利润结构，为员工提供端对端的用药服务；并通过提供积分商城服务，让员工买得到、用得掉、花得完，大大降低医疗费用支出、增强员工福利感知。</div>
                            <FlyInAnimation show={active1[0]}>
                                <img className={style.rightImg} src={require('@/assets/business/zhyf/img1.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                    <FlyInAnimation show={active1[1]}>
                        <div className={style.banner}>
                            <img src={require('@/assets/business/zhyf/img3.png')} alt="" />
                        </div>
                    </FlyInAnimation>
                    <img className={style.bgImg} src={require('@/assets/business/zhyf/img2.png')} alt="" />
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务内容'></MyTitle>
                    <div className={style.content}>
                        <div className='flexst'>
                            {img1.map((item, index) => <FlyInAnimation key={index} show={active2[index]}><img className={style.img1} src={item} alt="" /></FlyInAnimation>)}
                        </div>
                    </div>
                </div>
                <div className={style.contentBox} style={{backgroundColor:'#F5F5F5'}}>
                    <MyTitle title='服务数据'></MyTitle>
                    <div className={style.content}>
                        <div className='color333 mb30'>截至目前，智慧员福已与多个保险公司、三方平台(经纪公司)、城市合伙人等渠道达成合作共识，共同服务了国能、大唐、农行、移动、电信等客户，遍及金融、通信、交通、能源、烟草五大行业，覆盖的基金规模超200亿。</div>
                        <FlyInAnimation show={active3}>
                            <img className={style.img1} src={require('@/assets/business/zhyf/img5.png')} alt="" />
                        </FlyInAnimation>
                    </div>
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务案例'></MyTitle>
                    <div className={style.content}>
                        <div className='color333 mb30'>老白健康与某健康管理公司达成智慧员福合作，共同为东北某大型国有能源企业提供PBM系统集成服务，解决了客户企业员工健康服务分散、管理缺乏系统性、难以形成数据资产等问题，有效推动该健康管理公司健康服务数字化、智能化、系统化升级，进一步提高了企业员工的获得感、幸福感和安全感。</div>
                        <div className='flexcenter'>
                            <FlyInAnimation show={active4}>
                                <img className={style.img1} src={require('@/assets/business/zhyf/img6.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}