/**
 * Created by jljsj on 20/12/2.
 * The algorithm from GSAP(utils/paths.js) VERSION: 3.0.x
 */
var isNumber = function isNumber(value) {
  return typeof value === 'number';
};

export var pointsToSegment = function pointsToSegment(points, curviness) {
  var l = points.length - 2;
  var x = +points[0];
  var y = +points[1];
  var nextX = +points[2];
  var nextY = +points[3];
  var segment = [x, y, x, y];
  var dx2 = nextX - x;
  var dy2 = nextY - y;
  var closed = Math.abs(points[l] - x) < 0.001 && Math.abs(points[l + 1] - y) < 0.001;
  var cornerThreshold = Math.PI / 10;

  if (closed) {
    points.push(nextX, nextY);
    nextX = x;
    nextY = y;
    x = points[l - 2];
    y = points[l - 1];
    points.unshift(x, y);
    l += 4;
  }

  curviness = curviness || curviness === 0 ? +curviness : 1;
  var slope;

  for (var i = 2; i < l; i += 2) {
    var prevX = x;
    var prevY = y;
    x = nextX;
    y = nextY;
    nextX = +points[i + 2];
    nextY = +points[i + 3];
    var dx1 = dx2;
    var dy1 = dy2;
    dx2 = nextX - x;
    dy2 = nextY - y;
    var dx3 = nextX - prevX;
    var dy3 = nextY - prevY;
    var a = dx1 * dx1 + dy1 * dy1;
    var b = dx2 * dx2 + dy2 * dy2;
    var c = dx3 * dx3 + dy3 * dy3;
    var angle = Math.acos((a + b - c) / Math.sqrt(4 * a * b));
    var d2 = angle / Math.PI * curviness;
    var d1 = Math.sqrt(a) * d2;
    d2 *= Math.sqrt(b);

    if (x !== prevX || y !== prevY) {
      if (angle > cornerThreshold) {
        slope = Math.atan2(dy3, dx3);
        segment.push(Math.round(x - Math.cos(slope) * d1), Math.round(y - Math.sin(slope) * d1), Math.round(x), Math.round(y), Math.round(x + Math.cos(slope) * d2), Math.round(y + Math.sin(slope) * d2));
      } else {
        slope = Math.atan2(dy1, dx1);
        segment.push(Math.round(x - Math.cos(slope) * d1), Math.round(y - Math.sin(slope) * d1));
        slope = Math.atan2(dy2, dx2);
        segment.push(Math.round(x), Math.round(y), Math.round(x + Math.cos(slope) * d2), Math.round(y + Math.sin(slope) * d2));
      }
    }
  }

  segment.push(Math.round(nextX), Math.round(nextY), Math.round(nextX), Math.round(nextY));

  if (closed) {
    segment.splice(0, 6);
    segment.length = segment.length - 6;
  }

  return segment;
};

var softPointsToCubic = function softPointsToCubic(points, curviness) {
  points.unshift(points[0]);
  !curviness && curviness !== 0 && (curviness = 1);
  curviness *= 2 / 3;
  var d = points[0];
  var result = [d];
  var l = points.length - 1;

  for (var i = 1; i < l; i++) {
    var a = d;
    var b = {};
    var c = {};
    d = {};
    var p1 = points[i];
    var p2 = points[i + 1];

    for (var p in a) {
      var _ref;

      var v1 = a[p];
      var v2 = p1[p];
      b[p] = v1 + ((_ref = v2 - v1) !== null && _ref !== void 0 ? _ref : 0) * curviness;
      d[p] = v1 = i === l - 1 ? p2[p] : (v2 + p2[p]) / 2;
      c[p] = v1 + (v2 - v1) * curviness;
    }

    result.push(b, c, d);
  }

  return result;
};

export var populateSegmentFromArray = function populateSegmentFromArray(segment, values, property, mode) {
  var l = values.length;
  var si = mode === 2 ? 0 : mode;

  for (var i = 0; i < l; i++) {
    segment[si] = parseFloat(values[i][property]);
    mode === 2 && (segment[si + 1] = 0);
    si += 2;
  }

  return segment;
};

var relativize = function relativize(segment) {
  var x = segment[0],
      y = segment[1];

  for (var i = 2; i < segment.length; i += 2) {
    x = segment[i] += x;
    y = segment[i + 1] += y;
  }
};

export var arrayToRawPath = function arrayToRawPath(values, vars) {
  var _vars$curviness = vars.curviness,
      curviness = _vars$curviness === void 0 ? 1 : _vars$curviness,
      _vars$type = vars.type,
      type = _vars$type === void 0 ? 'thru' : _vars$type,
      relative = vars.relative,
      _vars$x = vars.x,
      x = _vars$x === void 0 ? 'x' : _vars$x,
      _vars$y = vars.y,
      y = _vars$y === void 0 ? 'y' : _vars$y;
  var toCubic;
  values = values.filter(function (c) {
    return c;
  });

  if (type === 'soft') {
    toCubic = true;
    values = softPointsToCubic(values, curviness);
  }

  var segment = populateSegmentFromArray(populateSegmentFromArray([], values, x, 0), values, y, 1);
  relative && relativize(segment);
  var t = [type === 'cubic' || toCubic ? segment : pointsToSegment(segment, curviness)];
  return t;
};
export var rawPathToString = function rawPathToString(rawPath) {
  if (isNumber(rawPath[0])) {
    rawPath = [rawPath];
  }

  var result = '';
  var l = rawPath.length;

  for (var s = 0; s < l; s++) {
    var segment = rawPath[s];
    result += "M".concat(Math.round(segment[0]), ",").concat(Math.round(segment[1]), " C");
    var sl = segment.length;

    for (var i = 2; i < sl; i++) {
      result += "".concat(Math.round(segment[i++] || segment[sl - 2]), ",").concat(Math.round(segment[i++] || segment[sl - 1]), " ").concat(Math.round(segment[i++] || segment[sl - 2]), ",").concat(Math.round(segment[i++] || segment[sl - 1]), " ").concat(Math.round(segment[i++] || segment[sl - 2]), ",").concat(Math.round(segment[i] || segment[sl - 1]), " \n      ");
    }

    if (segment.closed) {
      result += 'z';
    }
  }

  return result;
};