import React, { Component } from 'react'
import style from './phhy.less'
import MyTitle from '@/component/title/title'
import FlyInAnimation from '../../../component/flyInAnimation'
const img1 = [
    require('@/assets/business/zhyf/4_1.png'),
    require('@/assets/business/zhyf/4_2.png'),
    require('@/assets/business/zhyf/4_3.png'),
    require('@/assets/business/zhyf/4_4.png'),
]
export default class ZhyfTab extends Component {
    state = {
        active1: [false, false],
        active2: false,
        active3:false,
        active4:false,
    }
    componentDidMount() {
        window.addEventListener('scroll', this.changeScrollTopShow, true)
        const { active1 } = this.state
        // 服务案例动画
        active1[0] = true
        this.setState({ active1 })
        setTimeout(() => {
            active1[1] = true
            this.setState({ active1 })
        }, 1000);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.changeScrollTopShow, true)
    }
    changeScrollTopShow = () => {
        const mainEle = document.getElementsByClassName('point');
        const pos = mainEle[0].getBoundingClientRect()
        this.setState({ positionY: pos.top })
        this.activeAnimation(pos.top)
    }
    activeAnimation = (positionY) => {
        if (positionY < -600) {
            this.setState({ active2:true })
        }
        if (positionY < -1400) {
            this.setState({ active3:true })
        }
        if (positionY < -2100) {
            this.setState({ active4:true })
        }
    }
    render() {
        const { active1, active2,active3,active4 } = this.state
        return (
            <div className={style.pageWrapper}>
                <div className={style.topBanner}>
                    <img src={require('@/assets/business/phhy/top.png')} alt="" />
                </div>
                <div className={style.boxWrapper}>
                    <div className={style.boxContent}>
                        <div className='fs24 fwbold'>“1分钱，2分货”，保障倍增、幸福感倍增</div>
                        <div className={style.line} />
                        <div className='flexbet mb40'>
                            <div className={style.fontStyle} style={{ width: 520 }}>“普惠好药保障倍增计划”是老白健康面向中小企业、新市民、乡村振兴、个人及家庭四大场景推出的互联网门诊保险医药服务解决方案。老白健康通过科技创新和供应链创新优化医药流通环节、降低了保司的赔付风险：提出服务即保费的理念，保障额度1:2倍增，同等保费情况下，用户可支配健康金额更多、享受的健康服务更丰富。</div>
                            <FlyInAnimation show={active1[0]}>
                                <img className={style.rightImg} src={require('@/assets/business/phhy/img1.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                    <FlyInAnimation show={active1[1]}>
                        <div className={style.banner}>
                            <img src={require('@/assets/business/phhy/img3.png')} alt="" />
                        </div>
                    </FlyInAnimation>
                    <img className={style.bgImg} src={require('@/assets/business/zhyf/img2.png')} alt="" />
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务内容'></MyTitle>
                    <div className={style.content}>
                        <FlyInAnimation show={active2}>
                            <img className={style.img1} src={require('@/assets/business/phhy/img4.png')} alt="" />
                        </FlyInAnimation>
                    </div>
                </div>
                <div className={style.contentBox} style={{backgroundColor:'#F5F5F5'}}>
                    <MyTitle title='服务数据'></MyTitle>
                    <img className={style.imgBg} src={require('@/assets/business/phhy/serviceBg.png')} alt="" />
                    <div className={style.content}>
                        <div className='color333 mb30 ta-c'>截至目前，老白健康已与超30个渠道签署了“普惠好药”合作协议。</div>
                        <FlyInAnimation show={active3}>
                            <img className={style.img1} src={require('@/assets/business/phhy/img5.png')} alt="" />
                        </FlyInAnimation>
                    </div>
                </div>
                <div className={style.contentBox}>
                    <MyTitle title='服务案例'></MyTitle>
                    <div className={style.content}>
                        <div className='color333 mb30 ta-c'>普惠好药为农民、快递员、餐饮行业从业者、学生等群体推出保障服务，在四大业务场景形成了丰富的应用案例。</div>
                        <div className='flexcenter'>
                            <FlyInAnimation show={active4}>
                                <img className={style.img1} src={require('@/assets/business/phhy/img6.png')} alt="" />
                            </FlyInAnimation>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}