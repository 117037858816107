import { message } from 'antd';
import { getQuotaList } from '@/service/platform/basicModule/detailManage'

export default {
    namespace: 'quotaDetail',
    state: {
        searchCond: {
            size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.quotaDetail.searchCond);
            const { records, total } = yield call(getQuotaList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: records,
                    totalNum: Number(total),
                },
            });
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};