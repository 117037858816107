import React, { Component } from 'react';
import CheckBox from '@/component/CheckBox';
import { Button, Table, Popconfirm } from 'antd'
import { connect } from 'dva';
import moment from 'moment';
import _ from 'lodash';

const namespace = 'claimReport';
@connect(({ claimReport }) => ({ claimReport }))
export default class ClaimReport extends Component {
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        this.props.dispatch({
            type: `${namespace}/fetchList`
        })
    }
    searchSubmit = data => {
        const { searchCond } = this.props[namespace];
        Object.entries(data).forEach(([k, v]) => {
            if (k === 'time') {
                if (v && v.length > 0) {
                    searchCond.claim_start_time = `${moment(v[0]).format('YYYY-MM-DD')} 00:00:00`
                    searchCond.claim_end_time = `${moment(v[1]).format('YYYY-MM-DD')} 23:59:59`
                } else {
                    delete searchCond.claim_start_time
                    delete searchCond.claim_end_time
                }
            } else {
                searchCond[k] = v;
            }
        });
        searchCond.page = 1;
        this.props.dispatch({
            type: `${namespace}/set`,
            payload: searchCond,
        });
        this.getList()
    }
    handleTableChange = pagination => {
        const { searchCond } = this.props[namespace];
        searchCond.page_size = pagination.pageSize;
        searchCond.page = pagination.current;
        this.props.dispatch({
            type: `${namespace}/set`
        })
        this.getList()
    };
    render() {
        const { searchCond, totalNum, dataList } = this.props[namespace]
        const checkStateJson = {
            0: '未核对',
            1: '正常',
            2: '异常',
        }
        const searchList = [
            {
                key: "time",
                type: "RANGEDATE",
                width: '280px',
                placeholder: ['开始时间', '结束时间'],
                rangeDateLabel: { firstTime: 'claim_start_time', secondTime: 'claim_end_time' }
            }
        ]
        const columns = [
            { title: '理赔单id', dataIndex: 'claim_id', align: 'center', width: '150px' },
            { title: '创建时间', dataIndex: 'claim_date', align: 'center', width: '150px' },
            { title: '保单名称', dataIndex: 'customer_name', align: 'center', width: '150px' },
            { title: '保单总额', dataIndex: 'begin_balance', align: 'center', width: '150px' },
            { title: '实际理赔金额', dataIndex: 'claim_balance', align: 'center', width: '150px' },
            { title: '保单剩余额度', dataIndex: 'end_balance', align: 'center', width: '150px' },
            { title: '返点金额', dataIndex: 'rebate_amount', align: 'center', width: '150px' },
            { title: '对账结果', dataIndex: 'check_state', align: 'center', width: '150px', render: text => checkStateJson[text] },
            { title: '保司结余', dataIndex: 'agent_remain_balance', align: 'center', width: '150px' },
        ]
        const pagination = {
            pageSize: searchCond.page_size,
            total: totalNum,
            showQuickJumper: true,
            current: searchCond.page,
            showTotal: (total, range) => {
                return (
                    <span>
                        目前显示{range[0]}-{range[1]} 条,共 {total} 条
                    </span>
                );
            },
        }
        return <div style={{ margin: 24, padding: 24, background: '#fff', minHeight: 360 }}>
            <div className='flex mb15'>
                <Button type='primary' className='mr10'>理赔明细下载</Button>
                <Button type='primary'>理赔对账明细下载</Button>
            </div>
            <CheckBox
                searchList={searchList}
                searchSubmit={this.searchSubmit}
                fields={searchCond}
                searchReset={this.searchReset}
            />
            <Table
                rowKey="id"
                columns={columns}
                pagination={pagination}
                dataSource={dataList}
                onChange={this.handleTableChange}
                className="mt15"
                scroll={{ x: true }}
            />
        </div>
    }
}