import { message } from 'antd';
import { getProposalFormList,getSupplierList,setSupplierConfig } from '@/service/platform/basicModule/proposalFormManagement'
import { getUnitList } from '@/service/login';

export default {
    namespace: 'proposalFormInfo',
    state: {
        searchCond: {
            size: 10,
            page: 1,
        },
        dataList: [],
        totalNum: 0,
        insureUnitList: [],
    },
    effects: {
        *fetchList({ payload }, { call, put, select }) {
            const searchCond = yield select((state) => state.proposalFormInfo.searchCond);
            const { records, total } = yield call(getProposalFormList, searchCond);
            yield put({
                type: "set",
                payload: {
                    dataList: records,
                    totalNum: Number(total),
                },
            });
        },
        *fetchInsureUnitList({ payload }, { call, put, select }) {
            const paramData = {
                agent_id: localStorage.getItem('bindClientId'),
                page: 1,
                page_size: 100
            }
            const res = yield call(getUnitList, paramData)
            const relatedUnitList = res?.map(item => ({ unit_id: item.id, unit_name: item.name }))
            localStorage.setItem('relatedUnitList', JSON.stringify(relatedUnitList) || [])
            yield put({
                type: "set",
                payload: {
                    insureUnitList: res || [],
                },
            });
        },
        *fetchSupplierList({ payload }, { call }) {
            return yield call(getSupplierList, payload);
        },
        *setSupplierConfig({ payload }, { call }) {
            const res = yield call(setSupplierConfig, payload);
            return res
        },
    },

    reducers: {
        set(state, { payload }) {
            return {
                ...state,
                ...payload,
            };
        },
    }
};