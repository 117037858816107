import React, { Component } from 'react';
import { Tabs } from 'antd'
import { connect } from 'dva';
import style from './Login.less';
import Config from '@/utils/config';
import _ from 'lodash'
import LoginForm from './loginForm.jsx';
import Forget from './forget';
import { getUrlParam } from '@/utils/utils';
import PDFView from '../../component/PDFView/pdfView.jsx'

const namespace = 'global';

@connect(({ global, loading }) => ({
  global,
}))
export default class Login extends Component {
  state = {
    showPolicyModal: false,
    pdfUrl: ''
  }

  onChange = (key) => { }

  showPolicy = (url) => {
    this.setState({
      pdfUrl: url,
      showPolicyModal: true
    })
  }

  render () {
    const { showQrcode } = this.props[namespace]
    return <div className={style.loginContainer} >
      {showQrcode?<div className={style.qrcodeContainer}>
        <div className={style.titleWrapper}>
          <div>已收到您的注册申请，请耐心等待我们与您联系</div>
          <div>扫码添加企业微信，了解账号注册进度</div>
        </div>
				<img src={require('../../assets/wechat_c.png')} alt="" />
			</div>:<div className={style.loginTabContainer}>
        <LoginForm/>
      </div>}
      {/* {this.state.showPolicyModal && <PDFView pdfUrl={this.state.pdfUrl} onCancel={() => { this.setState({ showPolicyModal: false, pdfUrl: '' }) }}></PDFView>} */}
    </div>
  }
}
